import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { KeyboardArrowLeft } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridToolbarContainer, gridClasses } from '@mui/x-data-grid-pro'
import { capitalizeName } from '.'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { ACLLoadingTable } from './ACLLoadingTable'
import CreateInputForm from './CreateInputForm'
import PermissionTable from './PermissionTable'
import UpdateInputFrom from './UpdateInputFrom'

const UserTable = (props) => {
  const { selectedRow, setActiveTab, storedRolePageState, storedSponsorUserPageState, setStoredSponsorUserPageState, setStoredRolePageState, activeTab, type, setType, setUserRows, setRoleRows, userRows, roleRows, spanLoading, setUpdatedRole, updatedRole } = props
  const { user } = useAuth()
  const { showSuccess } = useSuccessToast()
  const { showError } = useErrorToast()
  const [open, setOpen] = useState(false)
  const [roleOpen, setRoleOpen] = useState(false)
  const [userProps, setUserProps] = useState({})
  const [modifyUser, setModifyUser] = useState(false)
  const [modifyRole, setModifyRole] = useState(false)
  const [roleParams, setRoleParams] = useState({})
  const [spnRoleParams, setSpnRoleParams] = useState({})
  const [openDeleteRoleDialog, setOpenDeleteRoleDialog] = useState(false)
  const [spnUserParams, setSpnUserParams] = useState({})
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeRow, setActiveRow] = useState(null)
  const [rolePageOptions, setRolePageOptions] = useState({ page: 0, pageSize: 10 })
  const [userPageOptions, setUserPageOptions] = useState({ page: 0, pageSize: 10 })
  const [formEditMode, setFormEditMode] = useState('add')

  useEffect(() => {
    if (activeTab === 'user-tab') {
      setUserPageOptions(storedSponsorUserPageState)
    } else {
      setRolePageOptions(storedRolePageState)
    }
  }, [activeTab])

  const userColumns = [
    {
      field: 'userName',
      headerName: 'User Name',
      flex: 1
    },
    {
      field: 'roleName',
      headerName: 'Role Name',
      flex: 1
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      flex: 1
    },
    {
      field: 'roleCode',
      headerName: 'Role Code',
      renderCell: (params) => {
        return updatedRole || params?.row?.roleCode
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label='more'
            aria-haspopup='true'
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
              setActiveRow(params.row)
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && activeRow?.userId === params.row?.userId}
            onClose={() => {
              setActiveRow(null)
              setAnchorEl(null)
            }}
            elevation={4}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              padding: 0,
              '.MuiMenuItem-root': {
                padding: '0 10px'
              },
              '.MuiButtonBase-root': {
                justifyContent: 'flex-start',
                textTransform: 'capitalize'
              }
            }}
          >
            <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setModifyUser(true)
                  setUpdatedRole('')
                  setAnchorEl(null)
                  setStoredSponsorUserPageState(userPageOptions)
                  setUserProps({ ...params?.row, sponsor: selectedRow, roleList: roleRows })
                  setRoleParams({})
                }}
                disableRipple
                startIcon={
                  <ManageAccountsOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Manage User Permission
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setOpen(true)
                  setUserProps(params?.row)
                  setAnchorEl(null)
                  setFormEditMode('edit')
                  setType('User')
                }}
                disableRipple
                startIcon={
                  <EditOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >
                Edit User
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                sx={{
                  color: 'red',
                  fontWeight: 600
                }}
                variant='text'
                disableRipple
                onClick={() => {
                  setSpnUserParams({ ...params?.row, sponsor: selectedRow })
                  setAnchorEl(null)
                  setOpenDeleteUserDialog(true)
                }}
                startIcon={
                  <DeleteIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Delete User
              </Button>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ]

  const rolesColumns = [
    {
      field: 'roleName',
      headerName: 'Role Name',
      flex: 2
    },
    {
      field: 'roleCode',
      headerName: 'Role Code',
      flex: 2
    },
    {
      field: 'roleDesc',
      headerName: 'Role Desc',
      flex: 1
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label='more'
            aria-haspopup='true'
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
              setActiveRow(params.row)
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && activeRow?.id === params.row?.id}
            onClose={() => {
              setActiveRow(null)
              setAnchorEl(null)
            }}
            elevation={4}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              padding: 0,
              '.MuiMenuItem-root': {
                padding: '0 10px'
              },
              '.MuiButtonBase-root': {
                justifyContent: 'flex-start',
                textTransform: 'capitalize'
              }
            }}
          >
            <MenuItem>
              <Button
                fullWidth
                disabled={params?.row?.roleName === 'INDIVIDUAL'}
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setModifyRole(true)
                  setAnchorEl(null)
                  setStoredRolePageState(rolePageOptions)
                  setRoleParams({ ...params?.row, sponsor: selectedRow, roleList: roleRows })
                  setUserProps({})
                }}
                disableRipple
                startIcon={
                  <ManageAccountsOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Manage Role Permission
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                disabled={params?.row?.roleName === 'INDIVIDUAL'}
                variant='text'
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 600
                }}
                onClick={() => {
                  setRoleOpen(true)
                  setRoleParams(params?.row)
                  setFormEditMode('edit')
                  setAnchorEl(null)
                  setType('Role')
                }}
                disableRipple
                startIcon={
                  <EditOutlinedIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >
                Edit Role
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                fullWidth
                disabled={params?.row?.roleName === 'INDIVIDUAL'}
                sx={{
                  color: 'red',
                  fontWeight: 600
                }}
                variant='text'
                disableRipple
                onClick={() => {
                  setSpnRoleParams({ ...params?.row, sponsor: selectedRow })
                  setAnchorEl(null)
                  setOpenDeleteRoleDialog(true)
                }}
                startIcon={
                  <DeleteIcon
                    sx={{ fontSize: '8px' }}
                  />
                }
              >Delete Role
              </Button>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ]

  const handleUserRowClick = (params) => {
    if (params?.field !== 'action') {
      setModifyUser(true)
      setUpdatedRole('')
      setStoredSponsorUserPageState(userPageOptions)
      setAnchorEl(null)
      setUserProps({ ...params?.row, sponsor: selectedRow, roleList: roleRows })
      setRoleParams({})
    }
  }

  const handleRoleRowClick = (params) => {
    if (params?.field !== 'action' && params?.row?.roleName !== 'INDIVIDUAL') {
      setModifyRole(true)
      setStoredRolePageState(rolePageOptions)
      setRoleParams({ ...params?.row, sponsor: selectedRow, roleList: roleRows })
      setAnchorEl(null)
      setUserProps({})
    }
  }

  const DeleteSpnUserConfirm = () => {
    const [isDeleteApiLoading, setIsDeleteApiLoading] = useState(false)
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={openDeleteUserDialog}
          onClose={() => setOpenDeleteUserDialog(false)}
        >
          <DialogTitle>
            {`Are you sure you want to delete ${spnUserParams?.userName} user?`}
          </DialogTitle>
          <DialogActions sx={{ marginBottom: '5px' }}>
            {isDeleteApiLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  variant='outlined'
                  sx={{ width: '130px' }}
                >
                  Confirm
                </LoadingButton>
                )
              : (
                <Button variant='contained' onClick={(e) => handleUserDelete(e, spnUserParams?.userId, spnUserParams?.sponsor?.sponsorId, setIsDeleteApiLoading)}>
                  Confirm
                </Button>
                )}
            <Button autoFocus onClick={() => setOpenDeleteUserDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const DeleteSpnRoleConfirm = () => {
    const [isDeleteApiLoading, setIsDeleteApiLoading] = useState(false)
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={openDeleteRoleDialog}
          onClose={() => setOpenDeleteRoleDialog(false)}
        >
          <DialogTitle>
            {`Are you sure you want to delete ${spnRoleParams?.roleName} role?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              After deleting the role, User associated with this role will be assigned to individual role.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ marginBottom: '5px' }}>
            {isDeleteApiLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  variant='outlined'
                  sx={{ width: '130px' }}
                >
                  Confirm
                </LoadingButton>
                )
              : (
                <Button variant='contained' onClick={(e) => handleRoleDelete(e, spnRoleParams?.roleCode, setIsDeleteApiLoading)}>
                  Confirm
                </Button>
                )}
            <Button autoFocus onClick={() => setOpenDeleteRoleDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const handleCancelClose = () => {
    setOpen(false)
  }

  const handleRoleCancelClose = () => {
    setRoleOpen(false)
  }

  const CreateRole = (value, setRoleRows, setCreateApiLoading) => {
    API.post('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId}/roles`, {
      body: {
        roleCode: value?.roleCode,
        roleName: value?.roleName,
        roleDesc: value?.roleDesc
      }
    })
      .then((data) => {
        if (data && data?.success) {
          setRoleOpen(false)
          const id = randomId()
          const newRow = {
            id,
            ...{ ...(data?.data[0] ? data?.data[0] : value) },
            isNew: true
          }
          setRoleRows((oldRows) => [newRow, ...oldRows])
          setRolePageOptions(prev => ({ ...prev, page: 0 }))
          setCreateApiLoading(false)
          showSuccess(data?.message)
        }
      }).catch((error) => {
        setCreateApiLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => setCreateApiLoading(false))
  }

  const UpdateRole = (value, setRoleRows, setUpdateApiLoading) => {
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId}/roles`, {
      body: {
        roleCode: value?.roleCode,
        roleName: value?.roleName,
        roleDesc: value?.roleDesc
      }
    })
      .then((res) => {
        if (res && res?.success) {
          setRoleOpen(false)
          const updatedRows = roleRows.map(row => {
            if (row.roleCode === res?.data[0]?.roleCode) {
              return { ...row, roleName: res?.data[0]?.roleName, roleDesc: res?.data[0]?.roleDesc }
            }
            return row
          })
          setRoleRows(updatedRows)

          const updatedUsers = userRows.map(row => {
            if (row.roleCode === res?.data[0]?.roleCode) {
              return { ...row, roleName: res?.data[0]?.roleName, roleDesc: res?.data[0]?.roleDesc }
            }
            return row
          })
          setUserRows(updatedUsers)

          setUpdateApiLoading(false)
          showSuccess(res?.message)
        }
      }).catch((error) => {
        setUpdateApiLoading(false)
        showError(error?.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => {
        setUpdateApiLoading(false)
        setAnchorEl(null)
      })
  }

  const handleRoleDelete = (e, roleCode, setIsDeleteApiLoading) => {
    if (e.target.innerText === 'CONFIRM') {
      setIsDeleteApiLoading(true)
      API.del('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId}/roles/${roleCode}`)
        .then((res) => {
          if (res && res.success) {
            setRoleRows(roleRows?.filter((row) => row.roleCode !== roleCode))

            const updatedUsers = userRows.map(user => {
              if (res?.data?.includes(user?.userId)) {
                return {
                  ...user,
                  roleName: 'INDIVIDUAL',
                  roleCode: 'IND'
                }
              } else {
                return user
              }
            })
            setUserRows(updatedUsers)
            showSuccess(res?.message)
          }
        }).catch((error) => {
          setIsDeleteApiLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => {
          setIsDeleteApiLoading(false)
          setOpenDeleteRoleDialog(false)
          setAnchorEl(null)
        })
    }
  }

  const EditRoleToolbar = (props) => {
    const { setRoleRows } = props
    const [createApiLoading, setCreateApiLoading] = useState(false)
    const [updateApiLoading, setUpdateApiLoading] = useState(false)

    const handleRoleSaveClose = (values) => {
      return CreateRole(values, setRoleRows, setCreateApiLoading)
    }

    const handleRoleUpdateSaveClose = (values) => {
      return UpdateRole(values, setRoleRows, setUpdateApiLoading)
    }

    return (
      <GridToolbarContainer sx={{ marginBottom: '10px', marginTop: '10px' }}>
        <Button
          onClick={() => {
            setRoleOpen(true)
            setFormEditMode('add')
            setType('Role')
          }}
          color='primary'
          sx={{ position: 'absolute', right: '1px' }}
          startIcon={<AddIcon />}
        >
          Create Role
        </Button>
        <Dialog open={roleOpen} onClose={handleRoleCancelClose} fullWidth maxWidth='sm'>
          {formEditMode === 'add'
            ? (
              <CreateInputForm
                savedData={handleRoleSaveClose} handleCancelClose={handleRoleCancelClose} type={type} selectedRow={selectedRow} createApiLoading={createApiLoading} setCreateApiLoading={setCreateApiLoading}
              />
              )
            : (
              <UpdateInputFrom
                savedData={handleRoleUpdateSaveClose} handleCancelClose={handleRoleCancelClose} type={type} selectedRow={selectedRow} roleParams={roleParams} updateApiLoading={updateApiLoading} setUpdateApiLoading={setUpdateApiLoading}
              />
              )}
        </Dialog>
      </GridToolbarContainer>
    )
  }

  const CreateUser = (value, setUserRows, setCreateApiLoading, generatePasswordSelect) => {
    const requestBody = {
      sponsorCd: value?.sponsorCd,
      roleCode: value?.roleCode,
      userName: value?.userName,
      roleName: value?.roleName,
      userEmail: value?.userEmail
    }

    if (!generatePasswordSelect) {
      requestBody.tmpPwd = value?.tmpPwd
    }

    API.post('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${selectedRow?.sponsorId}/user`, {
      body: requestBody,
      queryStringParameters: {
        newClientUser: value?.showProductTour,
        generatePwd: generatePasswordSelect
      }
    }).then((data) => {
      if (data && data.success) {
        setOpen(false)
        const userId = data?.data?.cognitoSub || randomId()
        const newRow = {
          userId,
          ...{ ...(data?.data ? data?.data : value) },
          isNew: true
        }
        setUserRows((oldRows) => [newRow, ...oldRows])
        setUserPageOptions(prev => ({ ...prev, page: 0 }))
        showSuccess(data?.message)
      }
    }).catch((error) => {
      setCreateApiLoading(false)
      showError(error?.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
    }).finally(() => setCreateApiLoading(false))
  }

  const UpdateUser = (value, setUserRows, setUpdateApiLoading) => {
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/${userProps?.userId}/sponsor/${selectedRow?.sponsorId}`, {
      body: {
        userName: value?.userName,
        userEmail: value?.userEmail
      }
    }).then((res) => {
      if (res && res?.success) {
        setOpen(false)

        const updatedUsers = userRows.map(row => {
          if (row.userId === userProps?.userId) {
            return { ...row, userName: res?.data[0]?.userName, userEmail: res?.data[0]?.userEmail }
          }
          return row
        })

        setUserRows(updatedUsers)
        setUpdateApiLoading(false)
        showSuccess(res?.message)
      }
    }).catch((error) => {
      setUpdateApiLoading(false)
      showError(error?.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
    }).finally(() => {
      setUpdateApiLoading(false)
      setAnchorEl(null)
    })
  }

  const handleUserDelete = (e, id, spnId, setIsDeleteApiLoading) => {
    if (e.target.innerText === 'CONFIRM') {
      setIsDeleteApiLoading(true)
      API.del('baseAclURL', `user-access-control/v1/${user?.userGroup}/sponsor/${spnId}/user/${id}`)
        .then((res) => {
          if (res && res?.success) {
            setUserRows(userRows.filter((row) => row?.userId !== id))
            showSuccess(res?.message)
          }
        }).catch((error) => {
          setIsDeleteApiLoading(false)
          showError(error?.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        }).finally(() => {
          setIsDeleteApiLoading(false)
          setOpenDeleteUserDialog(false)
          setAnchorEl(null)
        })
    }
  }

  const EditToolbar = (props) => {
    const { setUserRows } = props
    const [createApiLoading, setCreateApiLoading] = useState(false)
    const [generatePasswordSelect, setGeneratePasswordSelect] = useState(true)
    const [updateApiLoading, setUpdateApiLoading] = useState(false)

    const handleSaveClose = (values) => {
      CreateUser(values, setUserRows, setCreateApiLoading, generatePasswordSelect)
    }

    const handleUserUpdateSaveClose = (values) => {
      return UpdateUser(values, setUserRows, setUpdateApiLoading)
    }

    return (
      <GridToolbarContainer sx={{ marginBottom: '10px', marginTop: '10px' }}>
        <Button
          onClick={() => {
            setOpen(true)
            setFormEditMode('add')
            setType('User')
          }}
          color='primary'
          sx={{ position: 'absolute', right: '1px' }}
          startIcon={<AddIcon />}
        >
          Create User
        </Button>
        <Dialog open={open} onClose={handleCancelClose} fullWidth maxWidth='sm'>
          {formEditMode === 'add'
            ? (
              <CreateInputForm
                handleCancelClose={handleCancelClose}
                savedData={handleSaveClose} type={type}
                setGeneratePasswordSelect={setGeneratePasswordSelect}
                generatePasswordSelect={generatePasswordSelect}
                selectedRow={selectedRow} roleRows={roleRows}
                setCreateApiLoading={setCreateApiLoading}
                createApiLoading={createApiLoading}
              />
              )
            : (
              <UpdateInputFrom
                handleCancelClose={handleCancelClose}
                savedData={handleUserUpdateSaveClose}
                type={type}
                selectedRow={selectedRow}
                userProps={userProps}
                updateApiLoading={updateApiLoading}
                setUpdateApiLoading={setUpdateApiLoading}
              />
              )}
        </Dialog>
      </GridToolbarContainer>
    )
  }

  return (
    <>
      {modifyUser || modifyRole
        ? (
          <PermissionTable
            roleParams={roleParams}
            setUpdatedRole={setUpdatedRole}
            updatedRole={updatedRole}
            setModifyUser={setModifyUser}
            setModifyRole={setModifyRole}
            setActiveTab={setActiveTab}
            userProps={userProps}
            type={type}
            roleRows={roleRows}
            setType={setType}
          />
          )
        : (
            (activeTab === 'user-tab' || activeTab === 'role-tab' || !modifyUser || !modifyRole) && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {user?.userGroup === 'admin' && (
                      <IconButton
                        className='rounded-circle' onClick={() => {
                          setActiveTab('spn-tab')
                          setAnchorEl(null)
                        }} sx={{ width: '22px', height: '22px', marginRight: '15px', backgroundColor: 'transparent' }}
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                    )}
                    <Typography
                      sx={{
                        color: '#34475A',
                        fontStyle: 'normal',
                        fontFamily: 'Lora',
                        fontWeight: 400,
                        fontSize: '26px'
                        // marginRight: '10px'
                      }}
                    >
                    {selectedRow.sponsorName ? capitalizeName(selectedRow.sponsorName) : spanLoading ? <Skeleton variant='rectangular' width={150} height={30} /> : ''}
                    </Typography>
                    {/* <Tooltip title='Edit Sponsor' placement='bottom'>
                      <Button
                        onClick={() => {
                          setOpen(true)
                          setFormEditMode('edit')
                          setType('Sponsor')
                        }} sx={{ backgroundColor: '#f4f5f7', minWidth: '40px', height: '30px' }}
                      >
                        <EditOutlinedIcon sx={{ color: 'gray' }} />
                      </Button>
                    </Tooltip> */}
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Card variant='outlined'>
                      <CardContent>
                        {spanLoading
                          ? (
                            <ACLLoadingTable headers={userColumns} />
                            )
                          : (
                            <DataGridPro
                              autoHeight
                              rows={userRows}
                              columns={userColumns}
                              density='compact'
                              getRowId={(row) => row?.userId}
                              pageSizeOptions={[10, 15, 25, 50, 75, 100]}
                              initialState={{
                                columns: {
                                  columnVisibilityModel: {
                                    roleCode: false
                                  }
                                }
                              }}
                              paginationModel={{ pageSize: userPageOptions.pageSize, page: userPageOptions.page }}
                              onPaginationModelChange={(event) => {
                                setUserPageOptions({ page: event.page, pageSize: event.pageSize })
                              }}
                              pagination
                              onCellClick={handleUserRowClick}
                              sx={{
                                '& .MuiDataGrid-row:hover': {
                                  cursor: 'pointer'
                                },
                                '& .MuiDataGrid-cell:focus': {
                                  outline: 'none'
                                },
                                [`.${gridClasses.main}`]: {
                                  height: 'calc(100vh - 48px - 39px - 37px - 16px - 16px - 53px - 39px - 10px)'
                                },
                                [`.${gridClasses.columnHeaders}`]: {
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 1
                                },
                                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                                  color: '#74788d',
                                  fontWeight: 600
                                },
                                [`.${gridClasses.virtualScroller}`]: {
                                  overflowY: 'auto !important',
                                  scrollbarWidth: 'none'
                                }
                              }}
                              rowSelection={false}
                              slots={{
                                toolbar: EditToolbar
                              }}
                              slotProps={{
                                toolbar: { setUserRows }
                              }}
                            />
                            )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card variant='outlined'>
                      <CardContent>
                        {spanLoading
                          ? (
                            <ACLLoadingTable headers={rolesColumns} />
                            )
                          : (
                            <DataGridPro
                              autoHeight
                              density='compact'
                              rows={roleRows}
                              columns={rolesColumns}
                              getRowId={(row) => row?.id}
                              initialState={{
                                columns: {
                                  columnVisibilityModel: {
                                    roleDesc: false
                                  }
                                }
                              }}
                              onPaginationModelChange={(event) => {
                                setRolePageOptions({ page: event.page, pageSize: event.pageSize })
                              }}
                              pagination
                              pageSizeOptions={[10, 15, 25]}
                              paginationModel={{ pageSize: rolePageOptions.pageSize, page: rolePageOptions.page }}
                              onCellClick={handleRoleRowClick}
                              rowSelection={false}
                              sx={{
                                '& .MuiDataGrid-row:hover': {
                                  cursor: 'pointer'
                                },
                                '& .MuiDataGrid-cell:focus': {
                                  outline: 'none'
                                },
                                [`.${gridClasses.main}`]: {
                                  height: 'calc(100vh - 48px - 39px - 37px - 16px - 16px - 53px - 39px - 10px)'
                                },
                                [`.${gridClasses.columnHeaders}`]: {
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 1
                                },
                                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                                  color: '#74788d',
                                  fontWeight: 600
                                },
                                [`.${gridClasses.virtualScroller}`]: {
                                  overflowY: 'auto !important',
                                  scrollbarWidth: 'none'
                                }
                              }}
                              slots={{
                                toolbar: EditRoleToolbar
                              }}
                              slotProps={{
                                toolbar: { setRoleRows }
                              }}
                            />
                            )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
            )
          )}
      <DeleteSpnRoleConfirm />
      <DeleteSpnUserConfirm />
    </>
  )
}

export default UserTable
