import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CancelIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, List, ListItem, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import './acl.css'

const passwordValidationMessages = (password) => {
  const validations = [
    {
      message: 'Password must be at least 8 characters long',
      isValid: password.length >= 8
    },
    {
      message: 'Password must not exceed 14 characters',
      isValid: password.length <= 14 && password.length > 1
    },
    {
      message: 'Password must contain at least one lowercase letter',
      isValid: /[a-z]/.test(password)
    },
    {
      message: 'Password must contain at least one uppercase letter',
      isValid: /[A-Z]/.test(password)
    },
    {
      message: 'Password must contain at least one number',
      isValid: /\d/.test(password)
    },
    {
      message: 'Password must contain at least one special character: ! @ # $ % ^ & *',
      isValid: /[!@#$%^&*]/.test(password)
    }
  ]

  return validations
}

const CreateInputForm = ({ savedData, type, handleCancelClose, selectedRow, roleRows, createApiLoading, setCreateApiLoading, setGeneratePasswordSelect, generatePasswordSelect }) => {
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState('')

  const createFromValidationSchema = (type) => {
    let schema
    switch (type) {
      case 'Role':
        schema = Yup.object().shape({
          roleName: Yup.string()
            .min(3, 'Role Name must contain at least three characters')
            .max(50, 'Role Name is Too Long!')
            .required('Role Name Required'),
          roleCode: Yup.string()
            .matches(/^[A-Z0-9]*$/, 'Invalid Role Code. Only capital letters and numbers are allowed.')
            .min(3, 'Role Code must contain at least three character')
            .max(50, 'Role Code is Too Long!')
            .required('Role Code Required')
        })
        break
      case 'User':
        schema = Yup.object().shape({
          userName: Yup.string()
            .min(1, 'Username must contain at least one characters')
            .max(30, 'Username Name is Too Long!')
            .required('Username Required'),
          userEmail: Yup.string()
            .email('Invalid email address')
            .required('Email address Required'),
          roleCode: Yup.string()
            .required('Role Code Required'),
          tmpPwd: generatePasswordSelect
            ? Yup.string()
            : Yup.string()
              .required()
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
              )
        })
        break
      case 'Sponsor':
        schema = Yup.object().shape({
          sponsorName: Yup.string()
            .min(1, 'Sponsor Name must contain at least one character')
            .max(50, 'Sponsor Name is Too Long!')
            .required('Sponsor Name Required'),
          sponsorCd: Yup.string()
            .matches(/^[A-Z0-9]*$/, 'Invalid sponsor code. Only capital letters and numbers are allowed.')
            .min(1, 'Sponsor Code must contain at least one character')
            .max(50, 'Sponsor Code is Too Long!')
            .required('Sponsor Code Required')
        })
        break
      case 'ARIS User' :
        schema = Yup.object().shape({
          userName: Yup.string()
            .min(1, 'Username must contain at least one characters')
            .max(30, 'Username Name is Too Long!')
            .required('Username Required'),
          userEmail: Yup.string()
            .email('Invalid email address')
            .required('Email address Required'),
          tmpPwd: generatePasswordSelect
            ? Yup.string()
            : Yup.string()
              .required()
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
              )
        })
        break
      default:
    }
    return schema
  }

  const initialFormValues = {
    Role: {
      roleName: '',
      roleCode: '',
      roleDesc: ''
    },
    User: {
      userName: '',
      userEmail: '',
      roleCode: '',
      roleName: '',
      sponsorCd: '' || selectedRow?.sponsorCd,
      tmpPwd: '',
      showProductTour: false
    },
    Sponsor: {
      sponsorName: '',
      sponsorCd: ''
    },
    'ARIS User': {
      userName: '',
      userEmail: '',
      tmpPwd: ''
    }
  }

  const formik = useFormik({
    initialValues: initialFormValues[type],
    validationSchema: createFromValidationSchema(type),
    onSubmit: (values) => {
      setCreateApiLoading(true)
      savedData(values)
    }
  })

  useEffect(() => {
    if (type === 'User' && roleRows) {
      setOptions(roleRows?.map((data) => data))
    }
  }, [type, roleRows])

  const handleRoleSelect = (e) => {
    if (e.target.value === 'Generate Password') {
      setGeneratePasswordSelect(true)
    } else if (e.target.value === 'Custom Password') {
      setGeneratePasswordSelect(false)
    }
  }

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
        <Typography
          variant='h6'
          textAlign='center'
          color="#34475A"
          fontWeight={400}
        >
          Add {type} Data
        </Typography>
        <IconButton onClick={handleCancelClose}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          {(() => {
            switch (type) {
              case 'Role':
                return (
                  <form>
                    <TextField
                      margin='dense'
                      id='roleName'
                      name='roleName'
                      label='Role Name'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.roleName && formik.touched.roleName)
                      }
                      helperText={
                        formik.errors.roleName &&
                        formik.touched.roleName &&
                        String(formik.errors.roleName)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.roleName}
                    />
                    <TextField
                      margin='dense'
                      id='roleCode'
                      name='roleCode'
                      label='Role Code'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.roleCode && formik.touched.roleCode)
                      }
                      helperText={
                        formik.errors.roleCode &&
                        formik.touched.roleCode &&
                        String(formik.errors.roleCode)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.roleCode}
                    />
                    <TextField
                      margin='dense'
                      id='roleDesc'
                      name='roleDesc'
                      label='Role Desc'
                      type='text'
                      fullWidth
                      variant='standard'
                      onChange={formik.handleChange}
                      value={formik?.values?.roleDesc}
                    />
                  </form>
                )
              case 'User': {
                const validationMessages = passwordValidationMessages(formik?.values?.tmpPwd)
                return (
                  <form>
                    <TextField
                      margin='dense'
                      id='userName'
                      name='userName'
                      label='User Name'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.userName && formik.touched.userName)
                      }
                      helperText={
                        formik.errors.userName &&
                        formik.touched.userName &&
                        String(formik.errors.userName)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.userName}
                    />
                    <TextField
                      id='roleName'
                      name='roleName'
                      label='roleName'
                      sx={{ display: 'none' }}
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.roleName && formik.touched.roleName)
                      }
                      helperText={
                        formik.errors.roleName &&
                        formik.touched.roleName &&
                        String(formik.errors.roleName)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.roleName}
                    />
                    <TextField
                      margin='dense'
                      id='userEmail'
                      name='userEmail'
                      label='Email'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.userEmail && formik.touched.userEmail)
                      }
                      helperText={
                        formik.errors.userEmail &&
                        formik.touched.userEmail &&
                        String(formik.errors.userEmail)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.userEmail}
                    />
                    <>
                      <InputLabel id='label-id' sx={{ mt: 2, color: Boolean(formik.errors.roleCode && formik.touched.roleCode) && selectedValue === '' ? '#d32f2f' : '' }}>Role Name</InputLabel>
                      <Select
                        labelId='label-id'
                        margin='dense'
                        size='small'
                        id='roleCode'
                        name='roleCode'
                        // sx={{ mb: 2 }}
                        value={selectedValue}
                        error={Boolean(formik.errors.roleCode && formik.touched.roleCode) && selectedValue === ''}
                        fullWidth
                        onChange={(event, newValue) => {
                          formik.setFieldValue('roleCode', newValue?.props?.value)
                          formik.setFieldValue('roleName', newValue?.props?.children ? newValue?.props?.children[2] : '')
                          setSelectedValue(newValue?.props?.value)
                        }}
                      >
                        {options && options.length
                          ? (
                              options.map((option, index) => (
                                <MenuItem value={option?.roleCode} key={index}>
                                  {option?.roleCode} ({option?.roleName})
                                </MenuItem>
                              ))
                            )
                          : []}
                      </Select>
                      {Boolean(formik.errors.roleCode && formik.touched.roleCode) && selectedValue === '' && (
                        <FormHelperText sx={{ color: '#d32f2f' }}>Role Name Required</FormHelperText>
                      )}
                    </>
                    <TextField
                      // margin='dense'
                      id='sponsorCd'
                      name='sponsorCd'
                      label='Sponsor Code'
                      disabled
                      type='text'
                      sx={{ mt: 2 }}
                      InputProps={{
                        readOnly: true
                      }}
                      fullWidth
                      variant='standard'
                      onChange={formik.handleChange}
                      value={formik?.values?.sponsorCd || selectedRow?.sponsorCd}
                    />
                    <FormControl sx={{ marginTop: '10px' }}>
                      <RadioGroup
                        defaultValue='Generate Password'
                        row
                      >
                        <FormControlLabel value='Generate Password' onChange={handleRoleSelect} control={<Radio />} label='Generate Password' />
                        <FormControlLabel value='Custom Password' onChange={handleRoleSelect} control={<Radio />} label='Custom Password' />
                      </RadioGroup>
                    </FormControl>
                    {
                      !generatePasswordSelect && (
                        <TextField
                          margin='dense'
                          id='tmpPwd'
                          name='tmpPwd'
                          label='Temporary Password'
                          type='text'
                          fullWidth
                          variant='standard'
                          error={!generatePasswordSelect
                            ? (validationMessages.some((v) => !v.isValid) && Boolean(formik.touched.tmpPwd)
                                ? validationMessages.some((v) => !v.isValid)
                                : false)
                            : Boolean(formik.errors.tmpPwd && formik.touched.tmpPwd)}
                          helperText={
                            Boolean(formik.touched.tmpPwd) && validationMessages.map((v, index) => (
                              <List key={index} disablePadding dense>
                                <ListItem sx={{ color: v.isValid ? 'green' : '#d32f2f', paddingLeft: '0px !important' }}>
                                  {v.message}
                                  {index < validationMessages.length - 1 && <br />}
                                </ListItem>
                              </List>
                            ))
                        }
                          onChange={formik.handleChange}
                          value={formik?.values?.tmpPwd}
                        />
                      )
                    }
                    <FormControl sx={{ marginTop: '10px' }}>
                      <FormControlLabel
                        name='showProductTour'
                        id='showProductTour'
                        onChange={(event) => formik.setFieldValue('showProductTour', event?.target?.checked)}
                        checked={formik?.values?.showProductTour}
                        control={<Checkbox />}
                        label='Show Product Tour'
                      />
                    </FormControl>
                  </form>
                )
              }
              case 'Sponsor':
                return (
                  <form>
                    <>
                      <TextField
                        margin='dense'
                        id='sponsorName'
                        name='sponsorName'
                        label='Sponsor Name'
                        type='text'
                        fullWidth
                        variant='standard'
                        error={
                          Boolean(formik.errors.sponsorName && formik.touched.sponsorName)
                        }
                        helperText={
                          formik.errors.sponsorName &&
                          formik.touched.sponsorName &&
                          String(formik.errors.sponsorName)
                        }
                        onChange={formik.handleChange}
                        value={formik?.values?.sponsorName}
                      />
                      <TextField
                        margin='dense'
                        id='sponsorCd'
                        name='sponsorCd'
                        label='Sponsor Code'
                        type='text'
                        fullWidth
                        variant='standard'
                        error={
                          Boolean(formik.errors.sponsorCd && formik.touched.sponsorCd)
                        }
                        helperText={
                          formik.errors.sponsorCd &&
                          formik.touched.sponsorCd &&
                          String(formik.errors.sponsorCd)
                        }
                        onChange={formik.handleChange}
                        value={formik?.values?.sponsorCd}
                      />
                    </>
                  </form>
                )
              case 'ARIS User' : {
                const validationMessages = passwordValidationMessages(formik?.values?.tmpPwd)
                return (
                  <>
                    <TextField
                      margin='dense'
                      id='userName'
                      name='userName'
                      label='User Name'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.userName && formik.touched.userName)
                      }
                      helperText={
                        formik.errors.userName &&
                        formik.touched.userName &&
                        String(formik.errors.userName)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.userName}
                    />
                    <TextField
                      margin='dense'
                      id='userEmail'
                      name='userEmail'
                      label='User Email'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.userEmail && formik.touched.userEmail)
                      }
                      helperText={
                        formik.errors.userEmail &&
                        formik.touched.userEmail &&
                        String(formik.errors.userEmail)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.userEmail}
                    />
                    <FormControl sx={{ marginTop: '10px' }}>
                      <RadioGroup
                        defaultValue='Generate Password'
                        row
                      >
                        <FormControlLabel value='Generate Password' onChange={handleRoleSelect} control={<Radio />} label='Generate Password' />
                        <FormControlLabel value='Custom Password' onChange={handleRoleSelect} control={<Radio />} label='Custom Password' />
                      </RadioGroup>
                    </FormControl>
                    {
                      !generatePasswordSelect && (
                        <TextField
                          margin='dense'
                          id='tmpPwd'
                          name='tmpPwd'
                          label='Temporary Password'
                          type='text'
                          fullWidth
                          variant='standard'
                          error={!generatePasswordSelect
                            ? (validationMessages.some((v) => !v.isValid) && Boolean(formik.touched.tmpPwd)
                                ? validationMessages.some((v) => !v.isValid)
                                : false)
                            : Boolean(formik.errors.tmpPwd && formik.touched.tmpPwd)}
                          helperText={
                            Boolean(formik.touched.tmpPwd) && validationMessages.map((v, index) => (
                              <List key={index} disablePadding dense>
                                <ListItem sx={{ color: v.isValid ? 'green' : '#d32f2f', paddingLeft: '0px !important' }}>
                                  {v.message}
                                  {index < validationMessages.length - 1 && <br />}
                                </ListItem>
                              </List>
                            ))
                        }
                          onChange={formik.handleChange}
                          value={formik?.values?.tmpPwd}
                        />
                      )
                    }
                  </>
                )
              }
              default:
            }
          })()}
          {
                createApiLoading
                  ? (
                    <LoadingButton
                      loading
                      loadingPosition='start'
                      variant='contained'
                      fullWidth
                      type='submit'
                      sx={{ mt: 5 }}
                    >
                      Create
                    </LoadingButton>
                    )
                  : (
                    <Button color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 5 }}>
                      Create
                    </Button>
                    )
              }
        </form>
      </DialogContent>
    </>
  )
}

export default CreateInputForm
