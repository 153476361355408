import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import * as Sentry from '@sentry/react'
import '../../../assets/styles/AccountDetailspage.scss'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'

const TradeBlotterMain = () => {
  const [tradeBlotter, setTradeBlotter] = useState()
  const [noDataTradeBlotter, setNoDataTradeBlotter] = useState()
  const [loading, setLoading] = useState(true)
  const { user, checkAccess } = useAuth()

  const getTradeBlotter = () => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.TRADE_BLOTTER })) {
      API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-blotter`,
      {
        queryStringParameters: {
          ...{ ...user?.userGroup === 'adv-classic' ? { resources: encodeURIComponent(JSON.stringify({ "serviceId": "trade", "resourceId": "trade-blotter" })) } : {} } },
      }
    )
        .then((response) => {
          setLoading(false)
          if (response.data?.length === 0) {
            setNoDataTradeBlotter(response.message)
          } else {
            setTradeBlotter(response.data ? response?.data?.map((row) => ({ ...row, id: randomId() })) : [])
            setNoDataTradeBlotter(null)
          }
        })
        .catch((error) => {
          setNoDataTradeBlotter(error.response?.data?.errorInfo?.userMessage)
          setLoading(false)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
    }
  }

  useEffect(() => {
    if (user) {
      getTradeBlotter()
    }
  }, [user])

  const getRowId = (row) => row.id

  const columns = [
    { field: 'securityName', headerName: 'Security Name', flex: 1 },
    { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
    { field: 'lastClosePrice', headerName: 'Last Close Price', type: 'number', flex: 1, align: 'right', headerAlign: 'right' },
    { field: 'marketValue', headerName: 'Market Value', type: 'number', flex: 1, align: 'right', headerAlign: 'right' },
    { field: 'marketValueLive', headerName: 'Live Price', type: 'number', flex: 1, align: 'right', headerAlign: 'right' },
    { field: 'noOfAccountsInTrade', headerName: 'Accounts Trading', type: 'number', flex: 1, align: 'right', headerAlign: 'right' },
    { field: 'side', headerName: 'Side', flex: 1, align: 'right', headerAlign: 'right' },
    { field: 'quantity', headerName: 'Quantities', type: 'number', flex: 1, align: 'right', headerAlign: 'right' },
    {
      field: 'splitEffectiveDate',
      headerName: 'Split Effective Date',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => params.value ? params.value : ''
    },
    {
      field: 'splitRatio',
      headerName: 'Split Ratio',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => params.value ? params.value : ''
    },
    {
      field: 'delistEffectiveDate',
      headerName: 'Delist Effective Date',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => params.value ? params.value : ''
    }
  ]

  return (
    <>
      <Box className='Account-trade'>
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'start', md: 'center' },
            justifyContent: 'space-between'
          }}
          className='tradeMainhead'
        >
          <Typography component='h3' className='page-title' sx={{ marginRight: '20px' }}>Trade Blotter</Typography>
        </Box>
        <Grid container sx={{ my: '20px' }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box className='table-responsive'>
                  {loading
                    ? (
                      <>
                        <table>
                          <thead>
                            <tr>
                              <th>Security Name</th>
                              <th>Local Symbol</th>
                              <th>Last Close Price</th>
                              <th>Market Value</th>
                              <th>Live Price</th>
                              <th>Accounts Trading</th>
                              <th>Side</th>
                              <th>Quantities</th>
                              <th>Split Effective Date</th>
                              <th>Split Ratio</th>
                              <th>Delist Effective Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                              <td>
                                <Skeleton
                                  variant='text'
                                  sx={{ fontSize: '1rem' }}
                                />
                              </td>
                            </tr>
                          </tbody>{' '}
                        </table>
                      </>
                      )
                    : (
                      <>
                        {!tradeBlotter
                          ? (
                            <>
                              {
                                noDataTradeBlotter ||
                                'No trading accounts were optimized for the given dates'
                              }
                            </>
                            )
                          : (
                            <DataGridPro
                              density='compact'
                              autoHeight
                              rows={tradeBlotter}
                              columns={columns}
                              getRowId={getRowId}
                              pagination
                              pageSizeOptions={[15]}
                              initialState={{
                                ...tradeBlotter?.initialState,
                                pagination: { paginationModel: { pageSize: 15 } }
                              }}
                              sx={{ border: 'none' }}
                            />
                            )}
                      </>
                      )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default TradeBlotterMain
