import { useState, useEffect } from 'react'
import { DataGridPro, GridActionsCellItem, GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Box } from '@mui/material'
import CustomTooltipIcon from './CustomTooltipIcon'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useParams } from 'react-router-dom'

const DataTable = ({ data, initialColumns, deleteKey, editData, deleteData, setRowModes, customValidations, deleteIcon = null, showPagination = true }) => {
  const [rowModesModel, setRowModesModel] = useState({})
  const apiRef = useGridApiRef()
  const { checkAccess } = useAuth()
  const params = useParams()
  const [updatedRowValues, setUpdatedRowValues] = useState({})

  const dataSecure = localStorage.getItem('object')
  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []
  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const columns = [
    ...initialColumns,
    ...((
      filterSecureData && filterSecureData[0]?.accountType === 'TRADING' &&
      checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
        { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRADING_PERSONALIZATION })) ||
       (filterSecureData && filterSecureData[0]?.accountType === 'TRANSITION' &&
       checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS,
         { subModuleName: moduleConfig.PERSONALIZATION, component_name: moduleConfig.EDIT_TRANSITION_PERSONALIZATION })))
      ? [
          {
            field: 'actions',
            type: 'actions',
            headerName: (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                Action
                <CustomTooltipIcon
                  text={
                    <>
                      Edit - You can edit existing constraint. <br />
                      Delete/Reset - You can remove active constraint.
                    </>
               }
                  action='help'
                />
              </Box>
            ),
            width: 100,
            getActions: ({ id, row }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

              if (isInEditMode) {
                const actions = [
                  <GridActionsCellItem
                    key='save'
                    disabled={row.error || row.minError || row.maxError}
                    icon={<SaveIcon />}
                    label='Save'
                    onClick={handleSaveClick(id, row)}
                  />,
                  <GridActionsCellItem
                    key='cancel'
                    icon={<CancelIcon />}
                    label='Cancel'
                    className='textPrimary'
                    onClick={handleCancelClick(id, row)}
                    color='inherit'
                  />
                ]
                return actions
              }

              if (!deleteKey) {
                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    key='edit'
                    label='Edit'
                    className='textPrimary'
                    onClick={handleEditClick(id, row)}
                    color='inherit'
                  />
                ]
              }

              return [
                <Tooltip title='Edit Constraint' key='edit'>
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    key='edit'
                    label='Edit'
                    disabled={row.disableAction}
                    className='textPrimary'
                    onClick={handleEditClick(id, row)}
                    color='inherit'
                  />
                </Tooltip>,
                <Tooltip title={deleteIcon ? 'Reset Constraint' : 'Delete Constraint'} key='delete'>
                  <GridActionsCellItem
                    key='delete'
                    disabled={!row[`${deleteKey}`] || row.disableAction}
                    icon={deleteIcon || <RemoveCircleOutlineIcon />}
                    label='Delete'
                    onClick={handleDeleteClick(row[`${deleteKey}`], row)}
                    color='inherit'
                  />
                </Tooltip>
              ]
            }
          }
        ]
      : []]

  const [rows, setRows] = useState(data)
  let constraintData = []
  const deleteFieldData = []

  useEffect(() => {
    setRows(data)
    constraintData = []

    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })

    setRows(prevData => prevData.map((row, index) => (
      updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
        row?.id === updatedData[row?.id]?.id)
      ? {
          ...row,
          error: updatedData[row?.id]?.error,
          errorMessage: updatedData[row?.id]?.errorMessage,
          minError: !!updatedData[row?.id]?.minError,
          maxError: !!updatedData[row?.id]?.maxError
        }
      : row))
  }, [data])

  const tempData = {}
  useEffect(() => {
    Object.keys(rowModesModel).forEach((d) => {
      tempData[d] = apiRef.current.getRowWithUpdatedValues(d)
    })
    setUpdatedRowValues(tempData)
    if (setRowModes) {
      setRowModes(rowModesModel)
    }
  }, [rowModesModel])

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    // change to edit mode for both cash level fields
    if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
      const minCashIndex = rows?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = rows?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      setRowModesModel((prevState) => ({
        ...prevState,
        [rows[minCashIndex].id]: { mode: GridRowModes.Edit },
        [rows[maxCashIndex].id]: { mode: GridRowModes.Edit }
      }))
    } else {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
      setRows(prevData => prevData.map((row, index) => (
        updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
          row?.id === updatedData[row?.id]?.id && row?.id !== id)
        ? {
            ...row,
            error: updatedData[row?.id]?.error,
            errorMessage: updatedData[row?.id]?.errorMessage || '',
            minError: !!updatedData[row?.id]?.minError,
            maxError: !!updatedData[row?.id]?.maxError
          }
        : (row?.id === id && updatedData[row?.id]?.error)
            ? { ...row, error: false, minError: false, maxError: false }
            : row))
    }
  }

  const handleSaveClick = (id, row) => () => {
    // save two fields simultaneously else only one field
    if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
      const minCashIndex = rows?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = rows?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      if (!row.error && !row.minError && !row.maxError) {
        setRowModesModel((prevState) => ({
          ...prevState,
          [rows[minCashIndex].id]: { mode: GridRowModes.View },
          [rows[maxCashIndex].id]: { mode: GridRowModes.View }
        }))
      }
    } else {
      if (!row.error) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
      }
    }
  }

  const handleDeleteClick = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
      const minCashIndex = data?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = data?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      if (rows?.filter((row) => (row[`${deleteKey}`] === rows[minCashIndex]?.accountPropertiesId))?.length) {
        deleteFieldData?.push(rows[minCashIndex]?.accountPropertiesId)
      }
      if (rows?.filter((row) => (row[`${deleteKey}`] === rows[maxCashIndex]?.accountPropertiesId))?.length) {
        deleteFieldData?.push(rows[maxCashIndex]?.accountPropertiesId)
      }
      if (deleteFieldData?.length === 2) {
        deleteData(deleteFieldData)
        setRows(rows.map((row) =>
          (row[`${deleteKey}`] === rows[minCashIndex].accountPropertiesId ||
        row[`${deleteKey}`] === rows[maxCashIndex].accountPropertiesId
            ? { ...row, [`${deleteKey}`]: null }
            : (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
            row?.id === updatedData[row?.id]?.id
          )
            ? {
                ...row,
                error: updatedData[row?.id]?.error,
                errorMessage: updatedData[row?.id]?.errorMessage,
                minError: updatedData[row?.id]?.maxError ? true : !!updatedData[row?.id]?.minError,
                maxError: updatedData[row?.id]?.minError ? true : !!updatedData[row?.id]?.maxError
              }
            : row[`${deleteKey}`] === id
              ? { ...row, [`${deleteKey}`]: null }
              : row))
      }
    } else {
      if (!row.error) {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
      }
      if (rows.filter((row) => (row[`${deleteKey}`] === id))?.length) {
        deleteData(id)
        setRows(prevData => prevData.map((row, index) => (
          (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
                row?.id === updatedData[row?.id]?.id
        )
          ? {
              ...row,
              error: updatedData[row?.id]?.error,
              errorMessage: updatedData[row?.id]?.errorMessage,
              minError: !!updatedData[row?.id]?.minError,
              maxError: !!updatedData[row?.id]?.maxError
            }
          : row[`${deleteKey}`] === id
            ? { ...row, [`${deleteKey}`]: null }
            : row))
      }
    }
  }

  const handleCancelClick = (id, row) => () => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    // cancel both cash level field changes
    if (row.propertyCode === 'MIN_CASH_LEVEL' || row.propertyCode === 'MAX_CASH_LEVEL') {
      const minCashIndex = data?.findIndex((item) => item.propertyCode === 'MIN_CASH_LEVEL')
      const maxCashIndex = data?.findIndex((item) => item.propertyCode === 'MAX_CASH_LEVEL')
      setRowModesModel((prevState) => ({
        ...prevState,
        [rows[minCashIndex].id]: { mode: GridRowModes.View, ignoreModifications: true },
        [rows[maxCashIndex].id]: { mode: GridRowModes.View, ignoreModifications: true }
      }))
      setRows(rows.map((row) => {
        if (row?.id === rows[minCashIndex]?.id || row?.id === rows[maxCashIndex]?.id) {
          return { ...row, error: false }
        }
        if (updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) && row?.id === updatedData[row?.id]?.id) {
          return {
            ...row,
            error: updatedData[row?.id]?.error,
            errorMessage: updatedData[row?.id].errorMessage,
            minError: !!updatedData[row?.id]?.minError,
            maxError: !!updatedData[row?.id]?.maxError
          }
        }
        return row
      }))
    } else {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true }
      })
      const editedRow = rows.find((row) => row?.id === id)
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row?.id !== id))
      }
      setRows(prevData => prevData.map((row, index) => (
        (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
        row?.id === updatedData[row?.id]?.id && row?.id !== id
      )
        ? {
            ...row,
            error: updatedData[row?.id]?.error,
            errorMessage: updatedRowValues[row?.id]?.errorMessage || updatedData[row?.id]?.errorMessage,
            minError: !!(((updatedRowValues[row?.id]?.minError) || updatedData[row?.id]?.minError)),
            maxError: !!((updatedRowValues[row?.id]?.maxError || updatedData[row?.id]?.maxError))
          }
        : (row?.id === id && updatedData[row?.id]?.error)
            ? { ...row, error: false, minError: false, maxError: false }
            : row))
    }
  }

  const processRowUpdate = (newRow) => {
    const updatedData = {}
    rows.forEach((d) => {
      updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
    })
    if (newRow.propertyCode === 'MIN_CASH_LEVEL' || newRow.propertyCode === 'MAX_CASH_LEVEL') {
      const updatedRow = { ...newRow }
      // setRows(prevData => prevData.map((row) => (row?.id === newrow?.id ? updatedRow : row)))
      setRows(prevData =>
        prevData.map((row) =>
          row?.id === newRow?.id
            ? updatedRow
            : (updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
              row?.id === updatedData[row?.id]?.id)
                ? {
                    ...row,
                    error: updatedData[row?.id]?.error,
                    errorMessage: updatedData[row?.id]?.errorMessage,
                    minError: !!updatedData[row?.id]?.minError,
                    maxError: !!updatedRowValues[row?.id]?.maxError
                  }
                : row
        )
      )
      constraintData.push(updatedRow)
      // pass multiple field to editData
      // ex. here we are passing two fields min cash and max cash in array form else pass only single field
      if (constraintData?.length === 2) {
        if (customValidations) {
          if (!customValidations(constraintData)) {
            editData(constraintData)
          }
        } else {
          editData(constraintData)
        }
      }
      return updatedRow
    } else {
      const updatedRow = { ...newRow }
      setRows(prevData =>
        prevData.map((row) =>
          row?.id === updatedRow?.id
            ? updatedRow
            : (updatedData[row?.id] && (updatedData[row?.id]?.error || updatedData[row?.id]?.minError || updatedData[row?.id]?.maxError) &&
              row?.id === updatedData[row?.id]?.id)
                ? {
                    ...row,
                    error: updatedData[row?.id]?.error,
                    errorMessage: updatedData[row?.id]?.errorMessage,
                    minError: !!updatedData[row?.id]?.minError,
                    maxError: !!updatedData[row?.id]?.maxError
                  }
                : row
        )
      )
      if (customValidations) {
        if (!customValidations(updatedRow)) {
          editData(updatedRow)
        }
      } else {
        editData(updatedRow)
      }
      return updatedRow
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  function getRowId (row) {
    return row?.id
  }

  return (
    <>
      <Box
        sx={{
          '.MuiDataGrid-footerContainer': {
            border: 'none'
          },
          '.MuiDataGrid-root': {
            border: 'none'
          },
          '.MuiDataGrid-row--editing': {
            background: 'rgba(25, 118, 210, 0.08) !important'
          },
          '.MuiDataGrid-row--editing .MuiDataGrid-cell': {
            backgroundColor: 'inherit !important'
          },
          '.MuiDataGrid-cell--editing': {
            padding: '0 10px !important'
          }
        }}
      >
        <div style={{ width: '100%' }}>
          <DataGridPro
            autoHeight
            rows={rows}
            columns={columns}
            getRowId={getRowId}
            pageSizeOptions={[10]}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            pagination={showPagination}
            disableRowSelectionOnClick
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            apiRef={apiRef}
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEditStop}
            processRowUpdate={processRowUpdate}
            getRowClassName={(params) => rowModesModel[params.row?.id]?.mode === GridRowModes.Edit ? 'MuiDataGrid-row--editing' : ''}
            sx={{
              fontFamily: 'Open Sans',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#34475A'
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                fontWeight: 600,
                fontFamily: 'Open Sans',
                color: '#74788d'
              }
            }}
          />
        </div>
      </Box>
    </>
  )
}

export default DataTable
