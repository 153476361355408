import { Fragment, useState, useEffect, useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { gridFilteredDescendantCountLookupSelector, DataGridPro, useGridSelector, gridClasses, useGridApiRef, useGridApiContext } from '@mui/x-data-grid-pro'
import { Box, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button, IconButton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuth } from '../../contexts/AuthContext'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useErrorToast } from '../../hooks/useErrorToast'
import { ACLLoadingTable } from './ACLLoadingTable'

const columns = [
  {
    field: 'type',
    headerName: 'Type',
    flex: 2
  },
  {
    field: 'code',
    headerName: 'Code',
    flex: 2
  }
]

let expansionLookupACL = {}

const ManageTradeGroupTab = (props) => {
  const {
    allAggregateGroupsList,
    aggregateGroupsLoading,
    selectionModel,
    allAggregateGroupsFlag,
    // setAllAggregateGroupsFlag,
    setSelectionModel,
    defaultCheckBoxApiLoading,
    setSpnGroupAccountsFlag,
    spnGroupAccountsFlag,
    modelProps
  } = props

  const { user } = useAuth()
  const nestedGridRef = useGridApiRef()
  const [row, setRow] = useState([])
  const { showSuccess } = useSuccessToast()
  const { showError } = useErrorToast()
  const [isSortProcessLoading, setIsSortProcessLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState('')
  const [idSaveLoading, setIdSaveLoading] = useState(false)
  const [allSpnGroups, setAllSpnGroups] = useState([])
  const [isAggregateGroupsSorting, setIsAggregateGroupsSorting] = useState(true)
  const [sortAggregateGroupsFlag, setSortAggregateGroupsFlag] = useState(true)
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const handleSelectionChange = (newSelection) => {
    getSelectionModel(row, newSelection)
    setSortAggregateGroupsFlag(false)
  }

  const getSelectionModel = (rows, selectionModel) => {
    const selectedIds = new Set(selectionModel)
    const updatedSelection = new Set(selectionModel)
    rows?.forEach((r) => {
      // Get the immediate parent hierarchy
      const parentHierarchy = r?.hierarchy?.slice(0, -1).join(',')
      const parentRow = row?.find((r) => r?.hierarchy.join(',') === parentHierarchy)

      if (parentRow && selectedIds?.has(parentRow?.id)) {
        // If parent is selected, ensure child is selected
        updatedSelection.add(r?.id)
      } else if (parentRow && !selectedIds?.has(parentRow?.id)) {
        // If parent is deselected, ensure child is deselected
        updatedSelection.delete(r?.id)
      }
    })
    setSelectionModel(Array.from(updatedSelection))
  }

  useEffect(() => {
    if (!aggregateGroupsLoading && !defaultCheckBoxApiLoading) {
      if (spnGroupAccountsFlag) {
        setCurrentTab('All Sponsor Groups')
        const spnGroupList = allAggregateGroupsList?.filter((d) => d.sponsorId === modelProps?.sponsor?.sponsorId)?.map((data) => data.aggGroupId)
        setAllSpnGroups(spnGroupList)
      } else {
        setCurrentTab('Select Manually')
      }
    }
  }, [allAggregateGroupsFlag, spnGroupAccountsFlag, aggregateGroupsLoading, defaultCheckBoxApiLoading])

  useEffect(() => {
    if (selectionModel?.length > 0 && !allAggregateGroupsFlag && !spnGroupAccountsFlag) {
      if (allAggregateGroupsList) {
        if (sortAggregateGroupsFlag && selectionModel?.length > 0) {
          const reorderedRows = [...allAggregateGroupsList].sort((a, b) => {
            const isSelectedA = sortAggregateGroupsFlag && selectionModel?.includes(a?.aggGroupId)
            const isSelectedB = sortAggregateGroupsFlag && selectionModel?.includes(b?.aggGroupId)
            return isSelectedB - isSelectedA
          })
          if (reorderedRows && reorderedRows.length > 0 && selectionModel?.length > 0) {
            setIsAggregateGroupsSorting(false)
            const processedRows = getTreeData(reorderedRows)
            if (isInitialLoading) {
              getSelectionModel(processedRows, selectionModel)
              setIsInitialLoading(false)
            }
            setRow(processedRows)
          }
          setIsSortProcessLoading(false)
        }
      }
    } else {
      if (spnGroupAccountsFlag) {
        const allSpnAccounts = allAggregateGroupsList?.filter((d) => d.sponsorId === modelProps?.sponsor?.sponsorId)
        const processedRows = getTreeData(allSpnAccounts)
        if (isInitialLoading && allSpnAccounts?.length) {
          getSelectionModel(processedRows, allSpnAccounts.map((data) => data.aggGroupId))
          setIsInitialLoading(false)
        }
        setRow(processedRows)
        setIsAggregateGroupsSorting(false)
        setIsSortProcessLoading(false)
      } else if (allAggregateGroupsList && !spnGroupAccountsFlag) {
        const processedRows = getTreeData(allAggregateGroupsList)
        if (isInitialLoading && selectionModel?.length) {
          getSelectionModel(processedRows, selectionModel)
          setIsInitialLoading(false)
        }
        setRow(processedRows)
        setIsAggregateGroupsSorting(false)
        setIsSortProcessLoading(false)
      }
    }
  }, [allAggregateGroupsList, modelProps, sortAggregateGroupsFlag, spnGroupAccountsFlag, allAggregateGroupsFlag, selectionModel])

  const handleAccountTypeChange = (e) => {
    if (e.target.value === 'Select Manually') {
      setCurrentTab(e.target.value)
      setSpnGroupAccountsFlag(false)
      setSortAggregateGroupsFlag(true)
    } else if (e.target.value === 'All Sponsor Groups') {
      setCurrentTab(e.target.value)
      const spnGroupList = allAggregateGroupsList?.filter((d) => d.sponsorId === modelProps?.sponsor?.sponsorId)?.map((data) => data.aggGroupId)
      setAllSpnGroups(spnGroupList)
      setSpnGroupAccountsFlag(true)
      getSelectionModel(row, spnGroupList)
    }
  }

  const handleAccountIdsSave = () => {
    setIdSaveLoading(true)
    API.patch('baseAclURL', `user-access-control/v1/${user?.userGroup}/user/aggregate/${modelProps?.userId}`, {
      queryStringParameters: {
        'event-type': 'GROUP'
      },
      body: [{
        serviceId: modelProps?.serviceId,
        resourceId: modelProps?.resourceId,
        allAggGroups: false,
        allSpnAggGroups: currentTab === 'All Sponsor Groups',
        aggGroupIds: currentTab === 'Select Manually' ? selectionModel?.filter(ids => ids.split('**').length === 1) : currentTab === 'All Sponsor Groups' ? allSpnGroups : []
      }]
    }).then((res) => {
      if (res && res?.success) {
        showSuccess(res?.message)
        setIdSaveLoading(false)
      }
    }).catch((error) => {
      setIdSaveLoading(false)
      showError(error?.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
    }).finally(() => {
      setIdSaveLoading(false)
    })
  }

  const getTreeData = (data) => {
    const parentRows = {}
    data?.forEach(item => {
      const currItem = item.groupName
      const groupObj = {}
      groupObj.id = item.aggGroupId
      groupObj.hierarchy = [item.aggGroupId]
      groupObj.groupId = item.aggGroupId
      groupObj.name = item.groupName
      groupObj.code = item.groupCd
      groupObj.type = item.groupType
      groupObj.isGroupRow = true
      parentRows[currItem] = groupObj
    })

    const childRows = {}
    data?.forEach(item => {
      item.accounts?.forEach(account => {
        // Each account should be a child of its respective groupName
        if (!childRows[item.groupName]) {
          childRows[item.groupName] = []
        }

        childRows[item.groupName].push({
          id: account.accountId + '**' + item.aggGroupId,
          accountId: account.accountId,
          hierarchy: [item.aggGroupId, account.accountId + '**' + item.aggGroupId],
          name: account.accountName,
          type: account.accountType,
          isGroupRow: false,
          code: account.accountCd
        })
      })
    })

    let finalArray = []
    for (const key in parentRows) {
      finalArray.push(parentRows[key])
      finalArray = finalArray.concat(childRows[key])
    }
    return finalArray
  }

  const CustomGridTreeDataGroupingCell = (props) => {
    const { id, field, rowNode } = props
    const apiRef = useGridApiContext()
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    )
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

    const handleClick = (event) => {
      if (rowNode.type !== 'group') {
        return
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
      apiRef.current.setCellFocus(id, field)
      event.stopPropagation()
    }

    return (
      <Box sx={{
        marginLeft: '-10px'
      }}>
        {filteredDescendantCount > 0
          ? (
            <>
              <IconButton
                size='small'
                onClick={handleClick}
                aria-label={rowNode.childrenExpanded ? 'Close' : 'Open'}
              >
                <ExpandMoreIcon
                  sx={{
                    transform: `rotateZ(${rowNode.childrenExpanded ? 360 : 270}deg)`,
                    transition: (theme) =>
                      theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest
                      })
                  }}
                  fontSize='inherit'
                />
              </IconButton>
              <span>
                {props?.row?.name}
              </span>
            </>
            )
          : (
            <span style={{ marginLeft: rowNode.depth * 27 }}>
              {props?.row?.name}
            </span>
            )}
      </Box>
    )
  }

  useEffect(() => {
    return () => {
      expansionLookupACL = {}
      setIsInitialLoading(true)
    }
  }, [])

  useEffect(() => {
    if (nestedGridRef?.current?.subscribeEvent) {
      nestedGridRef?.current?.subscribeEvent('rowExpansionChange', (node) => {
        if (node?.groupingKey) {
          expansionLookupACL[node?.groupingKey] = node?.childrenExpanded
        }
      })
    }
  }, [nestedGridRef, currentTab])

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return node?.groupingKey && !!expansionLookupACL?.[node?.groupingKey]
    },
    []
  )

  return (
    <Box sx={{ width: 'auto', height: '500px', minHeight: '500px', maxHeight: '500px', overflow: 'auto' }}>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={1} mt={1}>
        <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px 0 0 16px' }}>
          <RadioGroup
            name='radio-buttons-group'
            style={{ display: 'flex', flexDirection: 'row', marginLeft: '0px' }}
          >
            <FormControlLabel
              checked={currentTab === 'Select Manually' && !allAggregateGroupsFlag && !spnGroupAccountsFlag && !isAggregateGroupsSorting && !isSortProcessLoading && !aggregateGroupsLoading && !defaultCheckBoxApiLoading} value='Select Manually' onChange={handleAccountTypeChange} control={<Radio />} label='Select Manually'
              disabled={defaultCheckBoxApiLoading || aggregateGroupsLoading || isAggregateGroupsSorting || isSortProcessLoading}
            />
            <FormControlLabel
              disabled={defaultCheckBoxApiLoading || aggregateGroupsLoading || isAggregateGroupsSorting || isSortProcessLoading} value='All Sponsor Groups'
              onChange={handleAccountTypeChange} control={<Radio />} label='All Sponsor Groups'
              defaultChecked={false}
              checked={currentTab === 'All Sponsor Groups' && spnGroupAccountsFlag && !isAggregateGroupsSorting && !isSortProcessLoading && !aggregateGroupsLoading && !defaultCheckBoxApiLoading}
            />
          </RadioGroup>
        </FormControl>
        {idSaveLoading
          ? (
            <LoadingButton
              loading
              loadingPosition='start'
              variant='outlined'
              sx={{ float: 'right', width: '120px' }}
            >
              Apply
            </LoadingButton>
            )
          : (
            <Button
              disabled={defaultCheckBoxApiLoading || aggregateGroupsLoading || row?.length < 1 || isAggregateGroupsSorting}
              variant='contained' color='primary' sx={{ float: 'right', width: '100px' }} onClick={handleAccountIdsSave}
            >Apply
            </Button>
            )}
      </Grid>
      <Grid container xs={12} justifyContent='space-between' alignItems='center' mb={2}>
        {(isSortProcessLoading || defaultCheckBoxApiLoading || aggregateGroupsLoading || isAggregateGroupsSorting || !currentTab)
          ? (
            <ACLLoadingTable headers={[{ headerName: 'Name' }, ...columns]} />
            )
          : (
            <DataGridPro
              apiRef={nestedGridRef}
              autoHeight
              density='compact'
              treeData
              rows={row}
              checkboxSelection
              columns={columns}
              rowSelectionModel={selectionModel}
              disableRowSelectionOnClick
              isRowSelectable={(params) => {
                return params.row.hierarchy.length === 1 && currentTab === 'Select Manually'
              }}
              // getRowClassName={(params) => params.row.hierarchy === 1 ? 'disable-check' : ''}
              onRowSelectionModelChange={(e) => handleSelectionChange(e)}
              getRowId={(row) => row.id}
              getTreeDataPath={(row) => row.hierarchy}
              groupingColDef={{
                headerName: 'Name',
                flex: 2,
                filterable: true,
                sortable: true,
                disableColumnMenu: false,
                valueGetter: (params) => params?.row?.name,
                renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
              }}
              isGroupExpandedByDefault={isGroupExpandedByDefault}
              pageSizeOptions={[10]}
              onPaginationModelChange={(event) => setCurrentPage(event.page)}
              pagination
              paginationModel={{ pageSize: 10, page: currentPage }}
              initialState={{
                ...row?.initialState
              }}
              sx={{
                '&.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                  display: (currentTab === 'All Sponsor Groups') ? 'none' : ''
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none'
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: 0,
                  zIndex: 1
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.virtualScroller}`]: {
                  overflowY: 'auto !important',
                  scrollbarGutter: 'stable',
                  scrollbarWidth: 'none'
                }
              }}
            />
            )}
      </Grid>
    </Box>
  )
}

export default ManageTradeGroupTab
