import { Box, Tooltip, Typography } from '@mui/material'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import { formatCurrency } from '../../Report/components/dataProcess/DataProcess'

const CustomHeader = ({ title, text }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip title={title} placement='bottom'>
          <span style={{ lineHeight: '30px' }}>{title}</span>
        </Tooltip>
        <span style={{ lineHeight: '16px', minHeight: '20px', fontSize: '12px' }}>{text}</span>
      </Box>
    </>
  )
}

const positionGroupTableHeader = [
  {
    field: 'modelWeight',
    headerName: 'Model Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Model Weight (%)' text='' />,
    renderCell: (params) => parseFloat(params?.row?.modelWeight * 100).toFixed(2)
  },
  {
    field: 'initWeight',
    headerName: 'Initial Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => parseFloat(params?.row?.initWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Initial Weight (%)' text='I.W' />
  },
  {
    field: 'propWeight',
    headerName: 'Proposed Weight (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Weight (%)' text='P.W' />
  },
  {
    field: 'propDrift',
    headerName: 'Proposed Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => (params?.row?.propDrift * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Proposed Drift (%)' text='P.D = M.W - P.W' />
  },
  {
    field: 'initDrift',
    headerName: 'Initial Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderCell: (params) => parseFloat(params?.row?.initDrift * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Initial Drift (%)' text='I.D = M.W - I.W' />
  },
  {
    field: 'tradeDrift',
    headerName: 'Trade Drift (%)',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    valueGetter: (params) => parseFloat((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2),
    renderHeader: (params) => <CustomHeader title='Trade Drift (%)' text='T.D = abs(P.D) - abs(I.D)' />,
    renderCell: (params) => {
      const absDiff = parseFloat(((Math.abs(params?.row?.propDrift) - Math.abs(params?.row?.initDrift)) * 100)?.toFixed(2))
      return (<>{absDiff < 0 ? <ArrowDownward fontSize='small' sx={{ color: '#3BBFA3' }} /> : (absDiff > 0) ? <ArrowUpward fontSize='small' sx={{ color: '#FF6161' }} /> : ''}<Typography fontSize='12px' fontWeight='600' color={absDiff < 0 ? '#3BBFA3' : (absDiff > 0) ? '#FF6161' : '#34475A'}>{absDiff?.toFixed(2)}</Typography></>)
    }
  }
]

// security level trade table
const tradeTableHeaders = [
  { field: 'instr_id', headerName: 'Instrument Id', flex: 1 },
  {
    field: 'trdCode',
    headerName: 'Trade Code',
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
    </>),
    flex: 1
  },
  { field: 'initShares', headerName: 'Initial Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
  { field: 'propShares', headerName: 'Proposed Shares', type: 'number', valueFormatter: (params) => params?.value?.toFixed(2), align: 'right', headerAlign: 'right', flex: 1 },
  {
    field: 'trdQty',
    headerAlign: 'right',
    headerName: 'Trade Quantity',
    type: 'number',
    valueFormatter: (params) => params?.value?.toFixed(2),
    align: 'right',
    flex: 1
  }
]

const detailColumns = [
  { field: '', headerName: '', width: 50, sortable: false, disableColumnMenu: true, flex: 1 },
  { field: 'instrId', headerName: 'Instrument ID', flex: 1 },
  {
    field: 'trdCode',
    headerName: 'Trade Code',
    flex: 1,
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
    </>)
  },
  { field: 'trdQty', headerName: 'Trade Quantity', type: 'number', align: 'right', headerAlign: 'right', flex: 1 },
  { field: 'trdDate', headerName: 'Trade Date', flex: 1, valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : '' },
  {
    field: 'initMarketValue',
    flex: 1,
    type: 'number',
    headerName: 'Initial Market Value',
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => !isNaN(params?.row?.initMarketValue)
      ? params?.row?.initMarketValue < 0
        ? `-$${formatCurrency(params?.row?.initMarketValue, 2)}`
        : `$${formatCurrency(params?.row?.initMarketValue, 2)}`
      : 'N/A'
  },
  { field: 'initShares', headerName: 'Initial Shares', type: 'number', align: 'right', headerAlign: 'right', flex: 1 },
  {
    field: 'propMarketValue',
    headerName: 'Proposed Market Value',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.propMarketValue)
      ? params?.row?.propMarketValue < 0
        ? `-$${formatCurrency(params?.row?.propMarketValue, 2)}`
        : `$${formatCurrency(params?.row?.propMarketValue, 2)}`
      : 'N/A'
  },
  {
    field: 'lastClosePrice',
    headerName: 'Last Close Price',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
      ? params?.row?.lastClosePrice < 0
        ? `-$${formatCurrency(params?.row?.lastClosePrice, 2)}`
        : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
      : 'N/A'
  },
  {
    field: 'totalMarketValue',
    headerName: 'Total Market Value',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.totalMarketValue)
      ? params?.row?.totalMarketValue < 0
        ? `-$${formatCurrency(params?.row?.totalMarketValue, 2)}`
        : `$${formatCurrency(params?.row?.totalMarketValue, 2)}`
      : 'N/A'
  },
  {
    field: 'orgPurchasePrice',
    headerName: 'Org Purchase Price',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.orgPurchasePrice)
      ? params?.row?.orgPurchasePrice < 0
        ? `-$${formatCurrency(params?.row?.orgPurchasePrice, 2)}`
        : `$${formatCurrency(params?.row?.orgPurchasePrice, 2)}`
      : 'N/A'
  },
  {
    field: 'rgl',
    headerName: 'Realized Gain/Loss',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.rgl)
      ? params?.row?.rgl < 0
        ? `-$${formatCurrency(params?.row?.rgl, 2)}`
        : `$${formatCurrency(params?.row?.rgl, 2)}`
      : 'N/A'
  },
  {
    field: 'initUrgl',
    headerName: 'Initial Unrealized Gain/Loss',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    renderCell: (params) => !isNaN(params?.row?.initUrgl)
      ? params?.row?.initUrgl < 0
        ? `-$${formatCurrency(params?.row?.initUrgl, 2)}`
        : `$${formatCurrency(params?.row?.initUrgl, 2)}`
      : 'N/A'
  },
  { field: 'costBasis', headerName: 'Cost Basis', align: 'right', headerAlign: 'right', flex: 1 },
  { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 }
]

const tradeBlotterHeaders = [
  {
    field: 'instrId',
    headerName: 'Instrument Id',
    renderHeader: (params) => <CustomHeader title='Instrument Id' />,
    flex: 1
  },
  {
    field: 'localSymbol',
    headerName: 'Local Symbol',
    renderHeader: (params) => <CustomHeader title='Local Symbol' />,
    flex: 0.7
  },
  {
    field: 'side',
    headerName: 'Trade Code',
    renderHeader: (params) => <CustomHeader title='Trade Code' text='TC' />,
    renderCell: (params) => (<>
      <span style={{ color: params.formattedValue === 'SELL' ? '#FF6161' : params.formattedValue === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.formattedValue}</span>
    </>),
    flex: 0.7
  },
  {
    field: 'lastClosePrice',
    headerName: 'Last Close Price',
    type: 'number',
    valueGetter: (params) => params?.value?.toFixed(2),
    renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
      ? params?.row?.lastClosePrice < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.lastClosePrice, 2))}`
        : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
      : 'N/A',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderHeader: (params) => <CustomHeader title='Last Close Price' text='LCP' />
  },
  {
    field: 'livePrice',
    headerName: 'Live Price',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Live Price' text='LP' />,
    renderCell: (params) => {
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }

      return (
        <div style={cellStyle}>
          {params?.row?.livePrice !== null
            ? params?.row?.livePrice < 0
              ? `-$${formatCurrency(Math.abs(params?.row?.livePrice, 2))}`
              : `$${formatCurrency(params?.row?.livePrice, 2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 0.6,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'marketValue',
    headerName: 'Market Value',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Market Value' text='MV' />,
    renderCell: (params) => !isNaN(params?.row?.marketValue)
      ? params?.row?.marketValue < 0
        ? `-$${formatCurrency(Math.abs(params?.row?.marketValue, 2))}`
        : `$${formatCurrency(params?.row?.marketValue, 2)}`
      : 'N/A',
    flex: 0.8,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'liveMarketValue',
    headerName: 'Live Market Value',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Live Market Value' text='LMV' />,
    renderCell: (params) => {
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }

      return (
        <div style={cellStyle}>
          {params?.row?.liveMarketValue !== null
            ? params?.row?.liveMarketValue < 0
              ? `-$${formatCurrency(Math.abs(params?.row?.liveMarketValue, 2))}`
              : `$${formatCurrency(params?.row?.liveMarketValue, 2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 1,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'priceChange',
    type: 'number',
    renderCell: (params) => {
      const isPriceChangeNull = params?.row?.priceChange === null
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }
      return (
        <div style={cellStyle}>
          {!isPriceChangeNull
            ? `${(params?.row?.priceChange * 100).toFixed(2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    headerName: 'Price Change (%)',
    valueGetter: (params) => (params.value * 100).toFixed(2),
    renderHeader: (params) => <CustomHeader title='Price Change (%)' text='PC = (LP - LCP)/LP' />
  },
  {
    field: 'marketValueChange',
    type: 'number',
    renderCell: (params) => {
      const isMarketValueChangeNull = params?.row?.marketValueChange === null
      const isLivePrice = params?.row?.isLivePrice === 1

      const cellStyle = {
        color: !isLivePrice ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        textAlign: 'right'
      }
      return (
        <div style={cellStyle}>
          {!isMarketValueChangeNull
            ? `${(params?.row?.marketValueChange).toFixed(2)}`
            : 'N/A'}
        </div>
      )
    },
    flex: 1.4,
    align: 'right',
    headerAlign: 'right',
    headerName: 'Trade Value Change',
    renderHeader: (params) => <CustomHeader title='Trade Value Change' text='TVC = TC *(LMV - MV)' />
  },
  {
    field: 'quantity',
    headerName: 'Quantities',
    type: 'number',
    renderHeader: (params) => <CustomHeader title='Quantities' />,
    flex: 0.8,
    align: 'right',
    headerAlign: 'right'
  },
  {
    field: 'splitEffectiveDate',
    headerName: 'Split Effective Date',
    renderHeader: (params) => <CustomHeader title='Split Effective Date' />,
    flex: 1
  },
  {
    field: 'splitRatio',
    headerName: 'Split Ratio',
    renderHeader: (params) => <CustomHeader title='Split Ratio' />,
    flex: 0.6
  },
  {
    field: 'delistEffectiveDate',
    headerName: 'Delist Effective Date',
    renderHeader: (params) => <CustomHeader title='Delist Effective Date' />,
    flex: 1.1
  }
]

const modelChangesHeaders = [
  { field: 'instrId', headerName: 'Instrument Id', flex: 1 },
  {
    field: 'tradeCode',
    headerName: 'Trade Code',
    flex: 1,
    renderCell: (params) => (<>
      <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
    </>
    )
  },
  {
    field: 'previousSecurityWeight',
    headerName: 'Previous Weight (%)',
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    renderCell: (props) => props?.row?.previousSecurityWeight !== undefined && props?.row?.previousSecurityWeight !== null
      ? ((props?.row?.previousSecurityWeight) * 100).toFixed(2)
      : '0.00',
    valueGetter: (props) => props?.row?.previousSecurityWeight !== undefined && props?.row?.previousSecurityWeight !== null
      ? Number(((props?.row?.previousSecurityWeight) * 100).toFixed(2))
      : 0.00,
    flex: 1
  },
  {
    field: 'currentSecurityWeight',
    headerName: 'Current Weight (%)',
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    renderCell: (props) => props?.row?.currentSecurityWeight !== undefined && props?.row?.currentSecurityWeight !== null
      ? ((props?.row?.currentSecurityWeight) * 100).toFixed(2)
      : '0.00',
    valueGetter: (props) => props?.row?.currentSecurityWeight !== undefined && props?.row?.currentSecurityWeight !== null
      ? Number(((props?.row?.currentSecurityWeight) * 100).toFixed(2))
      : 0.00,
    flex: 1
  },
  {
    field: 'drift',
    headerName: 'Weight Change(%)',
    align: 'right',
    headerAlign: 'right',
    type: 'number',
    sortComparator: (a, b) => Math.abs(a) - Math.abs(b),
    flex: 1,
    // renderHeader: () => <CustomHeader title='Weight Change(%)' text='WC = UPW - CPW' />,
    renderCell: (params) => {
      const driftVal = params?.row?.drift
      const invalid = Math.abs(parseFloat(driftVal * 100).toFixed(2)) === 0
      return (
        <>
          {driftVal < 0 && !invalid
            ? <ArrowCircleDownIcon fontSize='small' sx={{ color: '#FF6161', marginRight: '2px' }} />
            : driftVal > 0 && !invalid
              ? <ArrowCircleUpIcon fontSize='small' sx={{ color: '#3BBFA3', marginRight: '2px' }} />
              : ''}
          <Typography
            fontSize='12px'
            fontWeight='600'
            color={parseFloat(driftVal * 100).toFixed(2) < 0 && !invalid
              ? '#FF6161'
              : (parseFloat(driftVal * 100).toFixed(2) > 0 && !invalid)
                ? '#3BBFA3'
                : ''}
            sx={{ width: '35px', textAlign: 'right', display: 'inline-block' }}
          >
            {Math.abs(parseFloat(driftVal * 100).toFixed(2)) === 0 ? '0.00' : parseFloat(driftVal * 100).toFixed(2)}
          </Typography>
        </>
      )
    },
    valueGetter: (props) => props?.row?.drift !== undefined && props?.row?.drift !== null
      ? Math.abs(parseFloat(props?.row?.drift * 100).toFixed(2)) === 0 ? 0.00 : parseFloat(parseFloat(props?.row?.drift * 100).toFixed(2))
      : 0.00
  }
]

export { CustomHeader, detailColumns, modelChangesHeaders, positionGroupTableHeader, tradeBlotterHeaders, tradeTableHeaders }
