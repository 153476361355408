import { useState } from 'react'
import { AppBar, IconButton, Toolbar, Box } from '@mui/material'
import 'rc-slider/assets/index.css'
import { useSelector } from 'react-redux'
import menu from '../assets/images/menu.svg'
import '../assets/styles/topbar.scss'
import CachedIcon from '@mui/icons-material/Cached'
import PageSearch from './PageSearch'
import ProfileMenu from './ProfileMenu'
import SwitchUserTop from './SwitchUserTop'
import PolicyCheckTop from './PolicyCheckTop'
import { useAuth } from '../contexts/AuthContext'
import LegacyViewButton from '../components/LegacyViewButton'

const Topbar = ({ topMenuToggleRef }) => {
  const { user, refreshACL } = useAuth()
  const { showLegacyView } = useSelector((state) => state.trade)
  const [currentZIndex, setCurrentZIndex] = useState(1)

  return (
    <>
      <AppBar
        position='sticky'
        className='app-bar'
        sx={{
          backgroundColor: 'white',
          borderBottom: 'none',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          height: '48px',
          zIndex: currentZIndex,
          marginRight: '0px',
          width: 'auto',
          marginLeft: '240px',
          color: 'black',
          ...{
            ...showLegacyView
              ? {
                  marginLeft: '50px'
                }
              : {}
          },
          '@media screen and (max-width:600px)': {
            marginLeft: '80px'
          },

          '@media screen and (max-width:800px)': {
            marginLeft: '0px'
          },

          '@media screen and (min-width:1024px) and (max-width:1400px)': {
            marginLeft: '50px'
          },

          '@media screen and (min-width:601px) and (max-width:1024px)': {
            marginLeft: '50px'
          },
          '& .MuiToolbar-root': {
            paddingLeft: '30px',
            paddingRight: '30px',
            minHeight: '48px',
            '@media screen and (max-width:600px)': {
              paddingLeft: '20px',
              paddingRight: '20px'
            },

            '@media screen and (max-width:800px)': {
              paddingLeft: '73px',
              paddingRight: '40px'
            },

            '@media screen and (min-width:1024px) and (max-width:1400px)': {
              paddingLeft: '30px',
              paddingRight: '40px'
            },

            '@media screen and (min-width:601px) and (max-width:1024px)': {
              paddingLeft: '30px',
              paddingRight: '40px'
            }
          }
        }}
      >
        <Toolbar sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%'
        }}
        >
          <IconButton
            className='menu-toggle'
            sx={{
              display: 'none',
              '@media screen and (max-width:600px)': {
                display: 'inline-block'
              }
            }}
            ref={topMenuToggleRef}
          >
            <img src={menu} alt='' height={21} width={22} />
          </IconButton>
          {user?.userGroup !== 'admin' && <PageSearch setCurrentZIndex={setCurrentZIndex} />}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: 'flex', alignItems: 'center' } }}>
            <LegacyViewButton />
            {['pm', 'adv-classic'].includes(user?.userGroup) ? <PolicyCheckTop /> : ''}
            {/* {user?.userGroup === 'adv-classic' ? <IconButton onClick={refreshACL}><CachedIcon fontSize='8px' /></IconButton> : ''} */}
            <SwitchUserTop />
            <ProfileMenu />
            {/* {window.innerWidth > 1400
              ? (
                <>
                  {['pm', 'adv-classic'].includes(user?.userGroup) ? <PolicyCheckTop /> : ''}
                  <SwitchUserTop />
                  <ProfileMenu />
                </>
                )
              : (
                <>
                  <SwitchUserTop />
                </>
                )} */}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Topbar
