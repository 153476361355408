import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Skeleton,
  Typography
} from '@mui/material'
import sendIcon from '../../../assets/images/sendIcon.svg'
import Loader from '../../Loader'

const LoadingSkeletonViewReq = () => {
  return (
    <Box className='skel'>
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
      <Skeleton height={60} animation='wave' className='py-3' />
    </Box>
  )
}

export const ViewTradeComments = ({ rowData }) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [commentValue, setCommentValue] = useState('')
  const [commentRequests, setCommentRequests] = useState([])
  const [loading, setLoading] = useState(true)
  const [sendCommentLoading, setSendCommentLoading] = useState(false)

  const getCommentRequests = () => {
    setLoading(true)
    API.get('baseOptimizationURL', `optimization/v1/${user.userGroup}/account-comments/${rowData?.accountId}`)
      .then(response => {
        if (response?.data) {
          setCommentRequests(response.data)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (user) {
      getCommentRequests()
    }
  }, [user])

  const handleInputChange = (event) => {
    const sanitizedComment = event.target.value.replace(/(<([^>]+)>)/gi, '')
    setCommentValue(sanitizedComment)
  }

  const handlePostComment = (event) => {
    event.preventDefault()
    const trimmedComment = commentValue.trim()
    if (trimmedComment?.length > 0) {
      setSendCommentLoading(true)
      API.post(
        'baseOptimizationURL', `optimization/v1/${user.userGroup}/account-comments/${rowData?.accountId}`,
        { body: { comment: trimmedComment } }
      )
        .then((response) => {
          if (response?.data?.length > 0) {
            const newComment = response.data[0]
            setCommentRequests((prevComments) => [newComment, ...prevComments])
          }
          setCommentValue('')
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error)
        })
        .finally(() => setSendCommentLoading(false))
    } else {
      showError('Comment must contain at least one character.')
    }
  }

  if (loading) {
    return <LoadingSkeletonViewReq />
  }

  return (
    <>
      {sendCommentLoading ? <Loader /> : ''}
      <Grid container>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-start' alignItems='center'>
              <Grid item>
                <Typography variant='h6' style={{ width: '100%', fontWeight: 400, fontFamily: 'Open Sans', color: '#34475A', marginBottom: '5px' }}>
                  {rowData?.accountName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <FormLabel className='account-request-label' sx={{ fontSize: '15px !important' }}>Comments</FormLabel>
            {commentRequests?.length
              ? <Box sx={{ marginTop: '6px', marginBottom: '14px' }}>
                <Grid container spacing={2} className='account-request' sx={{ width: '100% !important', marginTop: '0px !important', marginLeft: '0px !important', maxHeight: 'calc(100vh - 16px - 16px - 32px - 8px - 20px - 58px - 8px - 21px)', height: 'auto' }}>
                  {(commentRequests || []).map((item, index) =>
                    <Grid item xs={12} key={`${index}`} sx={{ paddingLeft: '8px !important', paddingY: '4px !important', paddingRight: '8px !important' }}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <Typography className='time' sx={{ fontFamily: 'Open Sans', fontSize: '12px !important' }}>
                            {
                              item?.commenterName
                                ? (
                                  <>
                                    {item?.commenterName || ''}
                                    <span style={{ marginLeft: '3px', marginRight: '3px' }}>-</span>
                                  </>
                                  )
                                : (<></>)
                            }
                            {new Date(item?.insTs).toDateString()}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography className='reqComment' sx={{ fontFamily: 'Open Sans', fontSize: '16px !important' }}>
                        {item.comment}
                      </Typography>
                      {index !== commentRequests?.length - 1 && <Divider sx={{ background: '#CACACA', marginTop: '8px' }} />}
                    </Grid>
                  )}
                </Grid>
                </Box>
              : <Grid container spacing={2} sx={{ width: '100% !important', marginTop: '4px !important', marginLeft: '0px', border: '1px solid rgba(116, 120, 141, 0.3)', borderRadius: '10px', mb: 1, py: '4px' }}>
                <Typography sx={{ fontFamily: 'Open Sans', fontSize: '14px !important', p: 1 }}>No comments available.</Typography>
              </Grid>}
            <Box className='reqEditCommnet'>
              <FormControl fullWidth>
                <Input
                  className='form-control'
                  placeholder='Write a Comment here'
                  required
                  sx={{ paddingX: '10px', height: '50px' }}
                  value={commentValue}
                  onChange={handleInputChange}
                />
              </FormControl>
              <img
                className='sendIcon'
                src={sendIcon}
                alt=''
                onClick={handlePostComment}
                style={{ height: '50px' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
