import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import { useFormik } from 'formik'
import Papa from 'papaparse'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import AddIcon from '@mui/icons-material/Add'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SaveIcon from '@mui/icons-material/Save'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import addIcon from '../../../assets/images/icon-add-plus-circle.svg'
import downloadIcon from '../../../assets/images/icon-download.svg'
import fileIcon from '../../../assets/images/icon-file.svg'
import Success from '../../../assets/images/success.svg'
import InvalidRowEntriesPopup from '../../../components/InvalidRowEntriesPopup'
import SchwabDataPopup from '../../../components/SchwabDataPopup'
import { useAuth } from '../../../contexts/AuthContext'
import { FundingSource } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { isNumberOnly, isNumberWithSign, isWholeNumber, numberOnlyPasteHandler, numberWithSignPasteHandler } from '../../../utils/NumberUtils'
import { checkInstrumentSearchQuery } from '../../../utils/searchQueryUtils'
import { InputAdornmentTextField } from '../../AccountOnboarding/components/inputAdornmentTextfield'
import Loader from '../../Loader'
import './uma-onboarding.scss'

// Extend dayjs with the utc plugin
dayjs.extend(utc)

const UMATradingPortfolioTab = (props) => {
  const {
    submitRef,
    setTaxlotInformation,
    custodianData,
    data,
    fileIdExists,
    setUploadedPortfolioData,
    umaDetails,
    closeUploadModel,
    setPortfolioData,
    setAllStepsVisited,
    formRef
  } = props

  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [countryOptions, setCountryOptions] = useState([])
  const [isoCodeInputValue, setIsoCodeInputValue] = useState(null)
  const [successMessage, setSuccessMessage] = useState('Checking')
  const [incorrectValue, setIncorrectValue] = useState(false)
  const [isoCodeInputValueList, setIsoCodeInputValueList] = useState([])
  const [isoCodeEditIndex, setIsoCodeEditIndex] = useState([])
  const [incorrectCountryCode, setIncorrectCountryCode] = useState(false)
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false)
  const [portfolioDataFromSchwab, setPortfolioDataFromSchwab] = useState({})
  const [errorModalContent, setErrorModalContent] = useState([])
  const [validatedPortfolioData, setValidatedPortfolioData] = useState(
    ((data && data?.file && data?.validatedPortfolioData) || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.validatedPortfolioData)) ? data?.validatedPortfolioData : {}
  )
  const [isSchwabDataModelOpen, setIsSchwabDataModelOpen] = useState(false)
  const [custodianList, setCustodianList] = useState([])
  const [isSecurityOrCountryOptionsLoading, setIsSecurityOrCountryOptionsLoading] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [file, setFile] = useState(data && data?.file)
  const [fileId, setFileId] = useState(fileIdExists || '')
  const [stagedData, setStagedData] = useState(
    ((data && data?.file && data?.stagedData) || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.stagedData)) ? data?.stagedData : []
  )
  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showtax, setShowtax] = useState(!!data?.file || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.stagedData))
  const [checkClicked, setCheckClicked] = useState(false)
  const [invalidStagedData, setInvalidStagedData] = useState([])
  const [invalidCopyData, setInvalidCopyData] = useState([])
  const [showInvalid, setShowInvalid] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([15, 25, 100])
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterColumn, setFilterColumn] = useState('name')
  const [filteredData, setFilteredData] = useState([])
  const [slicedData, setSlicedData] = useState([])
  const [rows, setRows] = useState([])
  const [validStagedData, setValidStagedData] = useState([])
  const [portfolioDate, setPortfolioDate] = useState(data && data?.portfolioDate)
  const [slicedInvalidData, setSlicedInvalidData] = useState([])
  const [unsavedRowCount, setUnsavedRowCount] = useState(0) // same for valid and invalid rows
  const scrollToTopRef = useRef(null)

  const validation = Yup.object().shape({
    uploadType: Yup.string(),
    totalPortfolioMarketValue: Yup.string()
      .when('fundingSource', ([fundingSource], schema) => {
        if (fundingSource && (fundingSource === 'SECURITY' || fundingSource === 'CASH_SECURITY')) { return schema.required('Please upload the file.') }
        return schema
      }),
    custodianMarketValue: Yup.string().nullable(),
    // fundingSource: Yup.string().required('Funding source required'),
    // custodian: Yup.object()
    //   .when('accountType', (schema) => {
    //     if (umaDetails?.accountType === 'transition') { return schema.required('Custodian required') }
    //     return schema
    //   }),
    fundingSource: Yup.string()
      .when('uploadType', ([uploadType], schema) => {
        if (uploadType && uploadType === 'manual') { return schema.required('Funding source required') }
        return schema
      }),
    securityTaxLotsFile: Yup.mixed(),
    totalCashValue: Yup.string()
      .trim()
      .when('fundingSource', ([fundingSource], schema) => {
        if (fundingSource && (fundingSource === 'CASH' || fundingSource === 'CASH_SECURITY')) {
          return schema.required('Total Cash Value required')
            .test(
              'value',
              'Total Cash Value Should be greater than 0',
              (val) => fundingSource === 'CASH'
                ? parseFloat(val) !== undefined && !isNaN(val) && parseFloat(val) > 0
                : parseFloat(val) !== undefined && !isNaN(val) && parseFloat(val) !== 0
            )
        }
        return schema
      }),
    // *********************************************
    // transition account import from api fields
    custodianId: Yup.object()
      .nullable() // Allows custodian to be null
      .when('uploadType', ([uploadType], schema) => {
        if (uploadType && uploadType === 'urlupload' && umaDetails?.accountType === 'transition') {
          return schema.required('Custodian required')
        }
        return schema
      }),
    custodianNumber: Yup.string()
      .when('uploadType', ([uploadType], schema) => {
        if (uploadType && uploadType === 'urlupload' && umaDetails?.accountType === 'transition') {
          return schema
            .required('Custodian Number required')
            .min(1, 'Custodian Number cannot be less than 1 character')
        }
        return schema
      }),
    masterAccountId: Yup.string()
      .when(['uploadType', 'custodianId'], ([uploadType, custodianId], schema) => {
        if (uploadType === 'urlupload' && umaDetails?.accountType === 'transition' && custodianId?.custodianName === 'Charles Schwab') {
          return schema
            .required('Master Account ID required')
            .min(1, 'Master Account ID cannot be less than 1 character')
        }
        return schema
      })

  })

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const formateToUsdCurrencyWithTwoDecimal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const csvConfig = {
    headers: [
      {
        name: 'Symbol',
        inputName: 'Symbol',
        required: true
      },
      {
        name: 'Purchase Date',
        inputName: 'purchaseDate',
        required: true
      },
      {
        name: 'Total Purchase Cost',
        inputName: 'purchaseCost',
        required: true
      },
      {
        name: 'Quantity / Shares',
        inputName: 'Quantity / Shares',
        required: true
      },
      {
        name: 'ISO Country Code',
        inputName: 'isoCountryCode',
        required: false
      }
    ]
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const initialValues =
    data && Object.keys(data)?.length === 0
      ? {
          totalPortfolioMarketValue: '',
          custodianMarketValue: '',
          securityTaxLotsFile: '',
          totalCashValue: '',
          fundingSource: '',
          uploadType: 'manual',
          // ***********************************
          // transition account import from api fields
          custodianId: null,
          custodianNumber: '',
          masterAccountId: ''
        }
      : {
          totalPortfolioMarketValue: data?.totalPortfolioMarketValue,
          custodianMarketValue: data?.custodianMarketValue || data?.validatedPortfolioData?.schwabPortfolioMarketValue,
          securityTaxLotsFile: data?.securityTaxLotsFile,
          totalCashValue:
          data?.fundingSource === 'CASH' || data?.fundingSource === 'CASH_SECURITY'
            ? data?.totalCashValue
            : '',
          fundingSource: data?.fundingSource,
          uploadType:
          custodianData?.selectedCustodian?.custodianName === 'Charles Schwab'
            ? data?.uploadType || 'manual'
            : 'manual'
        // ***********************************
        }

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => handlePortfolioFromSubmit(values)
  })

  const handlePortfolioFromSubmit = (values) => {
    if (umaDetails?.accountType === 'trading') {
      if (!formik?.errors?.totalCashValue && !formik?.errors?.totalPortfolioMarketValue) {
        setTaxlotInformation({ ...values, stagedData, fileId, file, securityTaxLotsFile: values.securityTaxLotsFile, uploadType: formik?.values?.uploadType, portfolioDate, validatedPortfolioData })
      }
    } else {
      if (closeUploadModel && !formik?.errors?.totalCashValue && !formik?.errors?.totalPortfolioMarketValue && formik.values.uploadType === 'manual') {
        if (setPortfolioData) {
          setPortfolioData(stagedData?.map((data) => ({ ...data, clicked: false })), values)
        }
        closeUploadModel()
      }
      if (closeUploadModel && !formik?.errors?.custodianId && !formik?.errors?.custodianNumber && formik.values.uploadType === 'urlupload') {
        if (Object.keys(validatedPortfolioData)?.length && validatedPortfolioData?.portfolio && validatedPortfolioData?.portfolio?.length && stagedData && stagedData?.length) {
          setPortfolioData(stagedData, values)
          closeUploadModel()
        } else if (formik?.values?.custodianId?.custodianName === 'Charles Schwab') {
          if (!Object.keys(validatedPortfolioData)?.length && !isTotalCashDisabled) {
            fetchPortfolioFromSchwab(values)
          } else if (isTotalCashDisabled && !formik?.errors?.custodianMarketValue && !formik?.errors?.totalCashValue && !formik?.errors?.fundingSource) {
            setPortfolioData(stagedData, values)
            closeUploadModel()
          }
        } else {
          closeUploadModel()
        }
      }
    }
  }

  useEffect(() => {
    if (formRef) {
      formRef.current = formik.handleSubmit
    }
  }, [formRef, formik])

  const [isTotalCashDisabled, setIsTotalCashDisabled] = useState(
    !!(data && data?.file && formik.values.fundingSource === 'CASH_SECURITY') || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.stagedData)
  )

  const isCharlesSchwabAndUrlUpload = (custodianData?.selectedCustodian?.custodianName === 'Charles Schwab' || formik?.values?.custodianId?.custodianName === 'Charles Schwab') && formik?.values?.uploadType === 'urlupload'
  const [modalShow, setModalShow] = useState(false)
  const addRowTable = () => {
    const data = {
      id: uuidv4(),
      symbol: '',
      newRow: true,
      shares: '1',
      name: '',
      purchaseCost: '1',
      selectedDate: new Date(),
      clicked: true,
      isoCountryCode: 'US',
      schwabLotMv: null,
      schwabLastClosePrice: null,
      originalIsoCountryCode: 'US',
      hasSecurityError: false,
      purchaseDate: new Date().toISOString().split('T')[0]
    }
    const newData = [data, ...stagedData]
    setStagedData(newData)

    // Update the rows for the current page (first page)
    const newPageData = newData?.slice(0, rowsPerPage)
    setRows(newPageData)
    // Reset pagination to the first page
    setPage(0)

    // Scroll to the top of the container
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollTo(0, 0)
    }
  }

  const handleChangePage = (event, newPage) => {
    if (
      (newPage > page || newPage < page) &&
      !isDataRowValid(stagedData[page * rowsPerPage])
    ) {
      showError(
        'Please enter valid data in the current row before proceeding to the next page.'
      )
    } else {
      setPage(newPage)
    }
  }

  const isDataRowValid = (rowData) => {
    return Object?.values(rowData || {})?.every((value) => value !== undefined && value !== ''
    )
  }
  // filtered by the search endpoint, so no need to do it again.

  const onSecurityNameChange = (query, index) => {
    const indexInStagedData = page * rowsPerPage + index
    if (checkInstrumentSearchQuery(query)) {
      setIsSecurityOrCountryOptionsLoading(true)
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
        queryStringParameters: { search: query }
      })
        .then((response) => {
          if (response?.data) {
            const filteredOptions = response?.data?.filter(option =>
              option?.instrId !== '__USD' && option?.instrId !== '__CASH' &&
              option?.localSymbol !== '__USD' && option?.localSymbol !== '__CASH'
            )
            setOptions((prevOptions) => {
              const updatedSecurityOptions = [...prevOptions]
              updatedSecurityOptions[indexInStagedData] = formik.values.fundingSource === 'SECURITY' ? [...filteredOptions] : [...response?.data]
              return updatedSecurityOptions
            })
            setIsSecurityOrCountryOptionsLoading(false)
          }
        })
        .catch((error) => {
          showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
          Sentry.captureException(
            error?.response?.data?.errorInfo?.userMessage || error
          )
        }).finally(() => setIsSecurityOrCountryOptionsLoading(false))
    }
  }

  const fetchISOCountryCodeList = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/iso-country-codes`)
      .then((response) => {
        if (response?.data) {
          setCountryOptions(response?.data)
          setIsSecurityOrCountryOptionsLoading(false)
        }
      })
      .catch((error) => {
        showError(error?.response?.data?.errorInfo?.userMessage || error?.message)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  const closeModal = () => {
    setModalShow(false)
    if (document.querySelectorAll('.invalid-entry')?.length > 0) {
      showError('Please correct data')
    } else {
      setShowModal(false)
      setTimeout(() => {
        document.body.classList.remove('modal-open', 'pe-0')
      }, 200)
    }
  }

  // const onSubmitHandler = (data) => {
  //   if (!formik?.errors?.totalCashValue && !formik?.errors?.totalPortfolioMarketValue) {
  //     setTaxlotInformation({ ...data, stagedData, fileId, file, uploadType: formik?.values?.uploadType, portfolioDate })
  //   }
  // }

  const getCurrentDate = () => {
    const date = new Date()
    const day = String(date?.getDate()).padStart(2, '0')
    const month = String(date?.getMonth() + 1).padStart(2, '0')
    const year = String(date?.getFullYear())
    const currentDate = `${year}${month}${day}`
    return currentDate
  }

  const handleUpload = async () => {
    let updatedCsvData
    const fileSize = (file?.size / 1024).toFixed(2) // KB

    if (file?.type !== 'text/csv') {
      showError('Please upload csv file')
      setFile('')
    } else if (fileSize > 1024) {
      showError('Please upload file size less than 1 MB')
      setFile('')
    } else {
      // Passing file data to parse using Papa.parse
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (results) => {
          const errors = {
            headerErrors: [],
            rowErrors: []
          }
          const portfolioJsonData = []
          if (results?.data?.length) {
            const headersArray = results.data[0]
            const originalFileContent = results.data

            // find mandatory columns are present or not and store error message for missing header
            const mandatoryColumns = csvConfig.headers.filter(column => column.required)
            const validationOfColumns = mandatoryColumns.every(ele => headersArray.includes(ele.name))
            if (!validationOfColumns) {
              const headerErrors = mandatoryColumns.filter(column => !headersArray.includes(column.name)).map(column => `Header name ${column.name} is not correct or missing`)
              errors.headerErrors = [...headerErrors]
            }

            // store index of column position in file data
            const positionOfColumnInFileJson = {}
            for (const column of csvConfig.headers) {
              // ignore column without target field
              if (column?.inputName) {
                positionOfColumnInFileJson[column?.inputName] = headersArray.findIndex(name => name === column?.name)
              }
            }

            for (let rowIndex = 1; rowIndex < originalFileContent.length; rowIndex++) {
              const columns = originalFileContent[rowIndex]
              const rowJson = {}
              csvConfig?.headers?.forEach(header => {
                // ignore column without target field
                if (header?.inputName) {
                  rowJson[header?.inputName] = (positionOfColumnInFileJson && columns[positionOfColumnInFileJson[header?.inputName]]) || null
                }
              })
              // if all mandatory column are present, store row
              if (mandatoryColumns.every(header => rowJson[header?.inputName])) {
                portfolioJsonData.push({ ...rowJson })
              } else {
                errors.rowErrors = [...errors?.rowErrors, `Mandatory field ${mandatoryColumns.map(column => column?.name || '').join(' or ')} is missing in the row ${rowIndex}`]
              }
            }
            if ((errors?.rowErrors?.length > 0) || (errors?.headerErrors?.length > 0)) {
              setErrorModalContent(errors)
              setIsErrorModelOpen(true)
            } else {
              setErrorModalContent([])
              setIsErrorModelOpen(false)
              if (Array.isArray(portfolioJsonData) && portfolioJsonData?.length > 0) {
                updatedCsvData = portfolioJsonData?.map((item) => {
                  if (item?.isoCountryCode === null || item?.isoCountryCode === '') {
                    item.isoCountryCode = 'US'
                  }
                  return item
                })
                const isCashOrUSDExist = portfolioJsonData?.filter(
                  (item) => item?.Symbol === '__USD' || item?.Symbol === '__CASH'
                )
                if (formik.values.fundingSource === 'SECURITY' && isCashOrUSDExist?.length > 0) {
                  showError('Cash line not allowed for funding type security only. Please re-check csv')
                  setFile('')
                } else if (
                  formik.values.fundingSource === 'CASH_SECURITY' &&
                  isCashOrUSDExist?.length === 0 &&
                  formik.values.totalCashValue === ''
                ) {
                  showError('Cash line is mandatory. Please enter cash value or upload through CSV.')
                  setFile('')
                } else {
                  setIsFileUploading(true)

                  const cashValueFliter = formik.values.totalCashValue

                  if (formik.values.fundingSource === 'SECURITY') {
                    getSecurityDetails(updatedCsvData || portfolioJsonData)
                  } else {
                    if (cashValueFliter) {
                      if (cashValueFliter === '') {
                        getSecurityDetails(updatedCsvData || portfolioJsonData)
                      } else {
                        // const isCashOrUSDExist = portfolioJsonData?.some(
                        //   (item) => item?.Symbol === '__USD' || item?.Symbol === '__CASH'
                        // )

                        // if (isCashOrUSDExist) {
                        //   updatedCsvData = portfolioJsonData?.map((item) => {
                        //     if (item?.Symbol === '__Cash' || item?.Symbol === '__USD') {
                        //       return {
                        //         ...item,
                        //         purchaseCost: 1,
                        //         'Quantity / Shares': cashValueFliter,
                        //         isoCountryCode: 'US'
                        //       }
                        //     } else {
                        //       return item
                        //     }
                        //   })
                        // } else {
                        const formatDate = getCurrentDate()
                        updatedCsvData = [
                          {
                            Symbol: '__USD',
                            purchaseDate: formatDate,
                            purchaseCost: 1,
                            'Quantity / Shares': cashValueFliter,
                            isoCountryCode: 'US'
                          },
                          ...(updatedCsvData || portfolioJsonData)
                        ]
                        // }

                        getSecurityDetails(updatedCsvData)
                      }
                    } else {
                      setIsFileUploading(true)
                      getSecurityDetails(updatedCsvData || portfolioJsonData)
                    }
                  }
                }
              } else {
                showError('No rows available in the uploaded file. Please re-upload file with rows')
              }
            }
          }
        }
      })
    }
  }

  const handleCancel = () => {
    setFile('')
    setFileId('')
    setStagedData([])
    setShowtax(false)
    setIsTotalCashDisabled(false)
    setValidatedPortfolioData({})
    formik.setFieldValue('custodianMarketValue', '')
    formik.setFieldValue('totalPortfolioMarketValue', '')
    setUnsavedRowCount(0)
    if (setAllStepsVisited) {
      setAllStepsVisited(false)
    }
    if (setUploadedPortfolioData) {
      setUploadedPortfolioData({})
    }
  }

  const getSecurityDetails = (csvDataProcessed) => {
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/uma-import/preprocessed-data`,
      { body: [...csvDataProcessed] }
    )
      .then((response) => {
        if (response?.data) {
          const template = response?.data?.map((data, index) => {
            return { ...data, clicked: false, id: uuidv4(), hasSecurityError: false }
          })
          const invalidEntries = template?.filter((item) => item?.name === null)
          setInvalidStagedData(invalidEntries)
          setInvalidCopyData(invalidEntries.map((obj) => ({ ...obj }))) // deep copy
          const validEntries = template?.filter((item) => item?.name !== null)
          if (template && template?.length) {
            setFileId(template[0]?.fileId)
          }
          setIsFileUploading(false)
          const formattedData = validEntries?.map((e, index) => {
            return {
              id: uuidv4(),
              symbol: e?.symbol,
              purchaseDate: e?.purchaseDate,
              purchaseCost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
              shares: e?.shares,
              isoCountryCode: e?.isoCountryCode,
              originalIsoCountryCode: e?.isoCountryCode,
              hasSecurityError: e?.hasSecurityError,
              instrId: e?.instrId,
              name: e?.name,
              clicked: e?.clicked
            }
          })
          setStagedData(formattedData)
          setValidStagedData(formattedData)
          openModal()
        }
      })
      .catch((error) => {
        showError(error?.response?.data?.message)
        setFile('')
        setFileId('')
        setIsTotalCashDisabled(false)
      })
      .finally(() => {
        setIsFileUploading(false)
      })
  }

  useEffect(() => {
    if (!showInvalid) {
      setRows(validStagedData)
    } else {
      setRows(invalidStagedData)
    }
  }, [showInvalid, validStagedData])

  useEffect(() => {
    if (user) {
      fetchISOCountryCodeList()
      getCustodianType()
    }
  }, [user])

  const checkStagingData = () => {
    setCheckClicked(false)
    setIsoCodeInputValueList([])
    const emptyFields = stagedData?.filter(
      (item) =>
        item?.shares === '' ||
        item?.purchaseCost === '' ||
        item?.symbol === '' ||
        item?.name === '' ||
        item?.name === null
    )?.length
    const uniqueValues = stagedData?.filter((item) => item?.symbol === '__CASH' || item?.symbol === '__USD')
    const zeroValue = stagedData
      .slice()
      .filter(
        (item) =>
          !(item?.symbol === '__USD' || item?.symbol === '__CASH') &&
          (item?.shares <= 0 || item?.shares === '')
      )?.length
    const zeroPurchasevalue = stagedData
      .slice()
      .filter(
        (item) => item?.purchaseCost < 0 || item?.purchaseCost === ''
      )?.length

    if (emptyFields && emptyFields > 0) {
      setCheckClicked(true)
      showError(
        'Error in uploaded file. Review flagged row(s), make necessary corrections before proceeding.'
      )
    } else if (zeroValue && zeroValue > 0) {
      showError('Shares must be greater than 0')
    } else if (zeroPurchasevalue && zeroPurchasevalue > 0) {
      showError('Total Purchase Cost must be greater than 0')
    }
    // commenting unique value check for cash field
    // else if (uniqueValues?.length > 1) {
    //   const removeDuplicates = stagedData?.filter(
    //     (item) => item?.symbol !== '__USD'
    //   )
    //   showError('Please remove duplicate Cash value')
    //   setShowInvalid(true)
    //   setStagedData(removeDuplicates)
    //   setInvalidStagedData(uniqueValues)
    // }
    else {
      setModalShow(true)
      const filterCashUsd = stagedData?.map((item) => {
        if (item?.symbol === '__CASH') {
          item.symbol = '__USD'
        }
        return item
      })
      const formattedData = filterCashUsd?.map((e) => {
        return {
          symbol: e?.symbol,
          purchase_date: dayjs(e?.purchaseDate).format('YYYY-MM-DD'),
          purchase_cost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
          shares: e?.shares,
          instrId: e?.instrId,
          ...{ ...isCharlesSchwabAndUrlUpload ? { org_purchase_price: e?.orgPurchasePrice, schwabLotMv: e?.schwabLotMv, schwabLastClosePrice: e?.schwabLastClosePrice } : {} }
        }
      })
      const requestBody = {
        securities: formattedData,
        ...(isCharlesSchwabAndUrlUpload
          ? {
              schwabPortfolioMarketValue: portfolioDataFromSchwab?.schwabPortfolioMarketValue,
              schwabTotalLots: portfolioDataFromSchwab?.schwabTotalLots,
            }
          : {})
      }
      const fileIdNew = fileId
      API.post(
        'baseURL',
        `account-master/v1/${user.userGroup}/account-imports/submit/${fileIdNew}?validate-only=true&funding-source=${formik.values.fundingSource}`,
        { body: requestBody }
      )
        .then((response) => {
          if (response) {
            setSuccessMessage(response?.message)
            setValidatedPortfolioData({
              ...response?.data,
              portfolio: response?.data?.portfolio?.map(item => ({
                ...item,
                id: randomId()
              }))
            })
            formik.setFieldValue('totalPortfolioMarketValue', response?.data?.dbPortfolioMarketValue)
            if (response?.data?.schwabPortfolioMarketValue) {
              formik.setFieldValue('custodianMarketValue', response?.data?.schwabPortfolioMarketValue)
            }
            const temp = stagedData?.map((data) => {
              return { ...data, clicked: false }
            })
            setStagedData(temp)
            setShowtax(true)
            if (formik.values.fundingSource === 'CASH_SECURITY' && stagedData?.some((item) => item?.symbol === '__USD')) {
              const totalCashValue = stagedData
                .filter((item) => item?.symbol === '__USD' || item?.symbol === '__CASH')
                .reduce((sum, item) => sum + (1 * parseFloat(item?.shares)), 0)
                .toFixed(2)

              // setValue('totalCashValue', totalCashValue?.toString())
              formik.setFieldValue('totalCashValue', parseFloat(totalCashValue || '0')?.toString())
              setIsTotalCashDisabled(true)
            }
            formik.setFieldError('totalPortfolioMarketValue', '')
            formik.setFieldError('totalCashValue', '')
            setTimeout(() => {
              closeModal()
            }, 2000)
          }
        })
        .catch((error) => {
          if (error?.response?.data?.errorInfo) {
            const errorMessage = error?.response?.data?.errorInfo?.userMessage
            // setSuccessMessage('Something went Wrong')
            showError(errorMessage)
          } else {
            showError('Something went wrong')
          }
          setModalShow(false)
          // setFile('')
          // setStagedData([])
          // setIsTotalCashDisabled(false)
          Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
        })
      // .finally(() => setFile(''))
    }
  }

  const onChangeHandler = (event, value, index) => {
    const indexInStagedData = page * rowsPerPage + index
    if (showInvalid === true) {
      const updatedInvalidStagedData = [...invalidStagedData]
      if (value) {
        updatedInvalidStagedData[indexInStagedData].name = value?.name
        updatedInvalidStagedData[indexInStagedData].symbol = value?.localSymbol
        updatedInvalidStagedData[indexInStagedData].instrId = value?.instrId
        updatedInvalidStagedData[indexInStagedData].isoCountryCode = value?.isoCountryCode
        updatedInvalidStagedData[indexInStagedData].originalIsoCountryCode = value?.isoCountryCode
        setInvalidStagedData(updatedInvalidStagedData)
      }
      setIsoCodeInputValue(updatedInvalidStagedData[indexInStagedData]?.isoCountryCode)
    } else if (searchTerm?.length > 0) {
      const updatedTableRows = [...rows]
      const originalIndex = rows.findIndex((item) => item?.id === value?.id)
      if (value) {
        updatedTableRows[originalIndex].name = value?.name
        updatedTableRows[originalIndex].symbol = value?.localSymbol
        updatedTableRows[originalIndex].instrId = value?.instrId
        updatedTableRows[originalIndex].isoCountryCode = value?.isoCountryCode
        updatedTableRows[indexInStagedData].originalIsoCountryCode = value?.isoCountryCode
        setRows(updatedTableRows)
      }
      setIsoCodeInputValue(updatedTableRows[originalIndex]?.isoCountryCode)
    } else {
      const indexInStagedData = page * rowsPerPage + index
      const updatedStagedData = [...stagedData]
      if (value) {
        updatedStagedData[indexInStagedData].name = value?.name
        updatedStagedData[indexInStagedData].symbol = value?.localSymbol
        updatedStagedData[indexInStagedData].instrId = value?.instrId
        updatedStagedData[indexInStagedData].isoCountryCode = value?.isoCountryCode
        updatedStagedData[indexInStagedData].originalIsoCountryCode = value?.isoCountryCode
        setStagedData(updatedStagedData)
      }

      const updatedIsoCountryCodes = [...isoCodeInputValueList]
      updatedIsoCountryCodes[index] = value?.isoCountryCode
      setIsoCodeInputValueList(updatedIsoCountryCodes)

      setIsoCodeInputValue(updatedStagedData[indexInStagedData]?.isoCountryCode)
    }

    // handle security error based on isoCountry code related to that security
    if (stagedData && stagedData?.length && stagedData[indexInStagedData] && stagedData[indexInStagedData]?.hasSecurityError) {
      stagedData[indexInStagedData].hasSecurityError = false
      setIncorrectCountryCode(false)
    } else if (invalidStagedData && invalidStagedData?.length && invalidStagedData[indexInStagedData] && invalidStagedData[indexInStagedData]?.hasSecurityError) {
      invalidStagedData[indexInStagedData].hasSecurityError = false
      setIncorrectCountryCode(false)
    }
  }

  const onISOCodeChangeHandler = (event, value, index) => {
    if (value) {
      const indexInStagedData = page * rowsPerPage + index
      if (showInvalid === true) {
        const updatedInvalidStagedData = [...invalidStagedData]
        if (updatedInvalidStagedData[indexInStagedData]?.originalIsoCountryCode === value?.code) {
          updatedInvalidStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedInvalidStagedData[indexInStagedData].hasSecurityError = false
          setIncorrectCountryCode(false)
        } else {
          updatedInvalidStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedInvalidStagedData[indexInStagedData].hasSecurityError = true
          setIncorrectCountryCode(true)
        }
        setInvalidStagedData(updatedInvalidStagedData)
      } else {
        const updatedStagedData = [...stagedData]
        if (updatedStagedData[indexInStagedData]?.originalIsoCountryCode === value?.code) {
          updatedStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedStagedData[indexInStagedData].hasSecurityError = false
          setIncorrectCountryCode(false)
        } else {
          updatedStagedData[indexInStagedData].isoCountryCode = value?.code
          updatedStagedData[indexInStagedData].hasSecurityError = true
          setIncorrectCountryCode(true)
        }
        setStagedData(updatedStagedData)
      }
    }
  }

  function formatDate (dateString) {
    return dayjs(dateString).format('YYYY-MM-DD')
  }
  const filterInvalid = () => {
    setShowInvalid(!showInvalid)
    setOptions([])
    setPage(0) // Reset page to 0 when switching between valid and invalid entries
  }
  const closePopupDialog = () => {
    setShowInvalid(false)
    setShowModal(false)
    setPage(0)
    setSelectedRows([])
    setSearchTerm('')
    setAnchorEl(null)
    setIncorrectCountryCode(false)
    if (formik?.values?.uploadType === 'urlupload') {
      formik.setFieldValue('fundingSource', '')
    }
    handleCancel()
  }
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget)
    setFilterColumn('name')
  }
  const handleFilterSymbol = (event) => {
    setAnchorEl(event.currentTarget)
    setFilterColumn('symbol')
  }
  const handleFilterClose = () => {
    setAnchorEl(null)
  }

  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const isButtonDisabled = invalidStagedData?.length === 0

  const applyFilter = (array) => {
    if (!searchTerm) {
      setFilteredData([])
      return
    }
    const filteredData = array?.filter((item) => {
      if (filterColumn === 'name') {
        return item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      } else {
        return item?.symbol?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      }
    })
    setFilteredData(filteredData)
    if (filteredData.length < (page * rowsPerPage)) {
      setPage(Math.floor(filteredData.length / rowsPerPage))
    }
  }

  useEffect(() => {
    applyFilter(stagedData)
  }, [searchTerm, filterColumn])

  useEffect(() => {
    if (showInvalid) {
      const slicedInvalidData = invalidStagedData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
      // Update sliced data state
      setSlicedInvalidData(slicedInvalidData)
      return
    }
    if (!searchTerm) {
      const slicedData = stagedData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
      setSlicedData(slicedData)
    } else {
      const slicedData = filteredData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
      setSlicedData(slicedData)
    }
  }, [page, rowsPerPage, stagedData, invalidStagedData, filteredData, showInvalid])

  const handleFilterClear = () => {
    setSearchTerm('')
    setAnchorEl(null)
  }

  const [selectedRows, setSelectedRows] = useState([])
  if (showInvalid) {
    if (invalidStagedData?.length === 0) {
      setShowInvalid(false)
      setUnsavedRowCount(0)
    }
  }
  // Handle checkbox click
  const handleCheckboxClick = (event, index) => {
    setSelectedRows((prevSelectedRows) => {
      if (event?.target?.checked) {
        // Add index to selected rows if it's not already included
        return [...prevSelectedRows, index]
      } else {
        // Remove index from selected rows
        return prevSelectedRows?.filter((i) => i !== index)
      }
    })
  }

  // Handle delete all button click
  const handleDeleteAllClick = () => {
    let rowsCntInEditAndCheck = 0
    const filteredData = invalidStagedData?.filter((data, index) => {
      const doesInclude = selectedRows?.includes(index)
      if (data?.clicked === true && doesInclude) {
        rowsCntInEditAndCheck += 1
      }
      return !doesInclude
    })
    setUnsavedRowCount(prev => prev - rowsCntInEditAndCheck)
    setInvalidStagedData(filteredData)
    setSelectedRows([])
    if (filteredData.length <= (page * rowsPerPage)) {
      setPage(Math.max(0, Math.ceil(filteredData.length / rowsPerPage) - 1))
    }
  }

  const handleSelectAllClick = (event) => {
    const invalidData = [...invalidStagedData]
    const { checked } = event.target
    if (checked) {
      const indices = (invalidData && invalidData?.length ? invalidData : [])?.map((_, index) => index)
      setSelectedRows(indices)
    } else {
      setSelectedRows([])
    }
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'symbol', headerName: 'Symbol', flex: 1 },
    {
      field: 'shares',
      headerName: 'Shares',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.shares ? params?.row?.shares : null
      },
      renderCell: (params) => {
        return params?.value || 'N/A'
      }
    },
    {
      field: 'purchaseCost',
      headerName: 'Total Purchase Cost',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.purchaseCost !== undefined && params?.row?.purchaseCost !== null ? params?.row?.purchaseCost : null
      },
      renderCell: (params) => {
        return params?.value !== undefined && params?.value !== null ? params?.value : 'N/A'
      }
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.purchaseDate ? params?.row?.purchaseDate : null
      },
      renderCell: (params) => {
        return params?.value ? params?.value : 'N/A'
      }
    },
    { field: 'isoCountryName', headerName: 'ISO Country Code', flex: 1 }
  ]

  const rowsTable = showtax
    ? stagedData?.map((data, index) => ({
      id: index,
      name: data?.name || '',
      symbol: data?.symbol,
      shares:
        (data?.symbol === '__USD' || data?.symbol === '__CASH') ? data?.shares : (data?.shares > 0 ? data?.shares : ''),
      purchaseCost: (data?.symbol === '__CASH' || data?.symbol === '__USD')
        ? formateToUsdCurrency.format(data?.shares)
        : (data?.purchaseCost >= 0 ? formateToUsdCurrency?.format(data?.purchaseCost) : ''),
      purchaseDate: formatDate(data?.purchaseDate),
      isoCountryName: data?.isoCountryCode
    }))
    : [
        {
          id: 1,
          name: 'Apple Private Limited',
          symbol: 'APPL',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 2,
          name: 'Investo defensive',
          symbol: 'ABC',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 3,
          name: 'Equity ETF',
          symbol: 'DEF',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 4,
          name: 'Agilent',
          symbol: 'XYZ',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 5,
          name: 'Technologies Inc',
          symbol: 'XAZY',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        }
      ]

  const fetchPortfolioFromSchwab = async (data) => {
    try {
      setIsFileUploading(true)
      const queryParams = {
        masterAccountID: data?.masterAccountId,
        custodianAccountNumber: data?.custodianNumber,
        isTaxManaged: data?.taxManaged || umaDetails?.taxManaged
      }
      API.get('baseUriAggregateMaster', `aggregate-master/v1/${user?.userGroup}/schwab-portfolio/import`, { queryStringParameters: queryParams })
        .then((response) => {
          if (response?.data) {
            setPortfolioDataFromSchwab(response?.data)
            if (response?.data?.fundingSource) {
              // setValue('fundingSource', response?.data?.fundingSource)
              formik.setFieldValue('fundingSource', response?.data?.fundingSource)
            }
            if (response?.data?.portfolioDate) {
              setPortfolioDate(response?.data?.portfolioDate)
            }
            if (response?.data?.securities) {
              const template = response?.data?.securities?.map((data, index) => {
                return { ...data, clicked: false, id: uuidv4(), hasSecurityError: false }
              })
              const invalidEntries = template?.filter((item) => item?.name === null)
              setInvalidStagedData(invalidEntries)
              setInvalidCopyData(invalidEntries.map((obj) => ({ ...obj })))
              const validEntries = template?.filter((item) => item?.name !== null)
              if (template && template?.length) {
                setFileId(template[0].fileId)
              }
              setIsFileUploading(false)
              const formattedData = validEntries.map((e, index) => {
                return {
                  id: uuidv4(),
                  symbol: e?.symbol,
                  purchaseDate: e?.purchaseDate,
                  purchaseCost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
                  shares: e?.shares,
                  orgPurchasePrice: e?.orgPurchasePrice,
                  name: e?.name,
                  hasSecurityError: e?.hasSecurityError,
                  isoCountryCode: e?.isoCountryCode,
                  schwabLotMv: e?.schwabLotMv,
                  schwabLastClosePrice: e?.schwabLastClosePrice,
                  originalIsoCountryCode: e?.isoCountryCode,
                  instrId: e?.instrId,
                  clicked: e?.clicked
                }
              })
              setStagedData(formattedData)
              setValidStagedData(formattedData)
              if (response?.data?.fundingSource === 'CASH' && response?.data?.securities?.some((item) => item?.symbol === '__USD')) {
                const purchaseCost = response?.data?.securities
                  .filter((item) => item?.symbol === '__USD')
                  .map((item) => item?.shares)
                // setValue('totalCashValue', purchaseCost.toString())
                formik.setFieldValue('totalCashValue', purchaseCost.toString())
                formik.setFieldError('totalCashValue', '')
                if (response?.data?.schwabPortfolioMarketValue) {
                  formik.setFieldValue('custodianMarketValue', response?.data?.schwabPortfolioMarketValue)
                }
                setIsTotalCashDisabled(true)
              } else {
                openModal()
              }
            }
          }
        })
        .catch((error) => {
          setIsFileUploading(false)
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(
            error.response?.data?.errorInfo?.userMessage || error
          )
        })
    } catch (error) {
      showError(error.response?.data?.errorInfo?.userMessage || error.message)
      Sentry.captureException(
        error.response?.data?.errorInfo?.userMessage || error
      )
    }
  }

  // Helper function to check if a string is a valid number
  const isValidNumber = (value) => {
    // Use parseFloat to check if the value is a valid number
    const parsedValue = parseFloat(value)
    return !isNaN(parsedValue)
  }

  const checkValidStagedDataPagesEntries = (stagedData) => {
    const slicedData = stagedData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    if (slicedData?.length - 1 <= 0) {
      if (page - 1 >= 0) {
        setPage(page - 1)
      } else {
        setPage(0)
      }
    }
  }

  const checkInvalidStagedDataPagesEntries = (invalidStagedData) => {
    const slicedData = invalidStagedData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    if (slicedData?.length - 1 <= 0) {
      if (page - 1 >= 0) {
        setPage(page - 1)
      } else {
        setPage(0)
      }
    }
  }

  const getCustodianType = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/custodians`)
      .then(response => {
        if (response?.data) {
          setCustodianList(response.data)
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getBorderColor = () => {
    if (validatedPortfolioData?.mvStatus === 'red') return '#F46A6A !important'
    if (validatedPortfolioData?.mvStatus === 'yellow') return '#F1B44C !important'
    if (validatedPortfolioData?.mvStatus === 'green') return '#34C38F !important'
    return 'default'
  }

  const customBorderColor = validatedPortfolioData?.portfolio?.length > 0 && (formik?.values?.uploadType === 'urlupload' || validatedPortfolioData?.mvStatus === 'red') ? getBorderColor() : 'default'

  return (
    <Box className='portfolio-tab'>
      {isFileUploading ? <Loader /> : null}
      <form onSubmit={formik.handleSubmit} style={{ marginBottom: '16px' }}>
        <Card className='card-layout'>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {
                umaDetails?.accountType === 'trading' && (
                  <Typography variant='h6' component='div' className='account-label-header' mb={2}>
                    Portfolio
                  </Typography>
                )
              }
              {/* {
                (formik?.values?.uploadType && umaDetails?.accountType === 'transition') && (
                  <Button color='primary' variant='contained' type='submit' display='flex' justifyContent='flex-end'>
                    Submit
                  </Button>
                )
              } */}
            </Box>
            {
              umaDetails?.accountType === 'trading' && (
                <Button type='submit' ref={submitRef} style={{ display: 'none' }} />
              )
            }
            <Box>
              {(custodianData?.selectedCustodian?.custodianName === 'Charles Schwab' && umaDetails?.accountType === 'trading') ||
                umaDetails?.accountType === 'transition'
                ? <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} display='flex' alignItems='center' mb={2} justifyContent='space-between'>
                    <Box mx={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='uploadType'
                        value={formik?.values?.uploadType || 'manual'}
                        onChange={(e) => {
                          formik.handleChange(e)
                          formik.setFieldValue('uploadType', e.target.value)
                          formik.setFieldValue('fundingSource', '')
                          setFile('')
                          setFileId('')
                          setStagedData([])
                          setShowtax(false)
                          setIsTotalCashDisabled(false)
                          setValidatedPortfolioData({})
                          formik.setFieldValue('totalPortfolioMarketValue', '')
                          formik.setFieldValue('custodianMarketValue', '')
                          formik.setFieldValue('totalCashValue', '')
                          formik.setFieldValue('masterAccountId', '')
                          formik.setFieldValue('custodianNumber', '')
                          formik.setFieldValue('custodianId', null)
                          formik.setErrors({})
                          if (e.target.value === 'urlupload' && umaDetails?.accountType === 'trading'
                          ) {
                            fetchPortfolioFromSchwab(custodianData)
                          }
                        }}
                      >
                        <FormControlLabel
                          value='manual'
                          control={<Radio size='small' />}
                          label='Manually Upload Portfolio'
                        />
                        <FormControlLabel
                          value='urlupload'
                          control={<Radio size='small' />}
                          label='Import from API'
                        />
                      </RadioGroup>
                      <Box sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                        {formik?.values?.uploadType === 'manual'
                          ? <Typography
                              variant='subtitle1'
                              component='p'
                              style={{ fontFamily: 'Open Sans', fontWeight: 600, display: 'inline-flex', alignItems: 'center' }}
                              className='text-title'
                            >
                            <a href={process.env.REACT_APP_ACCOUNT_SAMPLE_URL} style={{ display: 'inline-flex', alignItems: 'center' }}>
                              <img src={downloadIcon} alt='' style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                              Sample File
                            </a>
                          </Typography>
                          : ''}
                        {formik?.values?.uploadType === 'urlupload' && stagedData?.length && portfolioDate ? <h6 style={{ margin: 0, fontSize: '16px' }}>Latest Portfolio Date: {portfolioDate}</h6> : ''}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                : ''}
              {
                formik.values.uploadType === 'urlupload' && umaDetails?.accountType === 'transition' && (
                  <>
                    <Typography sx={{ color: '#74788D', fontSize: '16px', fontWeight: 600, fontFamily: 'Open Sans' }} mb={2}>
                      Custodian Details
                    </Typography>
                    {/* // <ImportFromApiTab formik={formik} fetchPortfolioFromSchwab={fetchPortfolioFromSchwab} umaDetails={umaDetails}/> */}
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <Box>
                          <Autocomplete
                            id='custodianId'
                            options={custodianList}
                            value={formik?.values?.custodianId}
                            getOptionLabel={(option) => option?.custodianName}
                            onChange={async (event, value) => {
                              await formik?.setFieldValue('custodianId', value)
                              formik.validateField('custodianId')
                              if (value?.custodianName !== 'Charles Schwab') {
                                formik.setFieldValue('masterAccountId', '')
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option?.custodianId === value?.custodianId}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Custodian *'
                                error={formik.touched.custodianId && Boolean(formik.errors.custodianId)}
                                helperText={formik.touched.custodianId && formik.errors.custodianId}
                              />
                            )}
                            required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <TextField
                          id='custodian-number'
                          name='custodianNumber'
                          type='text'
                          label='Custodian Account Number *'
                          placeholder='Enter number here'
                          onKeyDown={(e) =>
                            formik?.values?.custodianId?.custodianName === 'Charles Schwab'
                              ? isWholeNumber(e)
                              : true}
                          onPaste={(event) => {
                            if (formik?.values?.custodianId?.custodianName === 'Charles Schwab') {
                              const inputValue = event.clipboardData.getData('text')
                              const regex = /^\d+$/
                              if (!regex.test(inputValue)) {
                                event.preventDefault()
                              }
                            }
                          }}
                          onChange={(event) => {
                            const value = event.target.value
                            formik.setFieldValue('custodianNumber', value)
                            if (value === '') {
                              formik.setFieldError('custodianNumber', 'Custodian Number required')
                            } else {
                              formik.setFieldError('custodianNumber', '')
                            }

                            formik.setFieldValue('fundingSource', '')
                            formik.setFieldValue('custodianMarketValue', '')
                            formik.setFieldValue('totalCashValue', '')
                            setIsTotalCashDisabled(false)
                            setValidatedPortfolioData({})
                          }}
                          onBlur={() => {
                            formik.setFieldTouched('custodianNumber', true)
                          }}
                          value={formik?.values?.custodianNumber}
                          error={formik.touched.custodianNumber && Boolean(formik.errors.custodianNumber)}
                          helperText={formik.touched.custodianNumber && formik.errors.custodianNumber}
                          sx={{ width: '100%' }}
                        />
                      </Grid>

                      {formik?.values?.custodianId?.custodianName === 'Charles Schwab' && (
                        <Grid item xs={12} sm={6} md={4} mb={2}>
                          <TextField
                            id='master-account-id'
                            name='masterAccountId'
                            type='text'
                            label='Master Account ID *'
                            placeholder='Enter account ID here'
                            onKeyDown={isWholeNumber}
                            onPaste={(event) => {
                              const inputValue = event.clipboardData.getData('text')
                              const regex = /^\d+$/
                              if (!regex.test(inputValue)) {
                                event.preventDefault()
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value
                              formik.setFieldValue('masterAccountId', value)
                              if (value === '') {
                                formik.setFieldError('masterAccountId', 'Master Account ID required')
                              } else {
                                formik.setFieldError('masterAccountId', '')
                              }

                              formik.setFieldValue('fundingSource', '')
                              formik.setFieldValue('custodianMarketValue', '')
                              formik.setFieldValue('totalCashValue', '')
                              setIsTotalCashDisabled(false)
                              setValidatedPortfolioData({})
                            }}
                            onBlur={() => {
                              formik.setFieldTouched('masterAccountId', true)
                            }}
                            value={formik?.values?.masterAccountId}
                            error={formik.touched.masterAccountId && Boolean(formik.errors.masterAccountId)}
                            helperText={formik.touched.masterAccountId && formik.errors.masterAccountId}
                            sx={{ width: '100%' }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </>

                )
              }
              {(formik?.values?.uploadType === 'manual' || (formik?.values?.uploadType === 'urlupload' && stagedData?.length && formik.values.fundingSource))
                ? <>
                  {umaDetails?.accountType === 'transition' && formik?.values?.uploadType === 'urlupload'
                    ? <>
                      <Divider sx={{ my: 2 }} />
                      <Typography sx={{ color: '#74788D', fontSize: '16px', fontWeight: 600, fontFamily: 'Open Sans' }} mb={1}>
                        Portfolio Details
                      </Typography>
                    </>
                    : ''}
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <TextField
                        id='funding-source'
                        name='fundingSource'
                        select
                        label='Funding Source *'
                        value={formik.values.fundingSource || ''}
                        onChange={(e) => {
                          const newValue = e.target.value
                          formik.setFieldValue('fundingSource', newValue)
                          formik.setFieldError('totalPortfolioMarketValue', '')
                          formik.setFieldError('totalCashValue', '')
                          formik.setFieldError('uploadType', '')
                          setFile('')
                          setFileId('')
                          setIsTotalCashDisabled(false)
                        }}
                        onBlur={formik.handleBlur}
                        sx={{ width: '100%', mt: '8px' }}
                        // error={formik.errors.fundingSource}
                        // helperText={formik.errors.fundingSource}
                        error={formik.touched.fundingSource && Boolean(formik.errors.fundingSource)}
                        helperText={formik.touched.fundingSource && formik.errors.fundingSource}
                        disabled={formik?.values?.uploadType === 'urlupload'}
                      >
                        <MenuItem value=''>Select</MenuItem>
                        {Object.keys(FundingSource).map((option, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              // clearErrors()
                              formik?.setErrors({})
                              setFile('')
                              setFileId('')
                              formik.setFieldValue('totalPortfolioMarketValue', '')
                              formik.setFieldValue('custodianMarketValue', '')
                              formik.setFieldValue('totalCashValue', '')
                              setIsTotalCashDisabled(false)
                            }}
                            value={option}
                            selected={formik.values.fundingSource === option}
                          >
                            {FundingSource[option]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {(formik.values.fundingSource === 'SECURITY' || formik.values.fundingSource === 'CASH_SECURITY') && (
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <InputAdornmentTextField
                          id='total-portfolio-market-value'
                          type='number'
                          label='Total Portfolio Market Value'
                          readOnly
                          disabled
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: customBorderColor
                              }
                            },
                            '& .MuiInputLabel-root': {
                              color: customBorderColor
                            }
                          }}
                          // value={Number(formik?.values?.totalPortfolioMarketValue).toFixed(2) || ''}
                          value={formik?.values?.totalPortfolioMarketValue ? formateToUsdCurrencyWithTwoDecimal.format(formik?.values?.totalPortfolioMarketValue || '0').replace('$', '') : formik?.values?.totalPortfolioMarketValue}
                          onChange={formik.handleChange}
                          aria-describedby='total-portfolio-market-value'
                          adornment={<AttachMoneyOutlinedIcon />}
                          endAdornment={
                            validatedPortfolioData?.portfolio?.length > 0
                              ? <Tooltip title={validatedPortfolioData?.reason || 'View uploaded portfolio'}>
                                <Box
                                  sx={{
                                    cursor: 'pointer',
                                    display: 'flex'
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setIsSchwabDataModelOpen(true)
                                  }}
                                >
                                  <InfoOutlinedIcon
                                    sx={{
                                      color: '#74788d'
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                              : ''
                          }
                        />
                      </Grid>
                    )}
                    {(formik?.values?.fundingSource && isCharlesSchwabAndUrlUpload) && (
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <InputAdornmentTextField
                          id='Schwab-market-value'
                          type='number'
                          label='Custodian Market Value'
                          readOnly
                          disabled
                          value={
                            formik?.values?.uploadType === 'manual'
                              ? 'N/A'
                              : formateToUsdCurrencyWithTwoDecimal.format(formik.values.custodianMarketValue || '0').replace('$', '')
                          }
                          aria-describedby='Schwab-market-value'
                          sx={{
                            width: '100%'
                          }}
                          onChange={(e) => formik.setFieldValue('custodianMarketValue', e.target.value)}
                          adornment={<AttachMoneyOutlinedIcon />}
                        />
                      </Grid>
                    )}
                    {(formik.values.fundingSource === 'CASH' || formik.values.fundingSource === 'CASH_SECURITY') && (
                      <Grid item xs={12} sm={6} md={4} mb={2}>
                        <InputAdornmentTextField
                          id='total-cash-value'
                          name='totalCashValue'
                          type='text'
                          label='Total Cash Value *'
                          // value={formik.values.totalCashValue}
                          value={isTotalCashDisabled ? Number(formik.values.totalCashValue).toLocaleString('en-US') : formik.values.totalCashValue}
                          disabled={isTotalCashDisabled}
                          onChange={(e) => {
                            const newValue = e.target.value
                            formik.setFieldValue('totalCashValue', newValue)
                            const parsedValue = parseFloat(newValue)
                            if (parsedValue === 0) {
                              formik.setFieldError('totalCashValue', 'Total Cash Value should be greater than 0')
                            } else if (newValue === '') {
                              formik.setFieldError('totalCashValue', 'Total Cash Value required')
                            } else {
                              formik.setFieldError('totalCashValue', '')
                            }
                          }}
                          sx={{
                            width: '100%'
                          }}
                          onKeyDown={(event) => {
                            if (formik.values.fundingSource === 'CASH_SECURITY' || formik.values.fundingSource === 'SECURITY') {
                              isNumberWithSign(event)
                            } else {
                              isNumberOnly(event)
                            }
                          }}
                          onPaste={(event) => {
                            if (formik.values.fundingSource === 'CASH_SECURITY' || formik.values.fundingSource === 'SECURITY') {
                              numberWithSignPasteHandler(event)
                            } else {
                              numberOnlyPasteHandler(event)
                            }
                          }}
                          adornment={<AttachMoneyOutlinedIcon />}
                          error={formik.touched.totalCashValue && Boolean(formik.errors.totalCashValue)}
                          helperText={formik.touched.totalCashValue && formik.errors.totalCashValue}
                        />
                      </Grid>
                    )}
                    {(formik.values.fundingSource === 'CASH_SECURITY' || formik.values.fundingSource === 'SECURITY') && formik?.values?.uploadType === 'manual' && (
                      <Grid item md={12} lg={8}>
                        <FormControl fullWidth sx={{ marginY: '16px' }} error={Boolean(formik?.errors.totalPortfolioMarketValue)}>
                          <Typography sx={{ color: '#74788d', fontSize: '16px', fontWeight: 600 }} mb={1}>
                            Upload Security Tax Lots
                          </Typography>
                          <Box
                            className='uma-import-portfolio'
                            onDragEnter={(e) => e.preventDefault()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                              e.preventDefault()
                              const droppedFile = e.dataTransfer.files[0]
                              formik.setFieldValue('securityTaxLotsFile', droppedFile)
                              setFile(droppedFile)
                            }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '180px',
                              border: formik?.errors.totalPortfolioMarketValue ? '0.5px dashed red !important' : '0.5px dashed gray !important'
                            }}
                          >
                            <Box>
                              <input
                                type='file'
                                disabled={formik?.values?.uploadType === 'urlupload'}
                                style={{ width: '100%', height: '85%', position: 'absolute', cursor: 'pointer', marginTop: '30px' }}
                                onChange={(e) => {
                                  setFile(e?.target?.files[0])
                                  e.target.value = ''
                                  if (data?.file) {
                                    data.file = ''
                                  }
                                }}
                                accept='.csv'
                              />
                              <Box sx={{ display: 'flex' }}>
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={addIcon}
                                  alt='add'
                                  height={30}
                                  width={30}
                                />
                                <Typography
                                  pl={1}
                                  sx={{ color: '#34475a', fontWeight: 600, cursor: 'pointer' }}
                                >
                                  Drop your files here or browse
                                </Typography>
                              </Box>
                            </Box>
                            {file && (
                              <Box className='uploaded-file' sx={{ width: '420px', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box className='btn-soft-blue' sx={{ backgroundColor: 'rgba(23, 92, 194, 0.5)', padding: '7px', color: 'white', width: '24px', height: '24px', display: 'flex', justifyContent: 'center' }} align='center' mr={1}>
                                    <img
                                      src={fileIcon}
                                      alt=''
                                      height={10}
                                      width={10}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography>Name: {file?.name}</Typography>
                                    <Typography>Size: {file?.size}</Typography>
                                    <Typography>
                                      Last Modified:{' '}
                                      {file && file?.lastModifiedDate
                                        ? file?.lastModifiedDate?.toLocaleDateString()
                                        : ''}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex' }} p={1}>
                                    <Button
                                      type='button'
                                      onClick={handleUpload}
                                      size='small'
                                      variant='contained'
                                      sx={{ fontSize: '12px !important' }}
                                      disabled={formik?.values?.uploadType === 'urlupload'}
                                    >
                                      Upload
                                    </Button>
                                    <Button
                                      type='button'
                                      onClick={handleCancel}
                                      size='small'
                                      sx={{ fontSize: '12px !important', marginLeft: '10px' }}
                                    >
                                      Cancel
                                    </Button>
                                  </Box>
                                  {isFileUploading ? <Loader /> : null}
                                </Box>
                              </Box>
                            )}
                          </Box>
                          {Boolean(formik?.errors.totalPortfolioMarketValue) && (
                            <FormHelperText error>
                              {formik?.errors.totalPortfolioMarketValue}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </>
                : ''}
            </Box>
          </CardContent>
        </Card>
      </form>
      {((formik.values.fundingSource === 'CASH_SECURITY' || formik.values.fundingSource === 'SECURITY'))
        ? <>
          {umaDetails?.accountType === 'trading' || (umaDetails?.accountType === 'transition' && stagedData?.length > 0)
            ? <Card className='card-layout' mt={3}>
              <CardContent>
                {umaDetails?.accountType === 'trading'
                  ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {(formik.values.fundingSource === 'CASH_SECURITY' || formik.values.fundingSource === 'SECURITY') && formik?.values?.uploadType === 'manual' && (
                      <Typography
                        variant='subtitle1'
                        component='p'
                        style={{ fontFamily: 'Open Sans', fontWeight: 600, display: 'inline-flex', alignItems: 'center' }}
                        className='text-title'
                      >
                        <a href={process.env.REACT_APP_ACCOUNT_SAMPLE_URL} style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <img src={downloadIcon} alt='' style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                          Sample File
                        </a>
                      </Typography>
                    )}
                    </Box>
                  : ''}
                <DataGridPro
                  density='compact'
                  autoHeight
                  columns={columns}
                  rows={rowsTable}
                  disableRowSelectionOnClick
                  pagination
                  pageSizeOptions={[15, 25, 50, 100]}
                  initialState={{
                    ...rows?.initialState,
                    pagination: { paginationModel: { pageSize: 15 } }
                  }}
                  sx={{
                    border: 'none',
                    fontFamily: 'Open Sans',
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 600,
                      fontFamily: 'Open Sans',
                      color: '#34475A'
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                      fontWeight: 600,
                      fontFamily: 'Open Sans',
                      color: '#34475A'
                    }
                  }}
                />
              </CardContent>
              </Card>
            : ''}
        </>
        : ''}
      <Dialog open={showModal} onClose={closePopupDialog} fullScreen>
        <DialogTitle>
          <IconButton
            onClick={closePopupDialog}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          {formik?.values?.uploadType === 'urlupload' && stagedData?.length && portfolioDate ? <h6 style={{ margin: 0, fontSize: '16px' }}>Latest Portfolio Date: {portfolioDate}</h6> : ''}
        </DialogTitle>
        <DialogContent ref={scrollToTopRef}>
          <TableContainer>
            <Table
              fullWidth
              stickyHeader
              sx={{
                '.MuiTableCell-root': {
                  padding: '0px 8px !important'
                }
              }}
            >
              <TableHead>
                {showInvalid
                  ? <>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectedRows?.length === invalidStagedData?.length}
                          indeterminate={selectedRows && selectedRows?.length ? selectedRows?.length !== invalidStagedData?.length : false}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell style={{ paddingLeft: '27px' }}>
                        Name
                      </TableCell>
                      <TableCell>Symbol </TableCell>
                      <TableCell align='right'>Shares </TableCell>
                      <TableCell align='right'>Total Purchase Cost</TableCell>
                      <TableCell align='right'>Purchase Date</TableCell>
                      <TableCell align='right'>ISO Country Code</TableCell>
                      <TableCell align='right'>Actions</TableCell>
                    </TableRow>
                  </>
                  : <>
                    <TableRow>
                      <TableCell style={{ paddingLeft: '27px' }}>
                        Name
                        {filterColumn === 'name' && searchTerm?.length > 0 && (
                          <FilterAltIcon
                            style={{
                              marginLeft: 5,
                              color: 'grey',
                              height: 15
                            }}
                          />
                        )}
                        <IconButton
                          aria-label='edit'
                          size='small'
                          onClick={handleFilterClick}
                        >
                          <MoreVertIcon sx={{ height: 20 }} />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleFilterClose}
                        >
                          <MenuItem sx={{ height: 40, background: 'white' }}>
                            <IconButton onClick={handleFilterClear}>
                              <CloseIcon />
                            </IconButton>
                            <Input
                              placeholder={`Filter by ${filterColumn}`}
                              value={searchTerm}
                              onChange={handleFilterChange}
                            />
                          </MenuItem>
                        </Menu>
                      </TableCell>
                      <TableCell>
                        Symbol{' '}
                        {filterColumn === 'symbol' &&
                          searchTerm?.length > 0 && (
                            <FilterAltIcon
                              style={{
                                marginLeft: 5,
                                color: 'grey',
                                height: 15
                              }}
                            />
                        )}
                        <IconButton
                          aria-label='edit'
                          size='small'
                          onClick={handleFilterSymbol}
                        >
                          <MoreVertIcon sx={{ height: 20 }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align='right'>
                        Shares{' '}
                      </TableCell>
                      <TableCell align='right'>Total Purchase Cost</TableCell>
                      <TableCell align='right'>Purchase Date</TableCell>
                      <TableCell align='right'>ISO Country Code</TableCell>
                      <TableCell align='right'>Actions</TableCell>
                    </TableRow>
                  </>}
              </TableHead>
              <TableBody>
                {showInvalid
                  ? <>
                    {slicedInvalidData?.map((data, index) => {
                      const indexInStagedData = page * rowsPerPage + index
                      return (
                        <TableRow
                          key={index}
                          className={
                            (data?.name === null || data?.purchaseCost < 0) &&
                              !(data?.symbol === '__USD' && data?.shares < 0)
                              ? 'invalid-entry'
                              : ''
                          }
                          onDoubleClick={() => {
                            const updatedInvalidStagedData = [...invalidStagedData]
                            const originalIndex = invalidStagedData?.findIndex(
                              (item) => item?.id === data?.id
                            )
                            if (updatedInvalidStagedData[originalIndex] && !updatedInvalidStagedData[originalIndex]?.clicked) {
                              updatedInvalidStagedData[originalIndex].clicked = true
                              setUnsavedRowCount(prev => prev + 1)
                            }
                            setInvalidStagedData(updatedInvalidStagedData)
                          }}
                        >

                          <TableCell>
                            <Checkbox
                              checked={selectedRows?.includes(index)}
                              onChange={(event) =>
                                handleCheckboxClick(event, index)}
                            />
                          </TableCell>
                          <TableCell>
                            {data?.clicked
                              ? <Autocomplete
                                // filterOptions={filterBy} // Custom filter function, if needed
                                  loading={isSecurityOrCountryOptionsLoading}
                                  size='small'
                                  sx={{ padding: '8px 8px 8px 0px', minWidth: '350px' }}
                                  value={data?.name}
                                  options={options[indexInStagedData] || []}
                                  getOptionLabel={(option) => option?.name || data?.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='Security Name'
                                      error={data?.name && invalidStagedData[indexInStagedData]?.hasSecurityError}
                                      helperText={
                                      data?.name && invalidStagedData[indexInStagedData]?.hasSecurityError
                                        ? 'Security does not exist in selected country code'
                                        : ''
                                    }
                                      placeholder={data?.name}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {/* {isLoading ? 'loading...' : null} */}
                                            {params.InputProps.endAdornment}
                                          </>
                                        )
                                      }}
                                    />
                                  )}
                                  onInputChange={(event, value) => {
                                    onSecurityNameChange(value, index)
                                  }}
                                  onChange={(event, value) => {
                                    onChangeHandler(event, value, index)
                                  }}
                                />
                              : data?.name
                                ? data?.name
                                : ''}
                            {!data?.name && invalidStagedData[indexInStagedData]?.clicked
                              ? <FormHelperText sx={{ margin: '0px 5px 5px 14px' }} error> Name Required</FormHelperText>
                              : <></>}
                            {data?.name === '' && invalidStagedData[indexInStagedData]?.clicked
                              ? <FormHelperText sx={{ margin: '0px 5px 5px 14px' }} error> Name Required</FormHelperText>
                              : <></>}
                          </TableCell>
                          <TableCell>
                            {data?.symbol === '__USD'
                              ? '__CASH'
                              : data?.symbol}
                          </TableCell>
                          <TableCell align='right'>
                            {data?.clicked
                              ? <TextField
                                  type='text'
                                  value={data?.shares}
                                  size='small'
                                  sx={{ padding: '8px 8px 8px 0px' }}
                                  onKeyDown={(event) => {
                                    if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                      isNumberWithSign(event)
                                    } else {
                                      isNumberOnly(event)
                                    }
                                  }}
                                  onPaste={(event) => {
                                    if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                      numberWithSignPasteHandler(event)
                                    } else {
                                      numberOnlyPasteHandler(event)
                                    }
                                  }}
                                  onChange={(e) => {
                                    const updatedInvalidStagedData = [...invalidStagedData]
                                    const originalIndex = invalidStagedData?.findIndex(
                                      (item) => item?.id === data?.id
                                    )
                                    updatedInvalidStagedData[originalIndex].shares = e?.target?.value
                                    if (
                                      ((data?.symbol === '__USD' || data?.symbol === '__CASH') &&
                                      (updatedInvalidStagedData[index]?.purchaseCost === '' ||
                                        (updatedInvalidStagedData[index]?.purchaseCost && updatedInvalidStagedData[index]?.purchaseCost < 0) ||
                                        (updatedInvalidStagedData[index]?.shares === '' || (updatedInvalidStagedData[index]?.shares && updatedInvalidStagedData[index]?.shares < 0)))) ||
                                    (
                                      !(updatedInvalidStagedData[index]?.purchaseCost === '' ||
                                        (updatedInvalidStagedData[index]?.purchaseCost && updatedInvalidStagedData[index]?.purchaseCost < 0) ||
                                        (updatedInvalidStagedData[index]?.shares === '' || (updatedInvalidStagedData[index]?.shares && updatedInvalidStagedData[index]?.shares <= 0))))
                                    ) {
                                      setIncorrectValue(false)
                                    } else {
                                      setIncorrectValue(true)
                                    }
                                    setIncorrectValue(false)
                                    setInvalidStagedData(updatedInvalidStagedData)
                                  }}
                                />

                              : data?.shares
                                ? data?.shares
                                : ''}
                            {
                              data?.shares === '' || ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares === '0')
                                ? (
                                  <FormHelperText error type='invalid'>
                                    {data?.shares === '' ? 'Shares Required' : 'Shares cannot be 0'}
                                  </FormHelperText>
                                  )
                                : <></>
                            }
                            {data?.shares && data?.shares <= 0 && !(data?.symbol === '__USD' || data?.symbol === '__CASH')
                              ? <FormHelperText error>
                                Shares should be greater than 0
                              </FormHelperText>
                              : <></>}
                          </TableCell>
                          <TableCell align='right'>
                            {(data?.symbol === '__USD' || data?.symbol === '__CASH')
                              ? (!data?.clicked || (data?.symbol === '__USD' || data?.symbol === '__CASH'))
                                  ? (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                      ? formateToUsdCurrency.format(data?.shares !== '-' ? data?.shares : 0)
                                      : ''
                                  : <>
                                    {data?.purchaseCost}
                                    </>
                              : data?.clicked
                                ? <TextField
                                    type='text'
                                    size='small'
                                    sx={{ padding: '8px 8px 8px 0px' }}
                                    value={data?.purchaseCost}
                                    onKeyDown={(event) => isNumberOnly(event)}
                                    onPaste={numberOnlyPasteHandler}
                                    onChange={(e) => {
                                      const updatedInvalidStagedData = [...invalidStagedData]
                                      const originalIndex = invalidStagedData?.findIndex(
                                        (item) => item?.id === data?.id
                                      )
                                      updatedInvalidStagedData[originalIndex].purchaseCost = e?.target.value
                                      if (updatedInvalidStagedData[originalIndex]?.purchaseCost === '' || (updatedInvalidStagedData[originalIndex]?.purchaseCost && updatedInvalidStagedData[originalIndex]?.purchaseCost < 0) || (updatedInvalidStagedData[originalIndex].shares === '' || (updatedInvalidStagedData[originalIndex].shares && updatedInvalidStagedData[originalIndex].shares <= 0))) {
                                        setIncorrectValue(true)
                                        updatedInvalidStagedData[originalIndex].hasOtherFieldsError = true
                                      } else {
                                        setIncorrectValue(false)
                                        updatedInvalidStagedData[originalIndex].hasOtherFieldsError = false
                                      }
                                      setInvalidStagedData(updatedInvalidStagedData)
                                    }}
                                  />

                                : (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                    ? formateToUsdCurrency.format(data?.purchaseCost)
                                    : ''}
                            {data?.purchaseCost === ''
                              ? <FormHelperText error>
                                Purchase Cost Required
                              </FormHelperText>
                              : <></>}
                            {(data?.purchaseCost !== undefined &&
                              data?.purchaseCost !== null &&
                              data?.purchaseCost !== '') &&
                              data?.purchaseCost < 0
                              ? <FormHelperText error>
                                Purchase Cost should not be less than 0
                              </FormHelperText>
                              : <></>}
                          </TableCell>

                          <TableCell align='right'>
                            {data?.newRow
                              ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={dayjs(data?.selectedDate)}
                                  slotProps={{ textField: { size: 'small' } }}
                                  sx={{ padding: '8px 0px 8px 8px' }}
                                  onChange={(date) => {
                                    const updatedInvalidStagedData = [...invalidStagedData]
                                    updatedInvalidStagedData[indexInStagedData].selectedDate = dayjs(date)
                                    updatedInvalidStagedData[indexInStagedData].purchaseDate =
                                      dayjs(date)
                                    setInvalidStagedData(updatedInvalidStagedData)
                                  }}
                                  format='YYYY-MM-DD'
                                  maxDate={dayjs()}
                                />
                              </LocalizationProvider>
                              : data?.clicked
                                ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    value={dayjs(data?.purchaseDate)}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ padding: '8px 0px 8px 8px' }}
                                    onChange={(date) => {
                                      const updatedInvalidStagedData = [...invalidStagedData]
                                      updatedInvalidStagedData[indexInStagedData].selectedDate = dayjs(date)
                                      updatedInvalidStagedData[indexInStagedData].purchaseDate =
                                        dayjs(date)
                                      setInvalidStagedData(updatedInvalidStagedData)
                                    }}
                                    format='YYYY-MM-DD'
                                    maxDate={dayjs()}
                                  />
                                </LocalizationProvider>
                                : formatDate(data?.purchaseDate)}
                          </TableCell>
                          <TableCell style={{ width: '250px' }} align='right'>
                            {data?.clicked && formik?.values?.uploadType !== 'urlupload'
                              ? (
                                <Autocomplete
                                  loading={isSecurityOrCountryOptionsLoading}
                                  size='small'
                                  sx={{ padding: '8px 8px 8px 0px' }}
                                  isOptionEqualToValue={(option, value) => {
                                    return option?.code === value
                                  }}
                                  options={countryOptions || []}
                                  getOptionDisabled={(option) =>
                                    option.code === invalidStagedData[indexInStagedData]?.isoCountryCode}
                                  getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.name}
                                  value={invalidStagedData[indexInStagedData]?.isoCountryCode === isoCodeInputValue ? isoCodeInputValue : invalidStagedData[indexInStagedData]?.isoCountryCode}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='Country Code'
                                      placeholder={data?.code}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {params.InputProps.endAdornment}
                                          </>
                                        )
                                      }}
                                    />
                                  )}
                                  onChange={(event, value) => {
                                    onISOCodeChangeHandler(event, value, index)
                                  }}
                                />
                                )
                              : formik?.values?.uploadType !== 'urlupload'
                                ? data?.isoCountryCode
                                : data?.isoCountryCode === null
                                  ? 'US'
                                  : data?.isoCountryCode
                                    ? data?.isoCountryCode
                                    : ''}
                          </TableCell>
                          <TableCell align='right'>
                            <Box display='flex' alignItems='center' justifyContent='flex-end'>
                              {data?.clicked
                                ? <IconButton
                                    aria-label='save'
                                    size='medium'
                                    disabled={
                                    stagedData[index]?.hasCodeError ||
                                    incorrectValue ||
                                    ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '' || data?.shares === '0')) ||
                                    ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0)) || !data?.name
                                  }
                                    onClick={() => {
                                      if (
                                        data?.purchaseDate &&
                                      data?.name &&
                                      (data?.purchaseCost !== undefined &&
                                        data?.purchaseCost !== null &&
                                        data?.purchaseCost !== '') &&
                                      data?.symbol &&
                                      data?.purchaseDate && data?.purchaseCost >= 0 &&
                                      ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares !== undefined) || // For data?.symbol === '__USD' or '__CASH' (Allow both positive and negative values)
                                      (data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (data?.shares === undefined || data?.shares > 0) && // For data?.symbol !== '__USD' and '__CASH'
                                      data?.isoCountryCode
                                      ) {
                                        const updatedInvalidStagedData = [...invalidStagedData]
                                        const updatedStagedData = [...stagedData]
                                        updatedInvalidStagedData[indexInStagedData].clicked = false
                                        updatedStagedData?.unshift(updatedInvalidStagedData[indexInStagedData])
                                        setStagedData(updatedStagedData)
                                        setValidStagedData([{ ...data, clicked: false }, ...validStagedData])
                                        updatedInvalidStagedData?.splice(indexInStagedData, 1)
                                        setInvalidStagedData(updatedInvalidStagedData)
                                        setInvalidCopyData(invalidCopyData.filter((_, i) => i !== indexInStagedData))
                                        // go to previous if current page have no data
                                        checkInvalidStagedDataPagesEntries(updatedInvalidStagedData)
                                        setUnsavedRowCount(prev => prev - 1)
                                      } else {
                                        showError('Please check for errors and enter valid values')
                                      }
                                    }}
                                  >
                                  <SaveIcon fontSize='inherit' />
                                </IconButton>
                                : <IconButton
                                    aria-label='edit'
                                    size='medium'
                                    onClick={() => {
                                      const updatedInvalidStagedData = [...invalidStagedData]
                                      const originalIndex = invalidStagedData?.findIndex(
                                        (item) => item?.id === data?.id
                                      )
                                      if (updatedInvalidStagedData[originalIndex]) {
                                        updatedInvalidStagedData[originalIndex].clicked = true
                                        setIsoCodeInputValue(updatedInvalidStagedData[originalIndex]?.isoCountryCode)
                                        const updatedIsoCountryCodes = [...isoCodeEditIndex]
                                        updatedIsoCountryCodes[originalIndex] = updatedInvalidStagedData[originalIndex]?.isoCountryCode
                                        setIsoCodeEditIndex(updatedIsoCountryCodes)
                                        setInvalidStagedData(updatedInvalidStagedData)
                                        setUnsavedRowCount(prev => prev + 1)
                                      }
                                    }}
                                  >
                                  <EditIcon />
                                </IconButton>}
                              {
                                  data?.clicked ? (
                                    <IconButton
                                      aria-label='close'
                                      size='medium'
                                      onClick={() => {
                                        const updatedInvalidStagedData = [...invalidStagedData]
                                        updatedInvalidStagedData.splice(indexInStagedData, 1, { ...invalidCopyData[indexInStagedData], clicked: false })
                                        setInvalidStagedData(updatedInvalidStagedData)
                                        setIncorrectValue(false)
                                        setIncorrectCountryCode(false)
                                        setUnsavedRowCount(prev => prev - 1)
                                      }}
                                    >
                                      <CloseIcon fontSize='inherit' />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      aria-label='delete'
                                      size='medium'
                                      onClick={() => {
                                        const updatedInvalidStagedData = [...invalidStagedData]
                                        updatedInvalidStagedData?.splice(indexInStagedData, 1)
                                        setIncorrectValue(false)
                                        setIncorrectCountryCode(false)
                                        setInvalidStagedData(updatedInvalidStagedData)
                                        setSelectedRows((prevSelectedRows) =>
                                          prevSelectedRows
                                            ?.filter((i) => i !== indexInStagedData) // Remove the deleted index
                                            ?.map((i) => (i > indexInStagedData ? i - 1 : i)) // Adjust the remaining indexes
                                        )
                                        setInvalidCopyData(invalidCopyData.filter((_, i) => i !== indexInStagedData))
                                        checkInvalidStagedDataPagesEntries(updatedInvalidStagedData)
                                      }}
                                    >
                                      <DeleteIcon fontSize='inherit' />
                                    </IconButton>
                                  )
                                }
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    }
                    )}
                  </>
                  : <>
                    {slicedData?.length > 0
                      ? <>
                        {slicedData?.map((data, index) => {
                          const indexInStagedData = page * rowsPerPage + index
                          return (
                            <TableRow
                              key={index}
                              className={
                                (data?.name === null || data?.purchaseCost < 0) &&
                                  !(data?.symbol === ('__USD' || '__CASH') && data?.shares < 0)
                                  ? 'invalid-entry'
                                  : ''
                              }
                              onDoubleClick={() => {
                                const updatedStagedData = [...stagedData]
                                const originalIndex = stagedData?.findIndex(
                                  (item) => item?.id === data?.id
                                )
                                if (updatedStagedData[originalIndex] && updatedStagedData[originalIndex]?.clicked) {
                                  updatedStagedData[originalIndex].clicked = true
                                  setUnsavedRowCount(prev => prev + 1)
                                }
                                setIsoCodeInputValue(updatedStagedData[originalIndex]?.isoCountryCode)
                                setStagedData(updatedStagedData)
                              }}
                            >
                              <TableCell
                                style={{ width: '500px' }}
                              >
                                {data?.clicked
                                  ? <Autocomplete
                                      loading={isSecurityOrCountryOptionsLoading}
                                      value={data?.name}
                                      size='small'
                                      sx={{ padding: '8px 8px 8px 0px' }}
                                      options={options[indexInStagedData] || []}
                                      getOptionLabel={(option) => option?.name || data?.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Security Name'
                                          error={data?.name && stagedData[indexInStagedData]?.hasSecurityError}
                                          helperText={
                                          data?.name && stagedData[indexInStagedData]?.hasSecurityError
                                            ? 'Security does not exist in selected country code'
                                            : ''
                                        }
                                          placeholder={data?.name}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {params?.InputProps?.endAdornment}
                                              </>
                                            )
                                          }}
                                        />
                                      )}
                                      onInputChange={(event, value) => {
                                        onSecurityNameChange(value, index)
                                      }}
                                      onChange={(event, value) => {
                                        onChangeHandler(event, value, index)
                                      }}
                                    />
                                  : data?.name
                                    ? data?.name
                                    : ''}
                                {!data?.newRow && data?.name === ''
                                  ? <FormHelperText sx={{ margin: '0px 5px 5px 14px' }} error>
                                    Name Required
                                  </FormHelperText>
                                  : <></>}
                                {data?.newRow && data?.name === ''
                                  ? <FormHelperText sx={{ margin: '0px 5px 5px 14px' }} error>
                                    Name Required
                                  </FormHelperText>
                                  : <></>}
                              </TableCell>
                              <TableCell>
                                {data?.symbol === '__USD' ? '__CASH' : data?.symbol}
                              </TableCell>
                              <TableCell align='right'>
                                {data?.clicked
                                  ? <TextField
                                      type='text'
                                      value={data?.shares}
                                      size='small'
                                      sx={{ padding: '8px 8px 8px 0px' }}
                                      onKeyDown={(event) => {
                                        if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                          isNumberWithSign(event)
                                        } else {
                                          isNumberOnly(event)
                                        }
                                      }}
                                      onPaste={(event) => {
                                        if (data?.symbol === '__USD' || data?.symbol === '__CASH') {
                                          numberWithSignPasteHandler(event)
                                        } else {
                                          numberOnlyPasteHandler(event)
                                        }
                                      }}
                                      onChange={(e) => {
                                        const updatedStagedData = [...stagedData]
                                        const originalIndex = stagedData?.findIndex((item) => item?.id === data?.id)
                                        updatedStagedData[originalIndex].shares = e?.target.value
                                        if (
                                          ((data?.symbol === '__USD' || data?.symbol === '__CASH') &&
                                          (updatedStagedData[index]?.purchaseCost === '' ||
                                            (updatedStagedData[index]?.purchaseCost && updatedStagedData[index]?.purchaseCost < 0) ||
                                            (updatedStagedData[index]?.shares === '' || (updatedStagedData[index]?.shares && updatedStagedData[index]?.shares < 0)))) ||
                                        (
                                          !(updatedStagedData[index]?.purchaseCost === '' ||
                                            (updatedStagedData[index]?.purchaseCost && updatedStagedData[index]?.purchaseCost < 0) ||
                                            (updatedStagedData[index]?.shares === '' || (updatedStagedData[index]?.shares && updatedStagedData[index]?.shares <= 0))))
                                        ) {
                                          setIncorrectValue(false)
                                          updatedStagedData[index].hasOtherFieldsError = false
                                        } else {
                                          setIncorrectValue(true)
                                          updatedStagedData[index].hasOtherFieldsError = true
                                        }
                                        setIncorrectValue(false)
                                        setStagedData(updatedStagedData)
                                      }}
                                    />
                                  : data?.shares
                                    ? data?.shares
                                    : ''}
                                {
                                  data?.shares === '' || ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares === '0')
                                    ? (
                                      <FormHelperText error type='invalid'>
                                        {data?.shares === '' ? 'Shares Required' : 'Shares cannot be 0'}
                                      </FormHelperText>
                                      )
                                    : <></>
                                }
                                {data?.shares && data?.shares <= 0 && !(data?.symbol === '__USD' || data?.symbol === '__CASH')
                                  ? <FormHelperText error>
                                    Shares should be greater than 0
                                  </FormHelperText>
                                  : <></>}
                              </TableCell>
                              <TableCell align='right'>
                                {(data?.symbol === '__USD' || data?.symbol === '__CASH')
                                  ? (!data?.clicked || (data?.symbol === '__USD' || data?.symbol === '__CASH'))
                                      ? (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                          ? formateToUsdCurrency?.format(data?.shares !== '-' ? data?.shares : 0)
                                          : ''
                                      : <>
                                        {data?.purchaseCost}
                                        </>
                                  : data?.clicked
                                    ? <TextField
                                        type='text'
                                        value={data?.purchaseCost}
                                        size='small'
                                        sx={{ padding: '8px 8px 8px 0px' }}
                                        onKeyDown={(event) => isNumberOnly(event)}
                                        onPaste={numberOnlyPasteHandler}
                                        onChange={(e) => {
                                          const updatedStagedData = [...stagedData]
                                          const originalIndex = stagedData?.findIndex(
                                            (item) => item?.id === data?.id
                                          )
                                          updatedStagedData[originalIndex].purchaseCost =
                                          e?.target.value
                                          if (updatedStagedData[originalIndex]?.purchaseCost === '' || (updatedStagedData[originalIndex]?.purchaseCost && updatedStagedData[originalIndex]?.purchaseCost < 0) || (updatedStagedData[originalIndex]?.shares === '' || (updatedStagedData[originalIndex]?.shares && updatedStagedData[originalIndex]?.shares <= 0))) {
                                            setIncorrectValue(true)
                                            updatedStagedData[originalIndex].hasOtherFieldsError = true
                                          } else {
                                            setIncorrectValue(false)
                                            updatedStagedData[originalIndex].hasOtherFieldsError = false
                                          }
                                          setStagedData(updatedStagedData)
                                        }}
                                      />
                                    : (data?.purchaseCost !== undefined && data?.purchaseCost !== null && data?.purchaseCost !== '')
                                        ? formateToUsdCurrency.format(data?.purchaseCost)
                                        : ''}
                                {data?.purchaseCost === ''
                                  ? <FormHelperText error>
                                    Purchase Cost Required
                                  </FormHelperText>
                                  : <></>}
                                {(data?.purchaseCost !== undefined &&
                                  data?.purchaseCost !== null &&
                                  data?.purchaseCost !== '') &&
                                  data?.purchaseCost < 0
                                  ? <FormHelperText error>
                                    Purchase Cost should not be less than 0
                                  </FormHelperText>
                                  : <></>}
                              </TableCell>
                              <TableCell align='right'>
                                {data?.clicked

                                  ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      slotProps={{ textField: { size: 'small' } }}
                                      sx={{ padding: '8px 0px 8px 8px' }}
                                      value={dayjs(data?.purchaseDate)}
                                      onChange={(date) => {
                                        const updatedStagedData = [...stagedData]
                                        updatedStagedData[indexInStagedData].selectedDate =
                                          dayjs(date)
                                        updatedStagedData[indexInStagedData].purchaseDate =
                                          dayjs(date)
                                        setStagedData(updatedStagedData)
                                      }}
                                      format='YYYY-MM-DD'
                                      maxDate={dayjs()}
                                    />
                                  </LocalizationProvider>
                                  : formatDate(data?.purchaseDate)}
                              </TableCell>
                              <TableCell style={{ width: '250px' }} align='right'>
                                {data?.clicked && formik?.values?.uploadType !== 'urlupload'
                                  ? (
                                    <Autocomplete
                                      loading={isSecurityOrCountryOptionsLoading}
                                      size='small'
                                      sx={{ padding: '8px 8px 8px 0px' }}
                                      isOptionEqualToValue={(option, value) => {
                                        return option?.code === value
                                      }}
                                      options={countryOptions || []}
                                      getOptionDisabled={(option) =>
                                        option.code === stagedData[indexInStagedData]?.isoCountryCode}
                                      getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option : option?.name}
                                      value={stagedData[indexInStagedData]?.isoCountryCode === isoCodeInputValue ? isoCodeInputValue : stagedData[indexInStagedData]?.isoCountryCode}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Country Code'
                                          placeholder={data?.code}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {params.InputProps.endAdornment}
                                              </>
                                            )
                                          }}
                                        />
                                      )}
                                      onChange={(event, value) => {
                                        onISOCodeChangeHandler(event, value, index)
                                      }}
                                    />
                                    )
                                  : formik?.values?.uploadType !== 'urlupload'
                                    ? data?.isoCountryCode
                                    : data?.isoCountryCode === null
                                      ? 'US'
                                      : data?.isoCountryCode
                                        ? data?.isoCountryCode
                                        : ''}
                              </TableCell>
                              <TableCell align='right'>
                                <Box display='flex' alignItems='center' justifyContent='flex-end'>
                                  {data?.clicked
                                    ? <IconButton
                                        aria-label='save'
                                        size='medium'
                                        disabled={
                                        ((stagedData[index]?.hasSecurityError || stagedData[index]?.hasOtherFieldsError || incorrectValue)
                                        ) ||
                                        ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '' || data?.shares === '0')) ||
                                        ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0)) || !data?.name
                                      }
                                        onClick={() => {
                                          if (
                                            data?.purchaseDate &&
                                          data?.name &&
                                          (data?.purchaseCost !== undefined &&
                                            data?.purchaseCost !== null &&
                                            data?.purchaseCost !== '') &&
                                          data?.symbol &&
                                          data?.purchaseDate && data?.purchaseCost >= 0 &&
                                          ((data?.symbol === '__USD' || data?.symbol === '__CASH') && data?.shares !== undefined) ||
                                          (data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (data?.shares === undefined || data?.shares > 0) &&
                                          data?.isoCountryCode
                                          ) {
                                            const updatedStagedData = [...stagedData]
                                            const originalIndex = stagedData?.findIndex((item) => item?.id === data?.id)
                                            updatedStagedData[originalIndex].clicked = false
                                            if (updatedStagedData[originalIndex].newRow) { updatedStagedData[originalIndex].newRow = false }
                                            setStagedData(updatedStagedData)
                                            setValidStagedData(validStagedData.map((obj, i) => (
                                              i === originalIndex
                                                ? {
                                                    ...data,
                                                    clicked: false,
                                                    ...(obj?.newRow ? { newRow: false } : null)
                                                  }
                                                : obj
                                            )))
                                            applyFilter(updatedStagedData)
                                            // go to previous if current page have no data
                                            checkValidStagedDataPagesEntries(updatedStagedData)
                                            setUnsavedRowCount(prev => prev - 1)
                                          } else {
                                            showError('Please check for errors and enter valid values')
                                          }
                                        }}
                                      >
                                      <SaveIcon fontSize='inherit' />
                                    </IconButton>
                                    : <IconButton
                                        aria-label='edit'
                                        size='medium'
                                        onClick={() => {
                                          const updatedStagedData = [...stagedData]
                                          const originalIndex = stagedData?.findIndex(
                                            (item) => item?.id === data?.id
                                          )
                                          if (updatedStagedData[originalIndex]) {
                                            updatedStagedData[originalIndex].clicked = true
                                            setIsoCodeInputValue(updatedStagedData[originalIndex]?.isoCountryCode)

                                            const updatedIsoCountryCodes = [...isoCodeEditIndex]
                                            updatedIsoCountryCodes[originalIndex] = updatedStagedData[originalIndex]?.isoCountryCode
                                            setIsoCodeEditIndex(updatedIsoCountryCodes)

                                            setStagedData(updatedStagedData)
                                            setUnsavedRowCount(prev => prev + 1)
                                          }
                                        }}
                                      >
                                      <EditIcon />
                                    </IconButton>}
                                  {
                                      data?.clicked ? (
                                        <IconButton
                                          aria-label='close'
                                          size='medium'
                                          onClick={() => {
                                            const originalIndex = stagedData?.findIndex(
                                              (item) => item?.id === data?.id
                                            )
                                            if (data?.newRow) {
                                              // incorrect state updation, proceed with care
                                              const updatedStagedData = [...stagedData]
                                              updatedStagedData.splice(originalIndex, 1)
                                              setStagedData(updatedStagedData)
                                              setValidStagedData(validStagedData.filter((_, i) => i !== originalIndex))
                                            } else {
                                              const updatedStagedData = [...stagedData]
                                              updatedStagedData.splice(originalIndex, 1, { ...validStagedData[originalIndex], clicked: false })
                                              applyFilter(updatedStagedData)
                                              setStagedData(updatedStagedData)
                                            }
                                            setIncorrectValue(false)
                                            setIncorrectCountryCode(false)
                                            setUnsavedRowCount(prev => prev - 1)
                                          }}
                                        >
                                          <CloseIcon fontSize='inherit' />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          aria-label='delete'
                                          size='medium'
                                          onClick={() => {
                                            const updatedStagedData = [...stagedData]
                                            const originalIndex = stagedData.findIndex(obj => obj?.id === data?.id)
                                            updatedStagedData.splice(originalIndex, 1)
                                            setStagedData(updatedStagedData)
                                            setValidStagedData(validStagedData.filter((_, i) => i !== originalIndex))
                                            setIncorrectValue(false)
                                            applyFilter(updatedStagedData)
                                            setIncorrectCountryCode(false)
                                            checkValidStagedDataPagesEntries(updatedStagedData)
                                          }}
                                        >
                                          <DeleteIcon fontSize='inherit' />
                                        </IconButton>
                                      )
                                    }
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </>
                      : <TableRow><TableCell colSpan={7} sx={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Open Sans', textAlign: 'center', padding: '16px' }}>No Valid Entries</TableCell></TableRow>}
                  </>}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Box>
            {searchTerm?.length > 0 || showInvalid
              ? ''
              : <>
                <Button
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => addRowTable()}
                  className='PortfolioEndBtn'
                >
                  Add
                </Button>
                <Button
                  style={{
                    backgroundColor: isButtonDisabled || incorrectValue || incorrectCountryCode ||
                      stagedData?.some((data) => data?.hasSecurityError || data?.name === '' || data?.clicked ||
                        data?.hasOtherFieldsError || incorrectValue ||
                        ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '' || data?.shares === '0')) ||
                        ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0 || data?.shares === '0')))
                      ? ''
                      : '#EA3D2F',
                    margin: '8px'
                  }}
                  variant='contained'
                  endIcon={<ErrorOutlineIcon />}
                  onClick={() => filterInvalid()}
                  className='PortfolioEndBtn'
                  disabled={isButtonDisabled || incorrectValue || incorrectCountryCode ||
                    stagedData?.some((data) => data?.hasSecurityError || data?.name === '' ||
                      data?.hasOtherFieldsError || incorrectValue || data?.clicked ||
                      ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '' || data?.shares === '0')) ||
                      ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0 || data?.shares === '0')))}
                >
                  Invalid Entries
                </Button>
                <Button
                  variant='contained'
                  onClick={() => checkStagingData()}
                  className='PortfolioEndBtn'
                  disabled={
                    !isButtonDisabled ||
                    !stagedData?.length ||
                    incorrectValue ||
                    incorrectCountryCode ||
                    stagedData?.some((data) => data?.hasSecurityError || data?.name === '' ||
                      data?.hasOtherFieldsError || incorrectValue ||
                      ((data?.symbol === '__USD' || data?.symbol === '__CASH') && (!isValidNumber(data?.shares) || data?.shares === '' || data?.shares === '0')) ||
                      ((data?.symbol !== '__USD' && data?.symbol !== '__CASH') && (!isValidNumber(data?.shares) || data?.shares <= 0 || data?.shares === '0')) || data?.clicked)
                  }
                >
                  Validate & Upload
                </Button>
              </>}
            {showInvalid && (
              <Box>
                <Button
                  style={{
                    backgroundColor: isButtonDisabled ? '' : 'auto',
                    marginRight: '5px'
                  }}
                  variant='contained'
                  onClick={() => filterInvalid()}
                  className='PortfolioEndBtn'
                  disabled={isButtonDisabled || incorrectValue || incorrectCountryCode || invalidStagedData?.some((data) => {
                    if (data?.hasSecurityError || data?.name === '' || data?.clicked) {
                      return true
                    }
                    return false
                  })}
                >
                  Valid Entries
                </Button>
                {selectedRows?.length > 0
                  ? (
                    <Button
                      style={{
                        backgroundColor: '#EA3D2F',
                        width: '176.13px',
                        color: 'white',
                        textTransform: 'capitalize',
                        border: '1px solid transparent !important',
                        borderRadius: '10px',
                        fontFamily: 'Open Sans',
                        fontSize: '15px'
                      }}
                      variant='contained'
                      endIcon={<DeleteIcon />}
                      onClick={() => handleDeleteAllClick()}
                      className='PortfolioEndBtn'
                    >
                      {selectedRows?.length === invalidStagedData?.length ? 'Delete All' : 'Delete Selected'}
                    </Button>
                    )
                  : ''}
              </Box>
            )}
          </Box>
        </DialogActions>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Box>
            {
              unsavedRowCount > 0
                ? <Typography sx={{ fontSize: '14px', color: '#D29922', paddingLeft: '24px' }}>{`You have ${unsavedRowCount}/${showInvalid ? invalidStagedData.length : stagedData.length} unsaved rows.`}</Typography>
                : ''
            }
          </Box>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={showInvalid ? invalidStagedData?.length : searchTerm ? filteredData.length : stagedData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
                `Page ${page + 1} (${from}-${to} of ${count})`}
          />
        </DialogActions>
      </Dialog>
      <Dialog open={modalShow} onClose={closeModal}>
        <DialogContent
          style={{ textAlign: 'center', width: '495px', height: '266px' }}
        >
          <img src={Success} alt='' style={{ marginTop: '28px', marginLeft: '5px' }} />
          <Typography variant='h4' style={{ marginTop: '44px', marginLeft: '5px', fontSize: '24px', fontWeight: 600 }}>
            {successMessage}
          </Typography>
        </DialogContent>
      </Dialog>
      {
        isErrorModelOpen ? <InvalidRowEntriesPopup isErrorModelOpen={isErrorModelOpen} errorModalContent={errorModalContent} setIsErrorModelOpen={setIsErrorModelOpen} /> : ''
      }
      {
        isSchwabDataModelOpen
          ? <SchwabDataPopup validatedPortfolioData={validatedPortfolioData} isSchwabDataModelOpen={isSchwabDataModelOpen} setIsSchwabDataModelOpen={setIsSchwabDataModelOpen} isUmaOnboardingScreen />
          : ''
      }
    </Box>
  )
}

export default UMATradingPortfolioTab
