import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import { Box, debounce } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import AllocationModelTab from './AllocationModelTab'
import NewConfirmationTab from './Confirmation'
import OnboardingStepper from './OnboardingStepper'
import UMADetailsTab from './UMADetailsTab'
import UMATradingPortfolioTab from './UMATradingPortfolioTab'
import UmaTransitionPortfolioTab from './UmaTransitionPortfolioTab'
import Loader from '../../Loader'

const stepLabels = [
  'UMA Details',
  'Upload Portfolio',
  'Select Allocation Model',
  'Confirmation'
]

const UMAOnboarding = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [activeIndex, setActiveIndex] = useState(0)
  const umaDetailsFormRef = useRef()
  const taxlotDetailsFormRef = useRef()
  const [umaDetailsFormSubmitted, setUmaDetailsFormSubmitted] = useState(false)
  const [taxlotDetailsFormSubmitted, setTaxlotDetailsFormSubmitted] = useState(false)
  const [umaDetails, setUmaDetails] = useState({})
  const [currentTransitionAllocationModel, setCurrentTransitionAllocationModel] = useState([])
  const [taxStates, setTaxStates] = useState([])
  const [sellLogicCodes, setSellLogicCodes] = useState([])
  const [custodianList, setCustodianList] = useState([])
  const [targetAllocationModel, setTargetAllocationModel] = useState([{ id: randomId(), data: [] }])
  const [uploadedPortfolioData, setUploadedPortfolioData] = useState({})
  const [fileIdExists, setFileIdExists] = useState(0)
  const [transitionPortfolioAllocationData, setTransitionPortfolioAllocationData] = useState([])
  const [personalizationData, setPersonalizationData] = useState({})
  const [transitionUploadPortfolioData, setTransitionUploadPortfolioData] = useState({})
  const [allStepsVisited, setAllStepsVisited] = useState(false)
  const [strategies, setStrategies] = useState([])
  const [strategyLoading, setStrategyLoading] = useState(false)
  const [isSubmitAPILoading, setIsSubmitAPILoading] = useState(false)
  const [sellLogicCodesLoading, setSellLogicCodesLoading] = useState(false)
  const [taxStatesLoading, setTaxStatesLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('Transition request created successfully')
  const [modalShow, setModalShow] = useState(false)
  const [umaDetailsFormChanged, setUmaDetailsFormChanged] = useState(false)

  const createTransitionJSON = (umaDetails, currentAllocation, targetAllocation, aggregateId) => {
    function getFormattedDate () {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    return {
      aggId: aggregateId || '',
      aggType: umaDetails?.accountType === 'trading' ? 'TRADING' : 'TRANSITION',
      aggName: umaDetails?.name,
      aggEmail: umaDetails?.email,
      aggTypeId: 1, // frontend will pass static value for this
      sellLogicId: umaDetails?.selllogicCode?.sellLogicId,
      isTaxManaged: umaDetails?.taxManaged || false,
      taxSensitivity: umaDetails?.taxSensitivity,

      ...(umaDetails?.accountType === 'trading' && {
        masterAccountID: umaDetails?.masterAccountId || '',
        custodianId: umaDetails?.custodian,
        custodianAccountNumber: umaDetails?.custodianNumber
      }),

      ...(umaDetails?.taxManaged && {
        taxStateId: umaDetails?.taxState?.stateId,
        advancedTaxes: umaDetails?.advancedTaxes || false,
        shortTermTaxRate: umaDetails?.shortTermTaxRate,
        longTermTaxRate: umaDetails?.longTermTaxRate
      }),

      ...(umaDetails?.taxManaged && umaDetails?.advancedTaxes && {
        extRealizedLongGL: umaDetails?.externalLongGainLoss,
        extRealizedShortGL: umaDetails?.externalShortGainLoss
      }),
      maxShortTermGain: 0, // frontend will pass static value for this
      maxLongTermGain: 0, // frontend will pass static value for this
      originalAccounts: currentAllocation?.map((account) => {
        const checkIsSchwabCustodian = transitionUploadPortfolioData[account?.id]?.fundingInformation?.custodianId?.custodianName === 'Charles Schwab' &&
        transitionUploadPortfolioData[account?.id]?.fundingInformation?.uploadType === 'urlupload'
        return {
          accountName: account.account.trim(),
          portfolio: Object.keys(transitionUploadPortfolioData)?.length
            ? transitionUploadPortfolioData[account?.id]?.fundingInformation?.fundingSource === 'CASH'
              ? [
                  {
                    symbol: '__USD',
                    purchase_date: getFormattedDate(),
                    purchase_cost: 1,
                    shares: transitionUploadPortfolioData[account?.id]?.fundingInformation?.totalCashValue || 0,
                    instrId: '__CASH',
                    dbLotMv: transitionUploadPortfolioData[account?.id]?.fundingInformation?.totalCashValue || 0,
                    dbLastClosePrice: 1,
                    lotMvStatus: 'green'
                  }
                ]
              : transitionUploadPortfolioData[account?.id]?.data.map((e) => ({
                symbol: e.symbol,
                purchase_date: e.purchaseDate ? (typeof e.purchaseDate !== 'string' ? dayjs(e.purchaseDate).format('YYYY-MM-DD') : e.purchaseDate.split('T')[0]) : '',
                purchase_cost: (e.symbol === '__CASH' || e.symbol === '__USD') ? 1 : e.purchaseCost,
                shares: e.shares,
                instrId: e?.instrId,
                ...(checkIsSchwabCustodian
                  ? {
                      org_purchase_price: e.orgPurchasePrice || null,
                      schwabLotMv: e?.schwabLotMv,
                      schwabLastClosePrice: e?.schwabLastClosePrice
                    }
                  : {})
              }))
            : [],
          fundingSource: transitionUploadPortfolioData[account?.id]?.fundingInformation?.fundingSource || ''
        }
      }),

      targetScenarios: targetAllocation?.map((scenario, index) => ({
        aggName: scenario?.allocationTitle || `Allocation ${index + 1}`,
        targetAccounts: scenario?.data?.map((account) => ({
          portSpecId: account?.strategy?.portSpecId,
          accountName: `${account?.strategy?.strategyName}-${(umaDetails?.name?.split(' ')[0] || '').trim()}`,
          allocWeight: account?.allocation || 0
        }))
      }))
    }
  }

  // GET API for UMA details screen
  const getCustodianType = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/custodians`)
      .then(response => {
        if (response?.data) {
          setCustodianList(response.data)
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getTaxStates = async () => {
    setTaxStatesLoading(true)
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/tax-states`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setTaxStates([...response.data])
          setTaxStatesLoading(false)
        }
      })
      .catch((error) => {
        setTaxStatesLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getSellLogicCodes = async () => {
    setSellLogicCodesLoading(true)
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/sell-logics`, {
      queryStringParameters: {}
    })
      .then((response) => {
        if (response.data) {
          setSellLogicCodes([...response.data])
          setSellLogicCodesLoading(false)
        }
      })
      .catch((error) => {
        setSellLogicCodesLoading(false)
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const getStrategyList = async () => {
    setStrategyLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user.userGroup}/sponsors/strategies`)
      .then(response => {
        if (response.data) {
          const strategyListData = response.data.map((item, index) => {
            const result = {
              id: index + 1, ...item
            }
            return result
          })
          setStrategies(strategyListData)
          setStrategyLoading(false)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        setStrategyLoading(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  const handleErrorDebounce = useMemo(
    () => debounce((message) => {
      showError(message)
    }, 300), [])

  useEffect(() => {
    if (user) {
      getTaxStates()
      getSellLogicCodes()
      getCustodianType()
      getStrategyList()
    }
  }, [user])

  const validateUmaDetailsTab = () => {
    if (!umaDetailsFormSubmitted) {
      umaDetailsFormRef?.current?.click()
    } else {
      setUmaDetailsFormSubmitted(false)
      setActiveIndex(activeIndex + 1)
    }
  }

  const validateUploadPortfolioTab = () => {
    if (umaDetails?.accountType === 'trading') {
      if (!taxlotDetailsFormSubmitted) {
        taxlotDetailsFormRef.current.click()
      } else {
        setTaxlotDetailsFormSubmitted(false)
        setActiveIndex(activeIndex + 1)
      }
    } else if (umaDetails?.accountType === 'transition') {
      const hasError = currentTransitionAllocationModel?.some((data) => data?.error) || !currentTransitionAllocationModel?.length
      // Check if any item has isInEditMode set to true
      const hasInEditMode = currentTransitionAllocationModel?.some((data) => data?.isInEditMode)

      if (hasInEditMode) {
        handleErrorDebounce('Some of the rows are unsaved. Please save all rows to proceed further.')
      }

      if (!hasError && !hasInEditMode) {
        setActiveIndex(activeIndex + 1)
      }

      if (!currentTransitionAllocationModel?.length) {
        // Show error if there is no allocation and strategy
        handleErrorDebounce('At least one account is required.')
      }
    }
  }

  const showErrorMessage = (message) => {
    showError(message, false, { pauseOnHover: true })
  }

  const validateAllocationModelTab = () => {
    // Check if any row in targetAllocationModel's data is in edit mode
    const hasInEditMode = targetAllocationModel?.some((model) =>
      model?.data?.some((row) => row.isInEditMode)
    )
    const titleError = targetAllocationModel?.some((data) => data?.titleError)
    const hasStrategyError = targetAllocationModel?.some((data) => data?.data?.length === 0)
    const hasNameEditMode = targetAllocationModel?.some((data) => data?.isNameEditMode)
    const inputFieldError = targetAllocationModel.some((data) => data?.inputFieldError)
    const duplicateStrategyError = targetAllocationModel?.some((data) => data?.duplicateStrategyError)
    const allocationError = targetAllocationModel.some((data) => data.allocationError)
    if (hasInEditMode) {
      showErrorMessage('Some of the rows are unsaved. Please save all rows to proceed further.')
    }
    if (targetAllocationModel && targetAllocationModel?.length && umaDetails?.accountType === 'transition' && (allocationError || hasStrategyError)) {
      showErrorMessage('Total allocation of each model should be 100%.')
    }
    if (targetAllocationModel && targetAllocationModel?.length && duplicateStrategyError) {
      showErrorMessage('Some allocations contain duplicate strategies. Please ensure each allocation has a unique strategy.')
    }
    if (!targetAllocationModel?.length) {
      showErrorMessage('At least one allocation is required.')
    }
    if (hasNameEditMode) {
      showErrorMessage('Some of the titles are unsaved. Please save all allocation titles to proceed further.')
    }

    if (!titleError && !hasStrategyError && !hasNameEditMode && targetAllocationModel.length && !inputFieldError && !allocationError && !duplicateStrategyError && !hasInEditMode) {
      setActiveIndex(activeIndex + 1)
    }
  }

  const checkAllStepsVisited = () => {
    if (
      targetAllocationModel?.some((data) => data?.titleError) ||
      targetAllocationModel?.some((data) => data?.isNameEditMode) ||
      targetAllocationModel?.some((data) => data.allocationError) ||
      targetAllocationModel?.some((data) => data?.duplicateStrategyError) ||
      targetAllocationModel?.some((data) => data.inputFieldError) ||
      targetAllocationModel?.some((data) => data?.data?.length === 0) ||
      targetAllocationModel?.some((data) => data?.data?.some((row) => row.isInEditMode)) ||
      !targetAllocationModel?.length ||
      (umaDetails?.accountType === 'transition' &&
        (currentTransitionAllocationModel?.some((data) => data?.error) || !currentTransitionAllocationModel?.length))
    ) {
      setAllStepsVisited(false)
    } else {
      setAllStepsVisited(true)
    }
  }

  const gotoNextStep = () => {
    switch (activeIndex) {
      case 0:
        validateUmaDetailsTab()
        break
      case 1:
        validateUploadPortfolioTab()
        break
      case 2:
        validateAllocationModelTab()
        break
      default:
        setActiveIndex(activeIndex + 1)
        break
    }

    // If the final step is reached, check if all steps are visited
    if (activeIndex === stepLabels.length - 2) {
      checkAllStepsVisited()
    }
  }

  const gotoPrevStep = () => {
    setActiveIndex(activeIndex - 1)
    setFileIdExists(uploadedPortfolioData.fileId)
  }

  const changeActiveStep = (index) => {
    if (allStepsVisited) {
      setActiveIndex(index)
    }
    if (targetAllocationModel && targetAllocationModel?.length > 0 && activeIndex === 2) {
      const filteredData = targetAllocationModel?.map(item => ({
        ...item,
        data: Array.isArray(item?.data)
          ? item?.data?.filter(row =>
            row && row.strategy !== '' && row.allocation !== ''
          )
          : []
      }))

      setTargetAllocationModel(filteredData)
    }
  }

  const setUmaInformation = (data) => {
    if (data?.masterAccountId !== umaDetails?.masterAccountId || data?.custodianNumber !== umaDetails?.custodianNumber || data?.taxManaged !== umaDetails?.taxManaged || data?.accountType !== umaDetails?.accountType) {
      setUploadedPortfolioData({})
    }
    setUmaDetailsFormSubmitted(true)
    setUmaDetails(data)
    gotoNextStep()
  }

  const setTaxlotInformation = (data) => {
    setTaxlotDetailsFormSubmitted(true)
    setUploadedPortfolioData(data)
    gotoNextStep()
  }

  const addAllocation = () => {
    // add allocation will add new allocation card for transition accounts
    // for creating multiple allocationCardData
    setTargetAllocationModel([
      ...targetAllocationModel,
      { id: randomId(), data: [] }
    ])
  }

  const submitUmaOnboarding = () => {
    setIsSubmitAPILoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/uma/onboard/upload-url`
    )
      .then((response) => {
        if (response?.data) {
          const data = response?.data?.uploadURL
          const aggId = response?.data?.aggId
          const formdata = new FormData()
          formdata.append('bucket', data?.fields?.bucket)
          formdata.append('key', data?.fields.key)
          formdata.append('Content-Type', data?.fields ? data?.fields['Content-Type'] : '')
          formdata.append('Policy', data?.fields?.Policy)
          formdata.append('X-Amz-Algorithm', data?.fields ? data?.fields['X-Amz-Algorithm'] : '')
          formdata.append(
            'X-Amz-Credential',
            data?.fields ? data?.fields['X-Amz-Credential'] : ''
          )
          formdata.append('X-Amz-Date', data?.fields ? data?.fields['X-Amz-Date'] : '')
          formdata.append(
            'X-Amz-Security-Token',
            data?.fields ? data?.fields['X-Amz-Security-Token'] : ''
          )
          formdata.append('X-Amz-Signature', data?.fields ? data?.fields['X-Amz-Signature'] : '')
          formdata.append('file', JSON.stringify(createTransitionJSON(umaDetails, currentTransitionAllocationModel, targetAllocationModel, aggId)))
          fetch(data?.url, {
            method: 'POST',
            body: formdata
          })
            .then((response) => {
              if (response.ok) {
                API.get(
                  'baseUriAggregateMaster',
                  `aggregate-master/v1/${user?.userGroup}/uma/onboard/${aggId}`
                )
                  .then((response) => {
                    if (response?.success) {
                      setIsSubmitAPILoading(false)
                      setModalShow(true)
                    }
                  })
                  .catch((error) => {
                    setIsSubmitAPILoading(false)
                    setModalShow(false)
                    showError(error.response?.data?.errorInfo?.userMessage || error.message)
                    Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
                  })
              } else {
                setIsSubmitAPILoading(false)
                setModalShow(false)
                showError('Failed to create transition request')
                Sentry.captureException('Failed to create transition request')
              }
            })
            .catch((error) => {
              setIsSubmitAPILoading(false)
              showError(error.response?.data?.errorInfo?.userMessage || error.message)
              Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
            })
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  // set allStepsVisited to false when data is modified in UMA details tab
  useEffect(() => {
    if (umaDetailsFormChanged) {
      setAllStepsVisited(false)
    }
  }, [umaDetailsFormChanged])

  return (
    <>
      {isSubmitAPILoading ? <Loader /> : ''}
      <Box className='uma-page' sx={{ height: 'auto' }}>
        <OnboardingStepper
          activeIndex={activeIndex}
          stepLabels={stepLabels}
          gotoNextStep={gotoNextStep}
          umaDetails={umaDetails}
          allStepsVisited={allStepsVisited}
          gotoPrevStep={gotoPrevStep}
          setSuccessMessage={setSuccessMessage}
          successMessage={successMessage}
          setModalShow={setModalShow}
          modalShow={modalShow}
          submitUmaOnboarding={submitUmaOnboarding}
          changeActiveStep={changeActiveStep}
        />
        {stepLabels[activeIndex] === 'UMA Details'
          ? (
            <UMADetailsTab
              custodianList={custodianList}
              taxStates={taxStates}
              sellLogicCodes={sellLogicCodes}
              setAllStepsVisited={setAllStepsVisited}
              submitRef={umaDetailsFormRef}
              setAllocationCardData={setTargetAllocationModel}
              setTransitionPortfolioAllocationData={setTransitionPortfolioAllocationData}
              setTransitionUploadPortfolioData={setTransitionUploadPortfolioData}
              setData={setUmaInformation}
              data={umaDetails}
              sellLogicCodesLoading={sellLogicCodesLoading}
              taxStatesLoading={taxStatesLoading}
              setUmaDetailsFormChanged={setUmaDetailsFormChanged}
            />
            )
          : stepLabels[activeIndex] === 'Select Allocation Model'
            ? <AllocationModelTab
                umaDetails={umaDetails}
                strategies={strategies}
                addAllocation={addAllocation}
                allocationCardData={targetAllocationModel}
                setAllStepsVisited={setAllStepsVisited}
                loading={strategyLoading}
                setAllocationCardData={setTargetAllocationModel}
                personalizationData={personalizationData}
                setPersonalizationData={setPersonalizationData}
              />
            : stepLabels[activeIndex] === 'Upload Portfolio'
              ? (
                  umaDetails?.accountType === 'transition'
                    ? (
                      <UmaTransitionPortfolioTab
                        setData={setCurrentTransitionAllocationModel}
                        setTransitionUploadPortfolioData={setTransitionUploadPortfolioData}
                        transitionUploadPortfolioData={transitionUploadPortfolioData}
                        setTaxlotInformation={setTaxlotInformation}
                        umaDetails={umaDetails}
                        setAllStepsVisited={setAllStepsVisited}
                        transitionPortfolioAllocationData={transitionPortfolioAllocationData}
                        setTransitionPortfolioAllocationData={setTransitionPortfolioAllocationData}
                      />
                      )
                    : (
                      <UMATradingPortfolioTab
                        submitRef={taxlotDetailsFormRef}
                        setTaxlotInformation={setTaxlotInformation}
                        data={uploadedPortfolioData}
                        setAllStepsVisited={setAllStepsVisited}
                        setUploadedPortfolioData={setUploadedPortfolioData}
                        custodianData={{ selectedCustodian: umaDetails?.custodian, custodianNumber: umaDetails?.custodianNumber, masterAccountId: umaDetails?.masterAccountId, taxManaged: umaDetails?.taxManaged }}
                        fileIdExists={fileIdExists}
                        umaDetails={umaDetails}
                      />
                      )
                )
              : stepLabels[activeIndex] === 'Confirmation'
                ? (
                  <NewConfirmationTab
                    umaDetails={umaDetails}
                    uploadedPortfolioData={uploadedPortfolioData}
                    currentAllocation={currentTransitionAllocationModel}
                    targetAllocation={targetAllocationModel}
                    transitionUploadPortfolioData={transitionUploadPortfolioData}
                  />
                  )
                : null}
      </Box>

    </>
  )
}

export default UMAOnboarding
