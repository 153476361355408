import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CancelIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'

const UpdateInputFrom = ({ savedData, type, handleCancelClose, selectedRow, userProps, spnParams, roleParams, setUpdateApiLoading, updateApiLoading }) => {
  const updateFromValidationSchema = (type) => {
    let schema
    switch (type) {
      case 'Role':
        schema = Yup.object().shape({
          roleName: Yup.string()
            .min(3, 'Role Name must contain at least three character')
            .max(50, 'Role Name is Too Long!')
            .required('Role Name Required')
        })
        break
      case 'User':
        schema = Yup.object().shape({
          userName: Yup.string()
            .min(1, 'Username must contain at least one character')
            .max(30, 'Username Name is Too Long!')
            .required('Username Required'),
          userEmail: Yup.string()
            .email('Invalid email address')
            .required('Email address Required')
        })
        break
      case 'Sponsor':
        schema = Yup.object().shape({
          sponsorName: Yup.string()
            .min(1, 'Sponsor Name must contain at least one character')
            .max(50, 'Sponsor Name is Too Long!')
            .required('Sponsor Name Required')
        })
        break
      default:
        schema = Yup.object()
        break
    }
    return schema
  }

  const initialFormValues = {
    Role: {
      roleName: '' || roleParams?.roleName,
      roleCode: '' || roleParams?.roleCode,
      roleDesc: '' || roleParams?.roleDesc
    },
    User: {
      userName: '' || userProps?.userName,
      userEmail: '' || userProps?.userEmail,
      sponsorCd: '' || userProps?.sponsor?.sponsorCd || selectedRow?.sponsorCd
    }
  }

  const formik = useFormik({
    initialValues: initialFormValues[type],
    validationSchema: updateFromValidationSchema(type),
    onSubmit: (values) => {
      setUpdateApiLoading(true)
      savedData(values)
    }
  })

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
        <Typography
          variant='h6'
          textAlign='center'
          color="#34475A"
          fontWeight={400}
        >Edit {type} Data
        </Typography>
        <IconButton onClick={handleCancelClose}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          {(() => {
            switch (type) {
              case 'Role':
                return (
                  <form>
                    <TextField
                      margin='dense'
                      id='roleName'
                      name='roleName'
                      label='Role Name'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.roleName && formik.touched.roleName)
                      }
                      helperText={
                        formik.errors.roleName &&
                        formik.touched.roleName &&
                        String(formik.errors.roleName)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.roleName}
                    />
                    <TextField
                      margin='dense'
                      id='roleCode'
                      disabled
                      name='roleCode'
                      label='Role Code'
                      InputProps={{
                        readOnly: true
                      }}
                      type='text'
                      fullWidth
                      variant='standard'
                      onChange={formik.handleChange}
                      value={formik?.values?.roleCode}
                    />
                    <TextField
                      margin='dense'
                      id='roleDesc'
                      name='roleDesc'
                      label='Role Desc'
                      type='text'
                      fullWidth
                      variant='standard'
                      onChange={formik.handleChange}
                      value={formik?.values?.roleDesc}
                    />
                  </form>
                )
              case 'User':
                return (
                  <form>
                    <TextField
                      margin='dense'
                      id='userName'
                      name='userName'
                      label='User Name'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.userName && formik.touched.userName)
                      }
                      helperText={
                        formik.errors.userName &&
                        formik.touched.userName &&
                        String(formik.errors.userName)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.userName}
                    />
                    <TextField
                      margin='dense'
                      id='userEmail'
                      name='userEmail'
                      label='Email'
                      type='text'
                      fullWidth
                      variant='standard'
                      error={
                        Boolean(formik.errors.userEmail && formik.touched.userEmail)
                      }
                      helperText={
                        formik.errors.userEmail &&
                        formik.touched.userEmail &&
                        String(formik.errors.userEmail)
                      }
                      onChange={formik.handleChange}
                      value={formik?.values?.userEmail}
                    />
                    <TextField
                      margin='dense'
                      id='sponsorCd'
                      name='sponsorCd'
                      label='Sponsor Code'
                      type='text'
                      disabled
                      InputProps={{
                        readOnly: true
                      }}
                      fullWidth
                      variant='standard'
                      onChange={formik.handleChange}
                      value={formik?.values?.sponsorCd || selectedRow?.sponsorCd}
                    />
                  </form>
                )
              default:
            }
          })()}
          {
                  updateApiLoading
                    ? (
                      <LoadingButton
                        loading
                        loadingPosition='start'
                        variant='contained'
                        fullWidth
                        type='submit'
                        sx={{ mt: 2 }}
                      >
                        Update
                      </LoadingButton>
                      )
                    : (
                      <Button
                        disabled={!(formik.dirty)}
                        color='primary' variant='contained' fullWidth type='submit' sx={{ mt: 2 }}
                      >
                        Update
                      </Button>
                      )
                }
        </form>
      </DialogContent>
    </>
  )
}

export default UpdateInputFrom
