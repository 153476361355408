import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Button, Tooltip } from '@mui/material'
import { IoIosExpand } from 'react-icons/io'
import { HiMiniArrowsPointingIn } from 'react-icons/hi2'
import { disableLegacyViewReducer, toggleLegacyViewReducer } from '../store/trade-reducer/trade-reducer'
import useWindowDimensions from '../hooks/useWindowDimensions'

export default function LegacyViewButton () {
  const dispatch = useDispatch()
  const { showLegacyView } = useSelector((state) => state.trade)
  const { width } = useWindowDimensions()
  const location = useLocation()

  const pagesWithoutLegacyView = ['/pdf-customizer']

  const toggleLegacyView = () => {
    dispatch(toggleLegacyViewReducer())
  }

  useEffect(() => {
    if (showLegacyView) {
      document.body.setAttribute('data-legacy-view', showLegacyView)
    } else {
      document.body.removeAttribute('data-legacy-view')
    }
    return () => {
      document.body.removeAttribute('data-legacy-view')
    }
  }, [showLegacyView])

  useEffect(() => {
    if (width < 1720) {
      dispatch(disableLegacyViewReducer())
      document.body.removeAttribute('data-legacy-view')
    }
  }, [width])

  if (pagesWithoutLegacyView.some(path => location.pathname.startsWith(path))) {
    return null
  }

  return (
    ((width > 1719) || showLegacyView)
      ? showLegacyView
        ? (
          <Tooltip title='Normal View'>
            <Button
              variant='outlined'
              onClick={toggleLegacyView}
              sx={{
                border: '1px solid #74788D',
                padding: '2px',
                minWidth: 'auto',
                borderRadius: '4px',
                ':hover': {
                  background: 'transparent',
                  border: '1px solid #74788D'
                }
              }}
            >
              <HiMiniArrowsPointingIn size='17px' color='#74788D' />
            </Button>
          </Tooltip>
          )
        : (
          <Tooltip title='Compact View'>
            <Button
              variant='outlined'
              onClick={toggleLegacyView}
              sx={{
                border: 'none',
                padding: '2px',
                minWidth: 'auto',
                ':hover': {
                  background: 'transparent',
                  border: 'none'
                }
              }}
            >
              <IoIosExpand size='17px' color='#74788D' />
            </Button>
          </Tooltip>
          )
      : ''
  )
}
